<template>
  <div class="app-container">
    <el-row :gutter="20">
      <el-col
        :span="7"
      >
        <div class="date-control-container">
          <el-date-picker
            v-model="dateFilter"
            size="mini"
            type="daterange"
            range-separator="To"
            start-placeholder="Start date"
            end-placeholder="End date"
            value-format="yyyy-MM-dd"
            @change="fetchRecordsRange"
          />
        </div>
      </el-col>
      <div />

      <el-col
        :span="6"
        style="padding-left: 0px;padding-right: 10px;padding-top: 10px;padding-bottom: 10px;"
      >

        <el-select v-model="selectedLocation" size="mini" placeholder="Location" @change="fetchRecordsRange">
          <el-option
            v-for="location in locationOptions"
            :key="location.id_connection_entity"
            :label="location.name"
            :value="location.id_connection_entity"
          />
        </el-select>

      </el-col>

      <el-col
        :span="4"
        style="padding-left: 10px;padding-right: 10px;padding-top: 10px;padding-bottom: 10px;"
      ><div />
        <el-input
          v-model="searchBox"
          size="mini"
          placeholder="Filter by Name"
        /></el-col>
    </el-row>
    <el-row :gutter="20" style="margin-top: 15px">
      <el-col
        :span="24"
      ><div>
        <el-table
          id="appointmentResultsTable"
          ref="resultsTable"
          v-loading="listLoading"
          :data="rows.filter(data => !searchBox || data['Client Name'].toLowerCase().includes(searchBox.toLowerCase()))"
          border
          fit
          stripe
          lazy
          :show-summary="false"
          style="width: 100%"
          class=".warning-row"
        >
          <div />
          <el-table-column
            v-for="col in columns"
            :key="col"
            :label="col"
            :prop="col"
            sortable
            :resizable="true"
            show-overflow-tooltip
            align="left"
            header-align="center"
            :column-key="col"
          />
        </el-table></div></el-col></el-row>
  </div>
</template>

<script>
import {
  fetchApptResults
} from '@/api/callCenterResults'
import { DateTime } from 'luxon'
import { responseValidator } from '@/utils/response-validator'
import * as apiClioAdmin from '@/api/clio-admin'

const today = new Date()

// var monthFirstDay = new Date(today.getFullYear(), today.getMonth(), 1)
var weekFirstDay = DateTime.local().startOf('week').toISO()
var endOfToday = DateTime.local().endOf('day').toISO()

export default {
  name: 'CCApptResults',
  components: {},
  data() {
    return {
      calendarKey: 'id_connection_entity',
      appointmentList: [],
      dateFilter: [weekFirstDay, endOfToday],
      displayDate: today,
      filterTypes: {
        locationFilter: [],
        resultFilter: []
      },
      list: null,
      listLoading: false, // this should be set to true when the data is loaded
      locationOptions: [
        // { value: null, label: 'All Locations' },
        { value: '1', label: 'Montgomery/Opelika' },
        { value: '2', label: 'Gadsden/Anniston' },
        { value: '3', label: 'Florence/Haleyville' },
        { value: '4', label: 'Huntsville/Decatur/Cullman' },
        { value: '5', label: 'Jackson/Vicksburg/Hattiesburg' },
        { value: '6', label: 'Mobile' },
        { value: '8', label: 'Birmingham' }
      ],
      rows: [],
      searchBox: '',
      selectListLoading: false,
      selectedLocation: null
    }
  },
  computed: {
    columns: function columns() {
      if (this.rows.length === 0) {
        return []
      }
      const allColumns = Object.keys(this.rows[0])
      const toRemove = new Set(['matter_id_clio', 'start_at_time_zone', 'matter_deleted'])
      return allColumns.filter(x => !toRemove.has(x))
    }
  },
  async mounted() {
    try {
      const resp = await apiClioAdmin.getClioLinkedAccounts()
      const validator = responseValidator(resp)
      if (!validator.success) throw validator
      this.locationOptions = resp.clio_accounts
    } catch (e) {
      this.$notify.error({ title: 'Oops!', message: 'Unable to get data location categories.' })
    }
  },
  methods: {
    createColumnFilterList() {
      var columnArray = this.columns

      var arrayObject = []
      for (var i = 0; i < columnArray.length; i++) {
        arrayObject.push({ id: columnArray[i], hidden: false })
      }
      this.columnFilters = arrayObject
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex === 1) {
        return 'warningRow'
      } else if (rowIndex === 3) {
        return 'success-row'
      }
      return ''
    },
    async fetchRecordsRange() {
      if (this.selectedLocation) {
        this.searchBox = ''
        this.listLoading = true
        if (this.selectedLocation) {
          var tableData = await fetchApptResults([this.dateFilter, this.selectedLocation])
          var updatedtd = tableData.sortedData.map(function(x) {
            x['Appt Time'] = DateTime.fromISO(x['Appt Time'], { zone: x.start_at_time_zone })
            x['Appt Time'] = x['Appt Time'].toLocaleString(DateTime.DATETIME_MED)

            x['Last Updated'] = DateTime.fromISO(x['Last Updated'], { zone: x.start_at_time_zone })
            x['Last Updated'] = x['Last Updated'].toLocaleString(DateTime.DATETIME_MED)

            return x
          })
        }
        this.listLoading = false
        if (updatedtd !== 'none') {
          this.rows = updatedtd
        } else {
          this.rows = []
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination-container {
  margin-top: 0px;
  padding: 15px 16px;
}

.analytics-filter-container {
  .filter-item {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }
  .export-button {
    vertical-align: middle;
    position: absolute;
    right: 0;
  }
}

.date-control-container {
  padding-top: 10px;
  padding-bottom: 10px;
}

.filter-item {
  width: 100%;
}

.chart-container {
  position: relative;
  width: 100%;
  height: calc(100vh - 84px);
}

  .warningRow {
    background: #57e60b !important;
  }

  .el-table .warning-row {
    background: oldlace;
  }

  .el-table .success-row {
    background: #f0f9eb;
  }

</style>
