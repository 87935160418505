<template>
  <div>
    <el-tabs type="border-card" :lazy="true">
      <el-tab-pane label="Calendars" :lazy="true">
        <div>
          <CalendarAttributes id="CalendarAttributes" :lazy="true" />
        </div>
      </el-tab-pane>
      <el-tab-pane label="External Account Linking" :lazy="true">
        <div>
          <ExternalAccounts id="ExternalAccounts" :lazy="true" />
        </div>
      </el-tab-pane>

      <!-- <el-tab-pane label="User Management" :lazy="true">
        <div>
          <ManageUsers id="ManageUsers" />
        </div>
      </el-tab-pane> -->
    </el-tabs>
  </div>
</template>

<script>

import CalendarAttributes from './components/calendars/calendar-attributes-main'
import ExternalAccounts from './components/external-accounts/external-accounts-main'
// import ManageUsers from './components/user-management/manage-users-main.vue'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'AdminSettings',
  components: {
    'CalendarAttributes': CalendarAttributes,
    'ExternalAccounts': ExternalAccounts
    // 'ManageUsers': ManageUsers
  },
  props: {},
  data() {
    return {
      data: '0'
    }
  },
  computed: {
    ...mapGetters([
      'name',
      'avatar',
      'roles'
    ])
  },
  created: async function() {},
  mounted: async function() {},
  methods: {}

}
</script>

<style lang="scss" scoped>
  .el-col {
    border-radius: 4px;
    margin-left: 20px;
  }
  .el-row {
    margin-bottom: 20px;
  }
  .el-tab-pane {
    max-height: calc(100vh - 100px);
    overflow-x: hidden;
    overflow-y: scroll;
  }
</style>
