import request from '@/utils/request'

export function getCounties(data) {
  return request({
    url: '/locations/counties',
    method: 'post',
    data
  })
}

export function getCountiesByFirmId(data) {
  return request({
    url: '/locations/counties/all',
    method: 'post',
    data
  })
}

export function fetchOffices(data) {
  return request({
    url: '/locations/offices',
    method: 'post',
    data
  })
}

export function fetchCalendars(data) {
  return request({
    url: '/locations/calendars',
    method: 'post',
    data
  })
}

/**
 * @description gets list of urls
 * @param {*} req
 * @param {*} res {status, clio_linked_calendars}
 */
export function getAllQuestionnaireUrls() {
  return request({
    url: `/locations/questionnaireUrls?type=get_all`,
    method: 'get'
  })
}

/**
 * @description gets url linked to location
 * @param {*} req query: location_id
 * @param {*} res {status, clio_linked_calendars}
 */
export function getLinkedQuestionnaireUrl(location_id) {
  return request({
    url: `/locations/questionnaireUrls?type=location_linked&location_id=${location_id}`,
    method: 'get'
  })
}

/**
 * @description adds a questionnaire url to the database
 * @param {*} req {questionnaire_url}
 * @param {*} res {status, serviced_locations}
 */
export function addQuestionnaireUrl(data) {
  return request({
    url: '/locations/questionnaireUrls',
    method: 'post',
    data: data
  })
}