<template>
  <div>
    <el-row>
      <span class="label">New Source Category </span>
      <el-input v-model="newLeadSource" class="input" />
      <el-button class="button" @click="submitNewSourceCategory(newLeadSource)"> Submit </el-button>
    </el-row>
    <el-row style="margin-top: 15px">
      <el-table
        :data="leadSourcesAndAliases"
        :span-method="objectSpanMethod"
        border
        style="width: 100%"
      >
        <el-table-column
          type="index"
          width="50"
        />
        <el-table-column
          prop="lead_source_name"
          label="Source"
        />
        <el-table-column
          prop="lead_alias_name"
          label="Alias"
        />
        <el-table-column
          label="Change Source"
        >
          <template slot-scope="scope">
            <el-select
              v-model="leadSourcesAndAliases[scope.$index].id_lead_source"
              filterable
              placeholder="Select"
              @change="handleSelection(scope.$index, scope.row)"
            >
              <el-option
                v-for="source in leadSources"
                :key="source.id_lead_source"
                :label="source.lead_source_name"
                :value="source.id_lead_source"
              />
            </el-select>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
  </div>
</template>

<script>
// eslint-disable-next-line
import { mapGetters, mapState } from 'vuex'
import { getLeadSourceNames, updateLeadSource, addLeadSource } from '@/api/lead-info'
export default {
  name: 'InlineEditTable',
  data() {
    return {
      newLeadSource: '',
      leadSources: [],
      leadAliases: [],
      leadSourcesAndAliases: [],
      selectedRow: {},
      selectedIndex: ''
    }
  },
  computed: {
    ...mapGetters([
      'name',
      'avatar',
      'roles'
    ])
  },
  watch: {
  },
  mounted: async function() {
    this.reloadPage()
  },
  methods: {
    async submitNewSourceCategory(newLeadSource) {
      console.log(newLeadSource)
      await addLeadSource({ lead_source_name: newLeadSource, inserted_by: this.name })
      this.reloadPage()
      this.newLeadSource = ''
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      var leadSourceGroup = []
      const next_row = this.leadSourcesAndAliases[rowIndex + 1] ? this.leadSourcesAndAliases[rowIndex + 1] : -1
      const previous_row = this.leadSourcesAndAliases[rowIndex - 1] ? this.leadSourcesAndAliases[rowIndex - 1] : -1
      // calculate number of rows to merge for column 1
      if (columnIndex === 1) {
        if (row.id_lead_source === next_row.id_lead_source && row.id_lead_source !== previous_row.id_lead_source) {
          leadSourceGroup = this.leadSourcesAndAliases.filter((source) => {
            return source.id_lead_source === row.id_lead_source
          })
        }
        if (leadSourceGroup.length > 0) {
          return { rowspan: leadSourceGroup.length, colspan: 1 }
        } else if (row.id_lead_source === previous_row.id_lead_source) {
          return { rowspan: 0, colspan: 1 }
        } else {
          return { rowspan: 1, colspan: 1 }
        }
      }
    },
    async handleSelection(index, row) {
      console.log(row)
      console.log([this.leadAliases[index].id_lead_alias, this.leadAliases[index].id_lead_source, this.name])
      await updateLeadSource({ id_lead_alias: row.id_lead_alias, id_lead_source: row.id_lead_source, inserted_by: this.name })
      await this.reloadPage()
    },
    async reloadPage() {
      try {
        const res = await getLeadSourceNames()
        this.leadSources = res.body.leadSources.sort((a, b) => Number(a.id_lead_source) - Number(b.id_lead_source))
        this.leadAliases = res.body.leadAliases.sort((a, b) => Number(a.id_lead_source) - Number(b.id_lead_source))
        this.leadSourcesAndAliases = res.body.leadSourcesAndAliases.sort((a, b) => Number(a.id_lead_source) - Number(b.id_lead_source))
      } catch (e) {
      // notify error
      }
    }
  }
}

</script>

<style scoped>
.edit-input {
  padding-right: 100px;
}

.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}

.pagination-container {
  margin-top: 0px;
  padding: 15px 16px;
}

.chart-container {
  position: relative;
  width: 100%;
  height: calc(100vh - 84px);
}

.label {
  display: inline-block;
  width: 200px;
}

.input {
  display: inline-block;
  width: 200px;
  padding-right: 10px;
}

.button {
  display: inline-block;
}
</style>
