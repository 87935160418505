var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 7 } }, [
            _c(
              "div",
              { staticClass: "date-control-container" },
              [
                _c("el-date-picker", {
                  attrs: {
                    size: "mini",
                    type: "daterange",
                    "range-separator": "To",
                    "start-placeholder": "Start date",
                    "end-placeholder": "End date",
                    "value-format": "yyyy-MM-dd",
                  },
                  on: { change: _vm.fetchRecordsRange },
                  model: {
                    value: _vm.dateFilter,
                    callback: function ($$v) {
                      _vm.dateFilter = $$v
                    },
                    expression: "dateFilter",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div"),
          _c(
            "el-col",
            {
              staticStyle: {
                "padding-left": "10px",
                "padding-right": "10px",
                "padding-top": "10px",
                "padding-bottom": "10px",
              },
              attrs: { span: 4 },
            },
            [
              _c("div"),
              _c("el-input", {
                attrs: { size: "mini", placeholder: "Filter by Phone Number" },
                model: {
                  value: _vm.searchBox,
                  callback: function ($$v) {
                    _vm.searchBox = $$v
                  },
                  expression: "searchBox",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticStyle: { "margin-top": "15px" }, attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _c(
              "div",
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.listLoading,
                        expression: "listLoading",
                      },
                    ],
                    staticStyle: { width: "100%" },
                    attrs: {
                      id: "analyticsTable",
                      data: _vm.rows.filter(function (data) {
                        return (
                          !_vm.searchBox ||
                          data["Lead Phone"]
                            .toLowerCase()
                            .includes(_vm.searchBox.toLowerCase())
                        )
                      }),
                      border: "",
                      fit: "",
                      stripe: "",
                      "max-height": 800,
                      "show-summary": false,
                      "highlight-current-row": "",
                    },
                  },
                  _vm._l(_vm.columns, function (col) {
                    return _c("el-table-column", {
                      key: col,
                      attrs: {
                        label: col,
                        prop: col,
                        sortable: "",
                        resizable: true,
                        "show-overflow-tooltip": "",
                        align: "left",
                        "header-align": "center",
                        "column-key": col,
                      },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }