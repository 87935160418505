var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      [
        _c("CalendarAddAttributesForm", {
          attrs: {
            "modal-visible": _vm.calendarAddAttributesFormVisible,
            lazy: true,
          },
          on: {
            close: function ($event) {
              _vm.calendarAddAttributesFormVisible = false
            },
            "close-and-refresh": _vm.closeAndRefresh,
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      [
        _c("CalendarEditAttributesForm", {
          attrs: {
            "modal-visible": _vm.calendarEditAttributesFormVisible,
            lazy: true,
            "calendar-attributes-to-edit": _vm.calendarAttributesToEdit,
          },
          on: {
            close: function ($event) {
              _vm.calendarEditAttributesFormVisible = false
            },
            "close-and-refresh": _vm.closeAndRefresh,
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.isLoading,
            expression: "isLoading",
          },
        ],
        staticClass: "table-container",
        attrs: {
          "element-loading-text": "Fetching Calendars...",
          "element-loading-spinner": "el-icon-loading",
          "element-loading-background": "rgba(0, 0, 0, 0.8)",
        },
      },
      [
        _c("h2", [_vm._v(" Linked Clio Calendars")]),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: { data: _vm.calendarAttributesTable },
          },
          [
            _c("el-table-column", {
              attrs: { fixed: "", prop: "name", label: "Account" },
            }),
            _c("el-table-column", {
              attrs: {
                fixed: "",
                prop: "clio_calendar_name",
                label: "Clio Name",
              },
            }),
            _c("el-table-column", {
              attrs: {
                fixed: "",
                prop: "calendar_name",
                label: "Display Name",
              },
            }),
            _c("el-table-column", {
              attrs: { prop: "calendar_city", label: "City" },
            }),
            _c("el-table-column", {
              attrs: { prop: "calendar_state", label: "State" },
            }),
            _c("el-table-column", {
              attrs: { align: "center", label: "Operations" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            link: "",
                            align: "center",
                            type: "primary",
                            size: "small",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.editCalendar(
                                scope.$index,
                                _vm.calendarAttributesTable
                              )
                            },
                          },
                        },
                        [_vm._v("Edit")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            link: "",
                            align: "center",
                            type: "primary",
                            size: "small",
                          },
                          nativeOn: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.removeCalendar(
                                scope.$index,
                                _vm.calendarAttributesTable
                              )
                            },
                          },
                        },
                        [_vm._v("Remove")]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c(
          "el-button",
          {
            staticClass: "mt-4",
            staticStyle: { width: "100%" },
            on: { click: _vm.openAddCalendarForm },
          },
          [_vm._v("Add Calendar")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }