var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "element-loading-text": "Adding Questionnaire URL...",
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "rgba(0, 0, 0, 0.8)",
        title: "Add Questionnaire URL",
        visible: _vm.modalVisible,
        width: "575px",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.modalVisible = $event
        },
        close: _vm.closeModal,
      },
    },
    [
      _c("el-input", {
        attrs: { label: "Questionnaire URL", type: "text" },
        model: {
          value: _vm.questionnaire_url,
          callback: function ($$v) {
            _vm.questionnaire_url = $$v
          },
          expression: "questionnaire_url",
        },
      }),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.closeModal } }, [
            _vm._v("Cancel"),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submit()
                },
              },
            },
            [_vm._v("Submit")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }