<template>
  <div class="account-container">
    <el-row :gutter="20">
      <el-col :span="2" class="label"><span>Email:</span> </el-col>
      <el-col :span="6" class="label"><span>{{ user.email }}</span> </el-col>
    </el-row>
    <!-- <el-row :gutter="20">
      <el-col :span="2" class="label"><span>Firm:</span> </el-col>
      <el-col :span="6" class="label"><span>{{ user.firm }}</span> </el-col>
    </el-row> -->
    <el-row :gutter="20">
      <el-col :span="2" class="label"><span>Roles:</span> </el-col>
      <el-col :span="22">
        <!-- <el-tag v-for="role in user.roles" key: class="role" size="mini" effect="plain">
          {{ role | roleFilter }}
        </el-tag> -->
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  filters: {
    roleFilter(role) {
      var strippedRole = role.replace('legal-merge-', '')
      var roleParts = strippedRole.split('-')
      var rolePartsJoined = roleParts.join(' ')
      return rolePartsJoined
    }
  },
  props: {
    user: {
      type: Object,
      default: () => {
        return {
          email: '',
          firm: '',
          roles: []
        }
      }
    }
  },
  methods: {
    submit() {
      this.$message({
        message: 'User information has been updated successfully',
        type: 'success',
        duration: 5 * 1000
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.account-container {
  .el-row {
    padding-bottom: 15px;
    .label {
      color: #303133;
      text-decoration: none;
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;
    }

  }

}

</style>
