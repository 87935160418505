<template id="template">

  <div
    class="app-container"
  >
    <div>
      <CalendarAddAttributesForm
        :modal-visible="calendarAddAttributesFormVisible"
        :lazy="true"
        @close="calendarAddAttributesFormVisible = false"
        @close-and-refresh="closeAndRefresh"
      />
    </div>
    <div>
      <CalendarEditAttributesForm
        :modal-visible="calendarEditAttributesFormVisible"
        :lazy="true"
        :calendar-attributes-to-edit="calendarAttributesToEdit"
        @close="calendarEditAttributesFormVisible = false"
        @close-and-refresh="closeAndRefresh"
      />
    </div>
    <div
      v-loading="isLoading"
      class="table-container"
      element-loading-text="Fetching Calendars..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <h2> Linked Clio Calendars</h2>
      <el-table :data="calendarAttributesTable" style="width: 100%">
        <el-table-column fixed prop="name" label="Account" />
        <el-table-column fixed prop="clio_calendar_name" label="Clio Name" />
        <el-table-column fixed prop="calendar_name" label="Display Name" />
        <el-table-column prop="calendar_city" label="City" />
        <el-table-column prop="calendar_state" label="State" />
        <el-table-column align="center" label="Operations">
          <template slot-scope="scope">
            <el-button
              link
              align="center"
              type="primary"
              size="small"
              @click="editCalendar(scope.$index, calendarAttributesTable)"
            >Edit</el-button>
            <el-button
              link
              align="center"
              type="primary"
              size="small"
              @click.native.prevent="removeCalendar(scope.$index, calendarAttributesTable)"
            >Remove</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-button
        class="mt-4"
        style="width: 100%"
        @click="openAddCalendarForm"
      >Add Calendar</el-button>
    </div>
  </div>
</template>

<script>

import * as apiCalendar from '@/api/admin-calendar-management'
import CalendarAddAttributesForm from './calendar-add-attributes-form'
import CalendarEditAttributesForm from './calendar-edit-attributes-form'
import { responseValidator } from '@/utils/response-validator'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'CalendarAttributes',
  components: {
    'CalendarAddAttributesForm': CalendarAddAttributesForm,
    'CalendarEditAttributesForm': CalendarEditAttributesForm
  },
  props: {
  },
  data() {
    return {
      isLoading: false,
      data: '0',
      // calendar table data
      calendarAttributesList: '',
      calendarAttributesTable: [],
      calendarAttributesColumns: [],
      selectedCalendarRow: null,
      calendarAddAttributesFormVisible: false,
      calendarEditAttributesFormVisible: false,
      calendarAttributesToEdit: {}
    }
  },
  computed: {
    ...mapGetters([
      'name',
      'avatar',
      'roles'
    ])
  },
  created: async function() {},
  mounted: async function() {
    this.isLoading = true
    await this.fetchCalendars()
    this.isLoading = false
  },
  methods: {
    async openAddCalendarForm() {
      this.calendarAddAttributesFormVisible = true
    },
    closeAndRefresh() {
      this.calendarAddAttributesFormVisible = false
      this.calendarEditAttributesFormVisible = false
      this.fetchCalendars()
    },
    async removeCalendar(index, rows) {
      this.isLoading = true
      try {
        const resp = await apiCalendar.deleteCalendarAttributesList({
          id_location_attributes_list: rows[index].id_location_attributes_list,
          id_location: rows[index].id_location,
          inserted_by: this.name
        })
        const validator = responseValidator(resp)
        if (!validator.success) throw validator
        await this.fetchCalendars()
      } catch (e) {
        this.$notify.error({ title: 'Error!', message: e.message ? e.message : 'Unable to get data.' })
      }
      this.isLoading = false
    },
    async editCalendar(index, rows) {
      this.calendarEditAttributesFormVisible = true
      this.calendarAttributesToEdit = rows[index]
    },
    async fetchCalendars() {
      try {
        const resp = await apiCalendar.getCalendarAttributesLists()
        const validator = responseValidator(resp)
        if (!validator.success) throw validator
        this.calendarAttributesTable = resp.calendar_attributes_lists
      } catch (e) {
        this.$notify.error({ title: 'Error!', message: e.message ? e.message : 'Unable to get data.' })
      }
    },
    async handleCalendarTableSelect(row) {
      this.selectedCalendarRow = row
    }

  }
}
</script>
<style lang="scss" scoped>
  .el-col {
    border-radius: 4px;
    margin-left: 20px;
  }
  .el-row {
    margin-bottom: 20px;
  }
  .text-input-label {
    display: inline-block;
    width: 200px;
    height: 36px;
    text-align: right;
    padding-top: 10px;
    padding-right: 20px;
  }
  .app-container {
    padding-top:10px;
    padding-bottom: 10px;
    overflow: auto;
  }
  .template {
    height: 100vh;
    padding-top:0px;
    padding-bottom: 0px;
    overflow: auto;
  }
  .table-container {
    display: flex;
    width: 60%;
    min-width: 840px;
    flex-direction: column;
    align-items: center;
  }

</style>

<style lang="scss">
  .el-tabs--border-card > .el-tabs__content {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    overflow: auto !important;
  }
  .el-tab-pane {
    height: 100vh;
    overflow: auto !important;
    overflow-y: auto !important;
  }
</style>
