<template>
  <div>
    <div class="app-container">
      <el-dialog
        title="Review Caller Information"
        :visible.sync="reviewDialogVisible"
        width="50%"
        :before-close="onReviewDialogCancel"
      >
        <el-table
          :data="reviewTableData"
          style="width: 100%"
          border
          :show-header="false"
        >
          <el-table-column prop="attribute" label="Attribute" width="180" />
          <el-table-column prop="enteredValue" label="Entered Value" />
        </el-table>

        <span slot="footer" class="dialog-footer">
          <el-checkbox
            v-model="createAppointmentChecked"
            style="padding-right: 10px"
          >Create Appointment</el-checkbox>
          <el-button @click="onReviewDialogCancel">Cancel</el-button>
          <el-button
            :loading="loadingWhileCreatingClioObjects"
            type="primary"
            @click="onReviewDialogConfirm"
          >Confirm</el-button>
        </span>
      </el-dialog>

      <el-dialog
        title="Existing Client"
        :visible.sync="dialogManualPhoneSearchVisible"
      >
        <el-table
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column
            prop="date"
            label="Date"
            width="180"
          />
          <el-table-column
            prop="location"
            label="Location"
            width="180"
          />
          <el-table-column
            prop="name"
            label="Name"
            width="180"
          />
          <el-table-column
            prop="summary"
            label="Summary"
          />
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            @click="dialogManualPhoneSearchVisible = false"
          >Close</el-button>
        </span>
      </el-dialog>

      <BadLeadDialog :dialog-visible="badLeadDialogVisible" @close="closeBadLeadDialog" />

      <ContactedLeadDialog v-bind.sync="contactedLeadDialogValues" @close="closeContactedLeadDialog" />

      <el-form
        ref="newClientForm"
        :model="newClientForm"
        :rules="rules"
        label-width="120px"
        label-position="top"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="First Name" prop="firstName">
              <el-input v-model="newClientForm.firstName" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Last Name" prop="lastName">
              <el-input v-model="newClientForm.lastName" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item type="email" label="Email" prop="emailAddress">
              <el-input
                v-model="newClientForm.emailAddress"
                class="input-with-select"
              >
                <el-select
                  slot="append"
                  v-model="newClientForm.emailType"
                  placeholder="Email Type"
                  style="width: 150px"
                >
                  <el-option
                    v-for="type in contactEmailTypes"
                    :key="type.value"
                    :value="type.value"
                    :label="type.value"
                  />
                </el-select>
              </el-input>

            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Phone Number" prop="phoneNumber">
              <el-input
                v-model="newClientForm.phoneNumber"
                class="input-with-select"
              >
                <el-button
                  slot="append"
                  type="info"
                  plain
                  icon="el-icon-search"
                  style="width: 100px"
                  @click="manualPhoneSearch()"
                />
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="Street Address" prop="streetAddress">
              <el-input v-model="newClientForm.streetAddress" />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="City" prop="city">
              <el-input v-model="newClientForm.city" />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="County" prop="county">
              <el-input v-model="newClientForm.county" />
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <el-form-item label="State" prop="state">
              <el-input v-model="newClientForm.state" />
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="Zip" prop="zip">
              <el-input v-model="newClientForm.zip" />
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="Address Type" prop="addressType">
              <el-select
                v-model="newClientForm.addressType"
                placeholder="Address Type"
                style="width: 100%"
              >
                <el-option
                  v-for="addType in contactAddressTypes"
                  :key="addType.value"
                  :value="addType.value"
                  :label="addType.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="Referral Sources" prop="referralSource">
              <el-select
                v-model="newClientForm.referralSource"
                filterable
                placeholder="Referral Sources"
                style="width: 100%"
              >
                <el-option
                  v-for="source in referralSources"
                  :key="source.value"
                  :value="source.value"
                  :label="source.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="Calendar" prop="calendar">
              <el-select
                v-model="newClientForm.calendar"
                placeholder="Select"
                :value-key="calendarKey"
                @change="onCalendarSelectionChange"
              >
                <el-option
                  v-for="calendar, index in calendars"
                  :key="index"
                  :label="calendar.calendar_name"
                  :value="calendar"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="Consultation" prop="consultationType">
              <el-select
                v-model="newClientForm.consultationType"
                filterable
                placeholder="Consultation Type"
                style="width: 100%"
                :value-key="consultationTypeKey"
                :disabled="newClientForm.calendar == null"
              >
                <el-option
                  v-for="consultationType in consultationTypes"
                  :key="consultationType.id_consultation_type"
                  :value="consultationType"
                  :label="consultationType.consultation_type"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="Can Text?" prop="permissionToText">
              <el-radio-group v-model="newClientForm.permissionToText">
                <el-radio border :label="true">Yes</el-radio>
                <el-radio border :label="false">No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="Previous office visit?"
              prop="previousAppointment"
            >
              <el-radio-group v-model="newClientForm.previousAppointment">
                <el-radio border :label="true">Yes</el-radio>
                <el-radio border :label="false">No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Setting Appointment?" prop="callerSettingAppointment">
              <el-radio-group v-model="newClientForm.callerSettingAppointment" @change="checkShowContactedLeadDialog">
                <el-radio border :label="true">Setting</el-radio>
                <el-radio border :label="false">No. Contact Only</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="Call Reason(s)" prop="callReason">
          <el-checkbox-group v-model="newClientForm.callReason">
            <el-checkbox
              v-model="newClientForm.behindOnMortgage"
              label="Behind on Mortgage"
              name="callReason"
            />
            <el-checkbox
              v-model="newClientForm.pendingRepossession"
              label="Pending Repossession"
              name="callReason"
            />
            <el-checkbox
              v-model="newClientForm.forclosurePending"
              label="Foreclosure Pending"
              name="callReason"
            />
            <el-checkbox
              v-model="newClientForm.medicalBills"
              label="Medical Bills"
              name="callReason"
            />
            <el-checkbox
              v-model="newClientForm.garnishmentPending"
              label="Garnishment Pending"
              name="callReason"
            />
            <el-checkbox
              v-model="newClientForm.creditCardBills"
              label="Credit Card Bills"
              name="callReason"
            />
            <el-checkbox
              v-model="newClientForm.lawsuitPending"
              label="Lawsuit Pending"
              name="callReason"
            />
            <el-checkbox
              v-model="newClientForm.loans"
              label="Loans"
              name="callReason"
            />
            <el-checkbox
              v-model="newClientForm.loans"
              label="Other"
              name="callReason"
            />
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="Call Notes" prop="callNotes">
          <el-input
            v-model="newClientForm.callNotes"
            :autosize="{ minRows: 4, maxRows: 8 }"
            type="textarea"
            maxlength="511"
            show-word-limit
          />

        </el-form-item>
        <el-row :gutter="20">

          <el-col :span="8">
            <el-form-item>
              <el-button type="danger" icon="el-icon-s-flag" @click="badLeadDialogVisible=true">Bad Lead</el-button>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item />
          </el-col>

          <el-col :span="4">
            <el-button
              type="primary"
              @click="submitForm('newClientForm')"
            >Create</el-button>
            <el-button @click="resetForm('newClientForm')">Reset</el-button>
            <!-- <el-button type="primary" @click="testAutofill"> test</el-button> -->
          </el-col>
        </el-row>
      </el-form>
      <location-reference-flyout>
        <locations-script />
      </location-reference-flyout>

      <right-panel>
        <motivator-script />
      </right-panel>
    </div>
  </div>
</template>

<style>
.dialog-footer {
  margin: 0 auto;
  text-align: center;
}

.el-drawer__wrapper {
  top: unset;
  bottom: 0px !important;
  height: 300px !important;
}
.el-drawer.btt {
  height: 100% !important;
}
.rowPaddingVert {
  padding: 5px 0px 5px 0px;
}
.topForm {
  width: 240px;
  min-height: 36px;
  border-radius: 4px;
  box-sizing: border-box;
}
</style>

<script>
import RightPanel from '@/components/RightPanel'
import LocationReferenceFlyout from '@/components/LocationReferenceFlyout'
import MotivatorScript from '@/components/MotivatorScript'
import LocationsScript from '@/components/LocationsScript'
import { createClioItems, tryFindExistingCaller } from '@/api/clio'
import { validPhone, validEmail } from '@/utils/validate'
// import { bus } from '@/main'
import { addcontact, addmatter, addMatterAndContact } from '@/api/lmdbManagement'
import { mapGetters, mapState } from 'vuex'
import BadLeadDialog from '@/components/BadLeadDialog'
import ContactedLeadDialog from '@/components/ContactedLeadDialog'
import * as apiCalendars from '@/api/calendar-management'
import { responseValidator } from '@/utils/response-validator'
import { referral_sources } from './shit/referral_sources.json'
import * as contact_info_types from './shit/contact_info_types.json'

export default {
  clientId: undefined,
  name: 'NewClient',
  caller: '',
  components: {
    RightPanel,
    MotivatorScript,
    LocationsScript,
    LocationReferenceFlyout,
    BadLeadDialog,
    ContactedLeadDialog
  },
  data() {
    const validatePhoneNumber = (rule, value, callback) => {
      if (value) {
        if (validPhone(value)) {
          return callback()
        } else {
          return callback(
            new Error('Invalid phone. Valid format is XXXXXXXXXX 10 digits')
          )
        }
      } else {
        return callback(new Error('Field cannot be left blank.'))
      }
    }
    const validateEmail = (rule, value, callback) => {
      if (!value) {
        callback()
      }

      if (!validEmail(value)) {
        callback(
          new Error('Invalid email. Example format example123@test456.com')
        )
      } else {
        callback()
      }
    }
    return {
      consultationTypeKey: 'id_consultation_type',
      calendarKey: 'id_location_attributes_list',
      rules: {
        firstName: [
          {
            required: true,
            message: 'Field cannot be left blank.',
            trigger: 'blur'
          }
        ],
        lastName: [
          {
            required: true,
            message: 'Field cannot be left blank.',
            trigger: 'blur'
          }
        ],
        emailAddress: [
          {
            type: 'email',
            required: false,
            validator: validateEmail,
            trigger: 'blur'
          }
        ],
        phoneNumber: [
          { required: true, validator: validatePhoneNumber, trigger: 'blur' }
        ],
        calendar: [
          {
            required: true,
            message: 'Please select a calendar.',
            trigger: 'blur'
          }
        ],
        consultationType: [
          {
            required: true,
            message: 'Please select a consultation type.',
            trigger: 'blur'
          }
        ],
        referralSource: [
          {
            required: true,
            message: 'Please select a referral source.',
            trigger: 'change'
          }
        ],
        callReason: [
          {
            type: 'array',
            required: true,
            message: 'Please select at least one call reason',
            trigger: 'change'
          }
        ],
        permissionToText: [
          {
            type: 'boolean',
            required: true,
            message: 'Please select if can text.',
            trigger: 'change'
          }
        ],
        previousAppointment: [
          {
            type: 'boolean',
            required: true,
            message: 'Please select if had previous appointment.',
            trigger: 'change'
          }
        ],
        callerSettingAppointment: [
          {
            type: 'boolean',
            required: true,
            message: 'Please choose whether the caller plans to set an appointment.',
            trigger: 'change'
          }
        ]
      },
      loadingWhileCreatingClioObjects: false,
      referralSources: referral_sources,
      consultationTypes: [],
      contactPhoneTypes: contact_info_types.contact_phone_types,
      contactEmailTypes: contact_info_types.contact_email_types,
      contactAddressTypes: contact_info_types.contact_address_types,
      tableData: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      calendars: [],
      lmdbContact: {},
      newClientForm: this.initNewClientForm(),
      drawer: false,
      direction: 'btt',
      drawerModal: false,
      appendToBody: true,
      loading: false,
      reviewDialogVisible: false,
      createAppointmentChecked: true,
      existingCallerFoundDialogVisible: false,
      dialogManualPhoneSearchVisible: false,
      callerSettingAppointment: undefined,
      badLeadDialogVisible: false,
      contactedLeadDialogVisible: false,
      contactedLeadDialogValues: {
        number: undefined,
        note: undefined,
        referralSource: undefined,
        zipCode: undefined,
        dialogVisible: false
      },
      existingCaller: {
        clioOffice: undefined,
        firstName: undefined,
        lastName: undefined,
        phoneNumber: undefined,
        emailAddress: undefined
      },
      selectedCalendar: {
        id_location: '-1'
      }
    }
  },
  computed: {
    ...mapGetters([
      'name',
      'avatar',
      'roles'
    ]),
    reviewTableData: function() {
      var summary = [
        {
          attribute: 'Calendar',
          enteredValue: this.newClientForm.calendar?.name
        },
        {
          attribute: 'First Name',
          enteredValue: this.newClientForm?.firstName
        },
        { attribute: 'Last Name', enteredValue: this.newClientForm?.lastName },
        {
          attribute: 'Email Address',
          enteredValue: this.newClientForm?.emailAddress
        },
        {
          attribute: 'Phone Number',
          enteredValue: this.newClientForm?.phoneNumber
        },
        {
          attribute: 'Street Address',
          enteredValue: this.newClientForm?.streetAddress
        },
        { attribute: 'City', enteredValue: this.newClientForm?.city },
        { attribute: 'County', enteredValue: this.newClientForm?.county },
        { attribute: 'State', enteredValue: this.newClientForm?.state },
        { attribute: 'Zip', enteredValue: this.newClientForm?.zip },
        {
          attribute: 'Address Type',
          enteredValue: this.newClientForm?.addressType
        },
        {
          attribute: 'Referral Source',
          enteredValue: this.newClientForm?.referralSource
        },
        {
          attribute: 'Cosultation Type',
          enteredValue: this.newClientForm?.consultationType?.consultation_type
        },
        {
          attribute: 'Can Text',
          enteredValue: this.newClientForm.permissionToText ? 'Yes' : 'No'
        },
        {
          attribute: 'Previous Office Visit',
          enteredValue: this.newClientForm.previousAppointment ? 'Yes' : 'No'
        },
        {
          attribute: 'Call Reason(s)',
          enteredValue: this.newClientForm?.callReason?.join()
        },
        {
          attribute: 'Call Notes',
          enteredValue: this.newClientForm?.callNotes
        }
      ]
      return summary
    },
    manualSearchTableData() {
      var summary = [
        { attribute: 'Clio Office', value: this.existingCaller?.clioOffice },
        { attribute: 'First Name', value: this.existingCaller?.first_name },
        { attribute: 'Last Name', value: this.existingCaller?.last_name },
        {
          attribute: 'Email Address',
          value: this.existingCaller?.primary_email_address
        },
        { attribute: 'Phone Number', value: this.existingCaller?.phoneNumber }
      ]
      return summary
    },
    ...mapState(['contactedLeadInformation'])
  },
  async mounted() {
    try {
      const resp = await apiCalendars.getClioLinkedCalendars()
      const validator = responseValidator(resp)
      if (!validator.success) throw resp
      this.calendars = resp.calendar_attributes_lists
    } catch (e) {
      this.$notify.error({ title: 'Oops!', message: 'Unable to retrieve calendar list.' })
    }
  },
  methods: {
    closeBadLeadDialog() {
      this.badLeadDialogVisible = false
    },
    customResetForm(inputForm) {
      if (inputForm) {
        try {
          Object.keys(inputForm).forEach(key => {
            inputForm[key] = undefined // reset all the values for each key.
          })
        } catch {
          // error
        }
      }
    },
    closeContactedLeadDialog(data) {
      this.contactedLeadDialogValues.dialogVisible = false
      if (data === 'reset') {
        const resetForm = 'newClientForm'
        this.$refs[resetForm].resetFields()
        this.initNewClientForm()
      } else {
        this.newClientForm.callerSettingAppointment = undefined
        this.newClientForm.phoneNumber = data.number
        this.newClientForm.callNotes = data.note
        this.newClientForm.referralSource = data.referralSource
        this.newClientForm.zip = data.zipCode
      }
    },
    checkShowContactedLeadDialog() {
      if (!this.newClientForm.callerSettingAppointment) {
        this.contactedLeadDialogValues.number = this.newClientForm.phoneNumber
        this.contactedLeadDialogValues.note = this.newClientForm.callNotes
        this.contactedLeadDialogValues.referralSource = this.newClientForm.referralSource
        this.contactedLeadDialogValues.zipCode = this.newClientForm.zip
        this.contactedLeadDialogValues.dialogVisible = true
      }
    },
    testAutofill() {
      // testUpdateSms()
      var now = new Date()
      this.newClientForm.firstName = 'test'
      this.newClientForm.lastName = now.toISOString()
      // this.newClientForm.emailAddress = 'buddy@legalmerge.com'
      this.newClientForm.phoneNumber = '2517530048'
      this.newClientForm.referralSource = 'Facebook'
      this.newClientForm.consultationType = { id_consultation_type: 1, consultation_type: 'con1' }
      this.newClientForm.streetAddress = '1234 test rd'
      this.newClientForm.city = 'mobile'
      this.newClientForm.county = 'mobile'
      this.newClientForm.state = 'AL'
      this.newClientForm.zip = '36619'
      this.newClientForm.addressType = 'home'
      this.newClientForm.permissionToText = false
      this.newClientForm.previousAppointment = false
      this.newClientForm.callReason = ['Loans']
      this.newClientForm.callNotes = 'asdf'
    },
    async onCalendarSelectionChange(val) {
      this.newClientForm.calendar = val
      this.consultationTypes = val.consultation_types
      this.newClientForm.consultationType = null
    },
    initNewClientForm() {
      return {
        firstName: undefined,
        lastName: undefined,
        emailAddress: undefined,
        emailType: undefined,
        phoneNumber: undefined,
        phoneType: undefined,
        referralSource: undefined,
        consultationType: undefined,
        permissionToText: undefined,
        streetAddress: undefined,
        city: undefined,
        county: undefined,
        state: undefined,
        zip: undefined,
        country: undefined,
        addressType: undefined,
        previousAppointment: undefined,
        callerSettingAppointment: undefined,
        callReason: [],
        callNotes: undefined,
        calendar: undefined
      }
    },
    async createContactAsync(contactInfo) {
      this.loading = true
      var postBody = {
        data: {
          first_name: this.newClientForm.firstName,
          last_name: this.newClientForm.lastName,
          phone_numbers: [
            {
              name: 'Mobile',
              number: this.newClientForm.phoneNumber,
              default_number: true
            }
          ],
          type: 'Person'
        }
      }

      if (this.newClientForm.emailAddress) {
        postBody.data.email_addresses = [
          {
            name: 'Home',
            address: this.newClientForm.emailAddress,
            default_email: true
          }
        ]
      }
      postBody.data.addresses = [
        {
          name: 'Home',
          street: this.newClientForm.streetAddress
            ? this.newClientForm.streetAddress
            : 'n/a',
          city: this.newClientForm.city ? this.newClientForm.city : 'n/a',
          province: this.newClientForm.state ? this.newClientForm.state : 'n/a',
          postal_code: this.newClientForm.zip ? this.newClientForm.zip : 'n/a',
          country: 'United States'
        }
      ]
      var contact = await createClioItems(
        'contacts.json?clientoffice=' + this.newClientForm.calendar.id_connection_entity,
        postBody
      )
      this.lmdbContact = contact.data
      this.lmdbContact['can_text'] = this.newClientForm.permissionToText
      this.lmdbContact['phone_number'] = this.newClientForm.phoneNumber
      this.lmdbContact['inserted_by'] = this.name
      this.lmdbContact['zip_code'] = this.newClientForm.zip
      this.lmdbContact['referral_source'] = this.newClientForm.referralSource
      // const lmContactRes = await addcontact(lmdbContact)
      // if (lmContactRes.success) {
      //   this.loading = false
      // } else {
      //   this.notifyLmError('LM Contact not added. Notify LegalMerge.')
      //   this.loading = false
      // }
      return contact
    },
    async createMatterAsync(contact) {
      this.loading = true
      var postBody = {
        data: {
          client: {
            id: contact.data.id // TODO: verify this is correct (looks to match in clio api docs)
          },
          description:
            this.newClientForm.lastName + ', ' + this.newClientForm.firstName,
          status: 'pending'
        }
      }
      var matter = await createClioItems(
        'matters.json?clientoffice=' + this.newClientForm.calendar.id_connection_entity,
        postBody
      )
      matter.summary =
        this.newClientForm.lastName + ', ' + this.newClientForm.firstName
      this.loading = false
      var data = {
        id_client: postBody.data.client.id,
        id_matter: matter.data.id,
        phone_number: this.lmdbContact.phone_number,
        zip_code: this.lmdbContact.zip_code,
        referral_source: this.lmdbContact.referral_source,
        can_text: this.newClientForm.permissionToText,
        id_location: this.newClientForm.calendar.id_connection_entity,
        id_campaign: 1,
        id_attorney: 0,
        inserted_by: this.name
      }
      const lmMatterResp = await addMatterAndContact({ data: data })
      if (lmMatterResp.success) {
        this.loading = false
      } else {
        this.notifyLmError('LM Matter not added. Notify LegalMerge.')
        this.loading = false
      }
      return matter
    },
    resetForm(formName) {
      if (typeof formName !== undefined) {
        this.$refs[formName].resetFields()
      }
    },
    onReviewDialogCancel() {
      this.reviewDialogVisible = false
    },
    notifyClioError(msg) {
      this.$notify({
        title: 'Clio',
        message: msg,
        type: 'error',
        duration: 4000
      })
    },
    notifyLmError(msg) {
      this.$notify({
        title: 'LegalMerge',
        message: msg,
        type: 'error',
        duration: 4000
      })
    },
    async onReviewDialogConfirm() {
      try {
        this.loadingWhileCreatingClioObjects = true
        var contact = await this.createContactAsync()
        if (contact) {
          const matter = await this.createMatterAsync(contact)
          if (matter) {
            this.$notify({
              title: 'Clio',
              message: 'Contact and matter successfully created.',
              type: 'success',
              duration: 4000
            })
            this.loadingWhileCreatingClioObjects = false
            this.reviewDialogVisible = false
            const matter_id = matter.data.id
            if (this.createAppointmentChecked) {
              var matterNotes =
                'PHONE: ' + this.newClientForm?.phoneNumber + '\n\n' +
                'EMAIL: ' + this.newClientForm.emailAddress + '\n\n' +
                'CONSULTATION TYPE: ' + this.newClientForm.consultationType.consultation_type + '\n\n' +
                'REFERRAL SOURCE: ' + this.newClientForm.referralSource + '\n\n' +
                'CALL REASON(S): ' + this.newClientForm?.callReason?.join() + '\n\n' +
                'CALL NOTES: ' + this.newClientForm?.callNotes + '\n\n' +
                'COUNTY: ' + this.newClientForm.county + '\n\n' +
                'ADDRESS: ' + '\n' + this.newClientForm.streetAddress + ',\n' +
                  this.newClientForm.city + ', ' +
                  this.newClientForm.state + ', ' +
                  this.newClientForm.zip + '\n\n' +
                'ADDRESS TYPE: ' + this.newClientForm.addressType + '\n\n' +
                'CAN TEXT:' + (this.newClientForm?.permissionToText ? `Yes` : `No`) + '\n\n' +
                'CREATED BY: ' + this.name
              const newAppointmentInformation = {
                office_id: this.newClientForm.calendar.id_connection_entity,
                calendar_clio_id: this.newClientForm.calendar.calendar_id_clio,
                calendar_name: this.newClientForm.calendar.name,
                call_reason: this.newClientForm?.callReason?.join(),
                call_notes: this.newClientForm?.callNotes,
                appointment_consultation_type: this.newClientForm?.consultationType.consultation_type,
                contact_name: contact.data.name,
                contact_email_type: this.newClientForm.emailType,
                contact_phone_Type: this.newClientForm.phoneType,
                contact_street_address: this.newClientForm.streetAddress,
                contact_city: this.newClientForm.city,
                contact_county: this.newClientForm.county,
                contact_state: this.newClientForm.state,
                contact_zip: this.newClientForm.zip,
                contact_address_type: this.newClientForm.addressType,
                office_location: this.newClientForm.calendar,
                referral_source: this.newClientForm.referralSource,
                appointment_state: 'create',
                description: matterNotes,
                type: 1,
                summary: contact.data.name,
                matter: {
                  display_number: matter_id,
                  id: matter_id,
                  description: contact.data.name,
                  client: {
                    id: contact.data.id,
                    canText: (this.newClientForm?.permissionToText ? `Yes` : `No`),
                    clientNumber: this.newClientForm?.phoneNumber,
                    clientEmail: this.newClientForm.emailAddress
                  },
                  dateRange: undefined,
                  appointmentTime: '',
                  appointmentId: undefined
                }
              }
              this.$store.dispatch('scheduling/setNewAppointmentInformation', newAppointmentInformation)
              this.resetForm('newClientForm')
              this.$router.push('/scheduling/index')
            } else {
              this.resetForm('newClientForm')
            }
            return
          } else {
            this.notifyClioError('Error creating clio items.')
            this.loadingWhileCreatingClioObjects = false
            this.reviewDialogVisible = false
          }
        } else {
          this.notifyClioError('Error creating clio items.')
          this.loadingWhileCreatingClioObjects = false
          this.reviewDialogVisible = false
        }
      } catch (e) {
        this.notifyClioError('Error creating clio items.')
        this.loadingWhileCreatingClioObjects = false
        this.reviewDialogVisible = false
      }
    },
    async submitForm(formName) {
      if (!this.newClientForm?.emailAddress) {
        this.$confirm('Continue without providing an email address?')
          .then((_) => {
            this.$refs[formName].validate((valid) => {
              if (valid) {
                this.reviewDialogVisible = true
              } else {
                this.reviewDialogVisible = false
                return false
              }
            })
          })
          .catch((_) => {})
      } else {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.reviewDialogVisible = true
          } else {
            this.reviewDialogVisible = false
            return false
          }
        })
      }
    },
    async manualPhoneSearch() {
      if (
        this.newClientForm.phoneNumber &&
        this.newClientForm.phoneNumber.length === 10
      ) {
        var manualstrippedCallerNoSpace =
          this.newClientForm.phoneNumber.replace(/[^0-9]/g, '')
        // var manualstrippedCallerWithSpace =
        //   manualstrippedCallerNoSpace?.replace(
        //     /(\d{3})(\d{3})(\d{2})/,
        //     '$1 $2 $3'
        //   )

        const searchingForContacts = this.$loading({
          lock: true,
          text:
            'Searching Clio locations for number ' +
            this.newClientForm.phoneNumber,
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        try {
          await tryFindExistingCaller({
            strippedCallerNoSpace: manualstrippedCallerNoSpace
            // strippedCallerWithSpace: manualstrippedCallerWithSpace
          }).then((response) => {
            searchingForContacts.close()
            if (response && response.length > 0) {
              this.dialogManualPhoneSearchVisible = true
              this.tableData = response
            } else {
              this.$message('Number not found in any Clio locations')
            }
          })
        } catch (e) {
          // failure
        }
      }
    }
  }
}
</script>

