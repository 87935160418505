import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'/'el-icon-x' the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect/index')
      }
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },
  {
    path: '/auth-redirect',
    component: () => import('@/views/login/auth-redirect'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/error-page/404'),
    hidden: true
  },
  {
    path: '/401',
    component: () => import('@/views/error-page/401'),
    hidden: true
  },
  {
    path: '/',
    component: Layout,
    redirect: '/whats-new',
    children: [
      {
        path: 'whats-new',
        component: () => import('@/views/whats-new/index'),
        name: 'Whats New!',
        meta: { title: 'What\'s New!', icon: 'star', affix: true }
      }
    ]
  },
  // {
  //   path: '/',
  //   component: Layout,
  //   redirect: '/dashboard',
  //   children: [
  //     {
  //       path: 'dashboard',
  //       component: () => import('@/views/dashboard/index'),
  //       name: 'Dashboard',
  //       meta: { title: 'Dashboard', icon: 'dashboard', affix: true }
  //     }
  //   ]
  // },
  {
    path: '/profile',
    component: Layout,
    redirect: '/profile/index',
    hidden: true,
    children: [
      {
        path: 'index',
        component: () => import('@/views/profile/index'),
        name: 'Profile',
        meta: { title: 'Profile', icon: 'user', noCache: true }
      }
    ]
  }
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = [
  {
    path: '/new-client',
    component: Layout,
    children: [
      {
        path: 'index',
        component: () => import('@/views/new-client/index'),
        name: 'NewClient',
        meta: {
          title: 'New Client',
          icon: 'el-icon-user',
          roles: ['legal-merge-analytics', 'legal-merge-call-center'],
          noCache: true
        }
      }
    ]
  },
  {
    path: '/scheduling',
    component: Layout,
    children: [
      {
        path: 'index',
        component: () => import('@/views/scheduling/index'),
        name: 'Scheduling',
        meta: {
          title: 'Scheduling',
          icon: 'el-icon-date',
          roles: ['legal-merge-analytics', 'legal-merge-call-center'],
          noCache: true
        }
      }
    ]
  },
  {
    path: '/cc_message-dash',
    component: Layout,
    children: [
      {
        path: 'index',
        component: () => import('@/views/cc_message-dash/index'),
        name: 'cc_message-dash',
        meta: {
          title: 'Message Dashboard',
          icon: 'el-icon-message',
          roles: ['legal-merge-analytics', 'legal-merge-call-center'],
          noCache: true
        }
      }
    ]
  },
  {
    path: '/cc-appt-results',
    component: Layout,
    children: [
      {
        path: 'index',
        component: () => import('@/views/cc-appt-results/index'),
        name: 'cc-appt-results',
        meta: {
          title: 'Appt Results',
          icon: 'el-icon-notebook-2',
          roles: ['legal-merge-analytics', 'legal-merge-call-center'],
          noCache: true
        }
      }
    ]
  },
  {
    path: '/feedback',
    component: Layout,
    children: [
      {
        path: 'index',
        component: () => import('@/views/feedback/index'),
        name: 'Feedback',
        meta: {
          title: 'Feedback',
          icon: 'el-icon-postcard',
          noCache: true
        }
      }
    ]
  },
  {
    path: '/analytics-tabs',
    component: Layout,
    children: [
      {
        path: 'index',
        component: () => import('@/views/analytics-tabs/index'),
        name: 'Analytics Tabs',
        meta: {
          title: 'Analytics', icon: 'el-icon-data-analysis', roles: ['legal-merge-analytics'],
          noCache: true }
      }
    ]
  },
  {
    path: '/admin_settings',
    component: Layout,
    children: [
      {
        path: 'index',
        component: () => import('@/views/admin_settings/index'),
        name: 'Admin Tabs',
        meta: {
          title: 'Admin', icon: 'el-icon-setting',
          roles: ['legal-merge-analytics'],
          noCache: true }
      }
    ]
  },

  // 404 page must be placed at the end !!!
  { path: '*', redirect: '/404', hidden: true }
]

const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
