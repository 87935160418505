var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-card", { staticStyle: { "margin-bottom": "20px" } }, [
    _c(
      "div",
      { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
      [_c("span", [_vm._v("About me")])]
    ),
    _c("div", { staticClass: "user-profile" }, [
      _c(
        "div",
        { staticClass: "box-center" },
        [
          _c(
            "pan-thumb",
            {
              attrs: {
                image: _vm.user.avatar,
                height: "100px",
                width: "100px",
                hoverable: false,
              },
            },
            [
              _c("div", [_vm._v("Hello")]),
              _vm._v(" " + _vm._s(_vm.user.role) + " "),
            ]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "box-center" }, [
        _c("div", { staticClass: "user-name text-center" }, [
          _vm._v(_vm._s(_vm.user.name)),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "user-bio" }, [
      _c("div", { staticClass: "user-education user-bio-section" }, [
        _c(
          "div",
          { staticClass: "user-bio-section-header" },
          [
            _c("svg-icon", { attrs: { "icon-class": "office-building" } }),
            _c("span", [_vm._v("Firm")]),
          ],
          1
        ),
        _c("div", { staticClass: "user-bio-section-body" }, [
          _c("div", { staticClass: "text-muted" }, [_vm._v(" Bond & Botes ")]),
        ]),
      ]),
      _c("div", { staticClass: "user-skills user-bio-section" }, [
        _c(
          "div",
          { staticClass: "user-bio-section-header" },
          [
            _c("svg-icon", { attrs: { "icon-class": "component" } }),
            _c("span", [_vm._v("Roles")]),
          ],
          1
        ),
        _c("div", { staticClass: "user-bio-section-body" }, [
          _c(
            "div",
            { staticClass: "tag-group" },
            _vm._l(_vm.user.roles, function (item) {
              return _c(
                "el-tag",
                { key: item, attrs: { type: "success", effect: "dark" } },
                [_vm._v(" " + _vm._s(_vm._f("roleFilter")(item)) + " ")]
              )
            }),
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }