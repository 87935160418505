<template>
  <el-dialog
    v-loading="loading"
    title="Lead Contacted. Not Setting Appointment"
    element-loading-text="Submitting Lead..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    :visible.sync="dialogVisible"
    :show-close="showCloseButton"
    :close-on-click-modal="closeOnClickModal"
    @open="openModal"
  >
    <el-form ref="contactedLeadForm" :model="contactedLeadData" :rules="rules" label-width="auto">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="Phone Number" prop="number">
            <el-input v-model="contactedLeadData.number" maxlength="10" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Zip Code" prop="zipCode">
            <el-input v-model="contactedLeadData.zipCode" maxlength="12" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="Referral Sources" prop="referralSource" label-width="auto">
            <el-select
              v-model="contactedLeadData.referralSource"
              filterable
              placeholder="Referral Sources"
              style="width=100%"
            >
              <el-option
                v-for="source in referralSources"
                :key="source.value"
                :value="source.value"
                :label="source.value"
              />
            </el-select>

          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-form-item label="Notes" prop="note">
          <el-input v-model="contactedLeadData.note" type="textarea" maxlength="255" show-word-limit />
        </el-form-item>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeModal">Cancel</el-button>
      <el-button type="primary" @click="onSubmit('contactedLeadForm')">Submit Contacted Lead</el-button>
    </span>
  </el-dialog>

</template>

<script>
import { mapGetters } from 'vuex'
import { addContactedLead } from '@/api/lmdbManagement'
import { validPhone } from '@/utils/validate'

export default {
  name: 'ContactedLeadDialog',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    number: {
      type: String,
      default: undefined
    },
    note: {
      type: String,
      default: undefined
    },
    referralSource: {
      type: String,
      default: undefined
    },
    zipCode: {
      type: String,
      default: undefined
    }
  },
  data() {
    const validatePhoneNumber = (rule, value, callback) => {
      if (value) {
        if (validPhone(value)) {
          return callback()
        } else {
          return callback(
            new Error('Invalid phone. Valid format is XXXXXXXXXX 10 digits')
          )
        }
      } else {
        return callback(new Error('Field cannot be left blank.'))
      }
    }
    return {
      contactedLeadData: {
        number: undefined,
        note: undefined,
        referralSource: undefined,
        zipCode: undefined
      },
      loading: false,
      rules: {
        number: [
          {
            required: true,
            validator: validatePhoneNumber,
            trigger: 'blur'
          }
        ],
        note: [
          {
            required: true,
            message: 'Note field cannot be left blank.',
            trigger: 'blur'
          }
        ],
        referralSource: [
          {
            required: true,
            message: 'Referral Source field cannot be left blank.',
            trigger: 'blur'
          }
        ],
        zipCode: [
          {
            required: false,
            trigger: 'blur'
          }
        ]
      },
      referralSources: [
        { value: 'abi.org' },
        { value: 'Ascend' },
        { value: 'Avvo' },
        { value: 'BBB.org' },
        { value: 'Bing' },
        { value: 'Businessfinder.al.com' },
        { value: 'ClickWhipt' },
        { value: 'CSX.scorpion.co' },
        { value: 'Direct Mail - Foreclosure' },
        { value: 'Direct Mail - Lawsuit' },
        { value: 'duckduckgo.com' },
        { value: 'expertise.com ' },
        { value: 'Facebook' },
        { value: 'Friend/Family' },
        { value: 'Findlaw.com' },
        { value: 'Form 123' },
        { value: 'Google' },
        { value: 'GMB' },
        { value: 'HelpPath' },
        { value: 'Intaker' },
        { value: 'Justia.com' },
        { value: 'law-finders.com' },
        { value: 'LawInfo.com' },
        { value: 'Lawyers.com' },
        { value: 'Lawyers.findlaw.com' },
        { value: 'Linkedin.com' },
        { value: 'LSA' },
        { value: 'natlbankruptcy.com' },
        { value: 'NOLO' },
        { value: 'nvlsp.org' },
        { value: 'Phone (Direct)' },
        { value: 'Prior Client' },
        { value: 'Referral' },
        { value: 'Search.aol.com' },
        { value: 'Search.tb.ask.com' },
        { value: 'startpage.com ' },
        { value: 'Television' },
        { value: 'Upsolve' },
        { value: 'Upsource' },
        { value: 'Webform' },
        { value: 'Yahoo' },
        { value: '720' }
      ],
      showCloseButton: false,
      closeOnClickModal: false,
      contactedLeadObject: {
        number: undefined,
        note: undefined,
        referralSource: undefined,
        zipCode: undefined
      }
    }
  },
  computed: {
    ...mapGetters([
      'name'
    ])
  },
  methods: {
    notifyError(msg) {
      this.$notify({
        title: 'Error',
        message: msg,
        type: 'error',
        duration: 4000
      })
    },
    notifySuccess(msg) {
      this.$notify({
        title: 'Success',
        message: msg,
        type: 'success',
        duration: 4000
      })
    },
    closeModal() {
      this.contactedLeadObject.number = this.contactedLeadData.number
      this.contactedLeadObject.note = this.contactedLeadData.note
      this.contactedLeadObject.referralSource = this.contactedLeadData.referralSource
      this.contactedLeadObject.zipCode = this.contactedLeadData.zipCode
      this.$emit('close', this.contactedLeadObject)
    },
    closeResetAll() {
      this.$emit('close', 'reset')
    },
    resetForm(formName) {
      this.closeResetAll()

      /* leaving the code below just in case the reset form
      fix breaks or isn't the best solution (WJS 2022_04_09)
      */

      // this.$nextTick(() => {
      //   this.$refs[formName].resetFields()
      //     .then((_) => {
      //       this.closeResetAll()
      //     })
      // })
    },
    openModal() {
      this.contactedLeadData.number = this.number
      this.contactedLeadData.referralSource = this.referralSource
      this.contactedLeadData.zipCode = this.zipCode
      this.contactedLeadData.note = this.note
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.callAddContactedLead()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    async callAddContactedLead() {
      var contactedLeadData = {
        phone_number: this.contactedLeadData.number,
        lead_quality: 'C',
        referral_source: this.contactedLeadData.referralSource,
        inserted_by: this.name,
        lead_quality_note: this.contactedLeadData.note,
        zip_code: this.contactedLeadData.zipCode
      }
      try {
        this.loading = true
        var addLeadResponse = await addContactedLead(contactedLeadData)
        this.loading = false
        if (addLeadResponse) {
          this.notifySuccess('Contacted Lead successfully submitted')
          console.log(addLeadResponse)
          this.resetForm('contactedLeadForm')
        }
      } catch (e) {
        if (this.loading === true) {
          this.loading = false
        }
        this.notifyError('Error submitting contacted lead')
        console.log(e)
      }
    }
  }
}
</script>

      <style>

      </style>
