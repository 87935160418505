export default {
  'common.border': '1px solid #ddd',
  'common.backgroundColor': 'white',
  'common.holiday.color': '#f54f3d',
  'common.saturday.color': '#135de6',
  'common.dayname.color': '#333',
  'common.today.color': '#009688',

  // creation guide style
  'common.creationGuide.backgroundColor': 'rgba(19, 93, 230, 0.1)',
  'common.creationGuide.border': '1px solid #135de6',

  // month header 'dayname'
  'month.dayname.height': '42px',
  'month.dayname.borderLeft': 'none',
  'month.dayname.paddingLeft': '0',
  'month.dayname.paddingRight': '0',
  'month.dayname.fontSize': '13px',
  'month.dayname.backgroundColor': 'inherit',
  'month.dayname.fontWeight': 'normal',
  'month.dayname.textAlign': 'center',

  // month day grid cell 'day'
  'month.holidayExceptThisMonth.color': '#f3acac',
  'month.dayExceptThisMonth.color': '#bbb',
  'month.weekend.backgroundColor': '#fafafa',
  'month.day.fontSize': '16px',

  // month schedule style
  'month.schedule.height': '18px',
  'month.schedule.marginTop': '2px',
  'month.schedule.marginLeft': '10px',
  'month.schedule.marginRight': '10px',

  // month more view
  'month.moreView.boxShadow': 'none',
  'month.moreView.paddingBottom': '0',
  'month.moreViewTitle.height': '28px',
  'month.moreViewTitle.marginBottom': '0',
  'month.moreViewTitle.backgroundColor': '#f4f4f4',
  'month.moreViewTitle.borderBottom': '1px solid #ddd',
  'month.moreViewTitle.padding': '0 10px',
  'month.moreViewList.padding': '10px',

  // week header 'dayname'
  'week.dayname.height': '41px',
  'week.dayname.borderTop': '1px solid #ddd',
  'week.dayname.borderBottom': '1px solid #ddd',
  'week.dayname.borderLeft': '1px solid #ddd',
  'week.dayname.paddingLeft': '5px',
  'week.dayname.backgroundColor': 'inherit',
  'week.dayname.textAlign': 'left',
  'week.today.color': '#009688',
  'week.pastDay.color': '#999',

  // week vertical panel 'vpanel'
  'week.vpanelSplitter.border': '1px solid #ddd',
  'week.vpanelSplitter.height': '3px',

  // week daygrid 'daygrid'
  'week.daygrid.borderRight': '1px solid #ddd',

  'week.daygridLeft.width': '100px',
  'week.daygridLeft.backgroundColor': '',
  'week.daygridLeft.paddingRight': '5px',
  'week.daygridLeft.borderRight': '1px solid #ddd',

  'week.today.backgroundColor': 'inherit',
  'week.weekend.backgroundColor': 'inherit',

  // week timegrid 'timegrid'
  'week.timegridLeft.width': '100px',
  'week.timegridLeft.backgroundColor': '#fafafa',
  'week.timegridLeft.borderRight': '1px solid #ddd',
  'week.timegridLeft.fontSize': '12px',
  'week.timegridLeftTimezoneLabel.height': '51px',
  'week.timegridLeftAdditionalTimezone.backgroundColor': '#fdfdfd',

  'week.timegridOneHour.height': '56px',
  'week.timegridHalfHour.height': '28px',
  'week.timegridHalfHour.borderBottom': '1px dotted #f9f9f9',
  'week.timegridHorizontalLine.borderBottom': '1px solid #eee',

  'week.timegrid.paddingRight': '10px',
  'week.timegrid.borderRight': '1px solid #ddd',
  'week.timegridSchedule.borderRadius': '0',
  'week.timegridSchedule.paddingLeft': '0',

  'week.currentTime.color': '#135de6',
  'week.currentTime.fontSize': '12px',
  'week.currentTime.fontWeight': 'bold',

  'week.pastTime.color': '#999',
  'week.pastTime.fontWeight': 'normal',

  'week.futureTime.color': '#333',
  'week.futureTime.fontWeight': 'normal',

  'week.currentTimeLinePast.border': '1px solid rgba(19, 93, 230, 0.3)',
  'week.currentTimeLineBullet.backgroundColor': '#135de6',
  'week.currentTimeLineToday.border': '1px solid #135de6',
  'week.currentTimeLineFuture.border': '1px solid #135de6',

  // week creation guide style
  'week.creationGuide.color': '#135de6',
  'week.creationGuide.fontSize': '12px',
  'week.creationGuide.fontWeight': 'bold',

  // week daygrid schedule style
  'week.dayGridSchedule.borderRadius': '0',
  'week.dayGridSchedule.height': '18px',
  'week.dayGridSchedule.marginTop': '2px',
  'week.dayGridSchedule.marginLeft': '10px',
  'week.dayGridSchedule.marginRight': '10px'
}
