<template>
  <div class="app-container">
    <h1>Welcome to Legal Merge</h1>
    <h3> Whats New: </h3>
    <aside>
      <p><b>5/17/2023 </b><br><br>
        Fixes <br>
        - Fixed an issue in admin settings where adding a calendar would fail on fields that aren't required.<br>
        - Added in admin settings validation to the edit/add calendar forms.<br>
        - Fixed an issue where questionnaire urls wouldn't get pulled properly in some cases.<br>
        - Fixed an issue in analytics where results weren't being sorted by location.<br>
      </p>
      <p><b>5/09/2023 </b><br><br>
        New Features <br>
        - Added clio account linking, status and management.<br>
        - Added calendar linking and management.<br>
        - Locked scheduling calendar selection to clio account of origin.<br>
        Fixes <br>
        - Fixed an issue where scheduling calendar would allow date/time selection for appointment before data populates causing a failed create.<br>
      </p>
      <p><b>8/09/2022 </b><br><br>
        Fixes <br>
        - Fixed some front-end issues on calendar.<br>
      </p>
      <p><b>8/07/2022 </b><br><br>
        Updates <br>
        - Removed Anniston from Calendar select options.<br>
      </p>
      <p><b>7/13/2022 </b><br><br>
        New Feature <br>
        - Call Center Appointment Results Page.<br>
      </p>
      <p><b>6/21/2022 </b><br><br>
        Updates <br>
        - Security update.<br>
        - Increased timeout to 20s <br><br>
      </p>
      <p><b>6/18/2022 </b><br><br>
        Fixes <br>
        - Fixed an issue with the messaging dashboard, should display properly now.<br><br>
      </p>
      <p><b>6/17/2022 </b><br><br>
        New Features <br>
        - Added Message Dashboard for message sending/receiving verification.  Leads can be looked up by phone number.  The dash will only report a maximum of 150 records, so care must be taken to restrict the date range to 1-3 days.<br>
        Updates <br>
        - The analytics dashboard in LegalMerge has been updated to include more robust leads reporting. It should now deliver all of the requested data.  <br><br>
      </p>
      <p><b>6/7/2022 </b><br><br>
        New Features <br>
        - Added analytics Appointment Results for firm admins.<br><br>
      </p>
      <p><b>5/11/2022 </b><br><br>
        Fixes <br>
        - Fixed an issue with texting the wrong appointment time on first text. <br>
        - Fixed an issue with emails erroring out at the Mobile office.<br><br>
      </p>
      <p><b>5/8/2022 </b><br><br>
        New Features <br>
        - Enabled the analytics for admins. <br>
        Changes: <br>
        - Swapped the create matter and client create button with the 'bad lead' report button <br>
        - Doubled the number of characters able to be entered for notes. <br>
        - Added an 'Other' category for call reason. <br><br>
      </p>
      <p><b>4/25/2022 </b><br><br>
        New Features: <br>
        - Added a phone number lookup to search existing clients. <br>
        - Added a contacted but no appointment set button & data entry. <br>
        - Added a bad lead button & data entry. <br>
        - Added backend tracking for bad leads. <br>
        - Added backend tracking for leads that contact but don't set appointments. <br><br>
      </p>
      <p><b>3/21/2022 </b><br><br>
        Fixes: <br>
        - Fixed an issue where appointment was being marked wrong in Legal Merge database.<br><br>
        Changes: <br>
        - Added a backend webhook refresher for clio (wont affect anything in the front end). <br>
      </p>
      <p><b>3/17/2022 </b><br><br>
        Fixes: <br>
        - Added a notification message when a matter/contact is not found in our database on reschedule. <br>
        - Fixed the hangup when matter/contact not found in database on reschedule.<br><br>
      </p>
      <p><b>3/1/2022 </b><br><br>
        New Features: <br>
        - Added a 'Whats New!' page to keep call center specialists informed about site changes! <br><br>
        Fixes: <br>
        - Fixed error where calendar was showing up in appointment type. <br>
        - Fixed bug where client name would be presented as "last, first" on reschedule instead of "first last" format <br><br>
        Changes: <br>
        - Moved 'CONSULTATION TYPE' up in the description for appointments for faster viewing, moved 'CAN TEXT' down. <br>
      </p>
    </aside>
  </div>
</template>

<script>

export default {
  name: 'WhatsNew',
  props: {},
  data() {
    return {
      data: '0'
    }
  },
  created() {},
  mounted() {},
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
