var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "custom-class": "wrong-calendar-dialog",
            modal: true,
            visible: _vm.isCurrentCallerWrongCalendar,
            title: "Wait!",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isCurrentCallerWrongCalendar = $event
            },
          },
        },
        [
          _c("p", [
            _vm._v(
              "You are about to add an appointment to a different calendar than selected on the intake form! The selected calendar was "
            ),
            _c("span", { staticStyle: { color: "red" } }, [
              _vm._v(_vm._s(_vm.intakeSelectedCalendar)),
            ]),
            _vm._v("."),
          ]),
          _c("p", [_vm._v("Please review your selection before proceeding.")]),
          _c(
            "el-button",
            {
              attrs: { type: "Primary" },
              on: {
                click: function ($event) {
                  _vm.isCurrentCallerWrongCalendar = false
                },
              },
            },
            [_vm._v(" Close ")]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "el-drag-dialog", rawName: "v-el-drag-dialog" }],
          attrs: {
            "custom-class": "appointmentDialog",
            "show-close": true,
            "close-on-click-modal": false,
            modal: false,
            visible: _vm.appointmentDialog.isVisible,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.appointmentDialog, "isVisible", $event)
            },
            closed: function ($event) {
              return _vm.onAppointmentDialogClosed("appointmentForm")
            },
            dragDialog: _vm.handleDrag,
          },
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _vm.showCurrentCallerButton
              ? _c(
                  "span",
                  {
                    staticStyle: { width: "100% !important" },
                    attrs: { slot: "label" },
                    slot: "label",
                  },
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        staticStyle: { "padding-left": "10px" },
                        attrs: {
                          effect: "dark",
                          content:
                            "Use current caller's most recently created matter and contact information.",
                          placement: "top",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: _vm.applyMatterButtonDisabled,
                              type: "success",
                              size: "mini",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.applyCallersMatter()
                              },
                            },
                          },
                          [_vm._v("Apply Current Caller's Matter")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c(
            "el-form",
            {
              ref: "appointmentForm",
              attrs: {
                size: "mini",
                model: _vm.appointmentForm,
                rules: _vm.appointmentDialog.rules,
                "label-position": "top",
                "label-width": "120px",
                disabled: _vm.appointmentDialog.isReadOnly,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Summary", prop: "summary" } },
                [
                  _c("el-input", {
                    attrs: {
                      autosize: { minRows: 1, maxRows: 8 },
                      type: "textarea",
                    },
                    model: {
                      value: _vm.appointmentForm.summary,
                      callback: function ($$v) {
                        _vm.$set(_vm.appointmentForm, "summary", $$v)
                      },
                      expression: "appointmentForm.summary",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Description", prop: "description" } },
                [
                  _c("el-input", {
                    attrs: {
                      autosize: { minRows: 4, maxRows: 8 },
                      type: "textarea",
                    },
                    model: {
                      value: _vm.appointmentForm.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.appointmentForm, "description", $$v)
                      },
                      expression: "appointmentForm.description",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { type: "flex", justify: "left", gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Matter", prop: "matter" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                "value-key": "description",
                                filterable: "",
                                remote: "",
                                "reserve-keyword": "",
                                placeholder: "Type in matter name",
                                "remote-method": _vm.matterSelection,
                                loading:
                                  _vm.appointmentDialog.matterSelectionLoading,
                                disabled: _vm.appointmentDialog.isReadOnly,
                              },
                              on: { change: _vm.onMatterOptionSelected },
                              model: {
                                value: _vm.appointmentForm.matter,
                                callback: function ($$v) {
                                  _vm.$set(_vm.appointmentForm, "matter", $$v)
                                },
                                expression: "appointmentForm.matter",
                              },
                            },
                            _vm._l(
                              _vm.appointmentDialog.matterSelectionOptions,
                              function (matter) {
                                return _c("el-option", {
                                  key: matter.id,
                                  attrs: {
                                    value: matter,
                                    label: matter.description,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { type: "flex", justify: "left", gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Date", prop: "dateRange" } },
                        [
                          _c("el-date-picker", {
                            staticClass: "filter-item",
                            attrs: {
                              type: "datetimerange",
                              format: "MM/d/yyyy hh:mm:ss A",
                              "value-format": "MM/d/yyyy hh:mm:ss A",
                              align: "right",
                              readonly: true,
                              "unlink-panels": true,
                              "range-separator": "To",
                              "start-placeholder": "Start date",
                              "end-placeholder": "End date",
                              clearable: false,
                            },
                            model: {
                              value: _vm.appointmentForm.dateRange,
                              callback: function ($$v) {
                                _vm.$set(_vm.appointmentForm, "dateRange", $$v)
                              },
                              expression: "appointmentForm.dateRange",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.cancelAppointmentCreation("appointmentForm")
                    },
                  },
                },
                [_vm._v("Close")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        !_vm.appointmentDialog.isReadOnly &&
                        !_vm.appointmentDialog.isEditing,
                      expression:
                        "!appointmentDialog.isReadOnly && !appointmentDialog.isEditing",
                    },
                  ],
                  attrs: {
                    loading: _vm.loadingWhileCreatingClioObjects,
                    size: "mini",
                    type: "primary",
                    disabled: _vm.appointmentDialog.isReadOnly,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.submitAppointmentCreation("appointmentForm")
                    },
                  },
                },
                [_vm._v("Confirm")]
              ),
              _c(
                "el-popover",
                {
                  attrs: { placement: "top", width: "260" },
                  model: {
                    value: _vm.appointmentPopoverVisible,
                    callback: function ($$v) {
                      _vm.appointmentPopoverVisible = $$v
                    },
                    expression: "appointmentPopoverVisible",
                  },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { "text-align": "center", margin: "0" } },
                    [
                      _c(
                        "el-button-group",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "plain", size: "mini" },
                              on: {
                                click: function ($event) {
                                  _vm.setScheduleEditable("appointmentForm")
                                  _vm.appointmentPopoverVisible = false
                                },
                              },
                            },
                            [_vm._v("Edit")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "plain", size: "mini" },
                              on: {
                                click: function ($event) {
                                  _vm.appointmentPopoverVisible = false
                                  _vm.appointmentRescheduleDialogVisible = true
                                },
                              },
                            },
                            [_vm._v("Resched.")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "danger", size: "mini" },
                              on: {
                                click: function ($event) {
                                  _vm.appointmentPopoverVisible = false
                                  _vm.appointmentCancelDialogVisible = true
                                },
                              },
                            },
                            [_vm._v("Cancel")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.appointmentDialog.isReadOnly &&
                            !_vm.appointmentDialog.isEditing &&
                            this.$store.getters.newAppointmentInformation
                              .appointment_state !== "create",
                          expression:
                            "appointmentDialog.isReadOnly && !appointmentDialog.isEditing && (this.$store.getters.newAppointmentInformation.appointment_state !== 'create')",
                        },
                      ],
                      attrs: { slot: "reference", size: "mini" },
                      slot: "reference",
                    },
                    [_vm._v("Modify")]
                  ),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        !_vm.appointmentDialog.isReadOnly &&
                        _vm.appointmentDialog.isEditing,
                      expression:
                        "!appointmentDialog.isReadOnly && appointmentDialog.isEditing",
                    },
                  ],
                  attrs: {
                    loading: _vm.loadingWhileCreatingClioObjects,
                    size: "mini",
                    type: "success",
                    disabled: _vm.appointmentDialog.isReadOnly,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.submitAppointmentPatch("appointmentForm")
                    },
                  },
                },
                [_vm._v("Save Changes")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c("div", { staticClass: "date-control-container" }, [
                _c(
                  "span",
                  [
                    _c("el-button", {
                      attrs: {
                        size: "medium",
                        icon: "el-icon-arrow-left",
                        circle: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onClickNavi("prev")
                        },
                      },
                    }),
                    _c("el-button", {
                      attrs: {
                        type: "medium",
                        icon: "el-icon-arrow-right",
                        circle: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onClickNavi("next")
                        },
                      },
                    }),
                    _c(
                      "span",
                      {
                        staticClass: "render-range",
                        staticStyle: {
                          "margin-left": "10px",
                          "font-weight": "bold",
                        },
                      },
                      [_vm._v(_vm._s(_vm.dateRange))]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          float: "right",
                          "padding-left": "10px",
                          width: "130px",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            model: {
                              value: _vm.selectedView,
                              callback: function ($$v) {
                                _vm.selectedView = $$v
                              },
                              expression: "selectedView",
                            },
                          },
                          _vm._l(
                            _vm.viewModeOptions,
                            function (options, index) {
                              return _c(
                                "el-option",
                                {
                                  key: index,
                                  attrs: {
                                    value: options.value,
                                    label: options.title,
                                  },
                                },
                                [_vm._v(" " + _vm._s(options.title) + " ")]
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          float: "right",
                          "padding-left": "10px",
                          width: "130px",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "Select",
                              "value-key": _vm.calendarKey,
                            },
                            on: { change: _vm.onUpdateSelectedCalendar },
                            model: {
                              value: _vm.clioCalendar,
                              callback: function ($$v) {
                                _vm.clioCalendar = $$v
                              },
                              expression: "clioCalendar",
                            },
                          },
                          _vm._l(_vm.clioCalendars, function (calendar, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: {
                                label: calendar.calendar_name,
                                value: calendar,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: { float: "right", "padding-left": "5px" },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.selectedCalendar.calendar_id_clio > -1 &&
                                  !_vm.appointmentDialog.isVisible,
                                expression:
                                  "selectedCalendar.calendar_id_clio > -1 && !appointmentDialog.isVisible",
                              },
                            ],
                            attrs: { type: "success", icon: "el-icon-refresh" },
                            on: {
                              click: function ($event) {
                                _vm.getList(), (_vm.calendarLoading = true)
                              },
                            },
                          },
                          [_vm._v("Refresh")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: { float: "right", "padding-left": "5px" },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.selectedCalendar.calendar_id_clio > -1 &&
                                  !_vm.appointmentDialog.isVisible,
                                expression:
                                  "selectedCalendar.calendar_id_clio > -1 && !appointmentDialog.isVisible",
                              },
                            ],
                            attrs: {
                              type: "danger",
                              icon: "el-icon-phone-outline",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.hotTransfer()
                              },
                            },
                          },
                          [_vm._v("Hot Transfer")]
                        ),
                      ],
                      1
                    ),
                    this.$store.getters.newAppointmentInformation
                      .appointment_state === "create"
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              float: "right",
                              "padding-left": "10px",
                            },
                          },
                          [
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  content:
                                    "The caller that just had a matter created on the New Client form. ",
                                  placement: "bottom",
                                  effect: "dark",
                                },
                              },
                              [
                                _c(
                                  "el-tag",
                                  {
                                    staticStyle: {
                                      height: "36px",
                                      "line-height": "34px",
                                    },
                                    attrs: {
                                      size: "x-large",
                                      effect: "plain",
                                      type: "success",
                                    },
                                  },
                                  [
                                    _c("b", [_vm._v("Caller:")]),
                                    _vm._v(
                                      " " + _vm._s(_vm.currentCallerName) + " "
                                    ),
                                    _c("b", [_vm._v("Location:")]),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.currentCallerCalendarName)
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c("calendar", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value:
                      _vm.selectedCalendar.calendar_id_clio == -1 ||
                      _vm.calendarLoading,
                    expression:
                      "selectedCalendar.calendar_id_clio == -1 || calendarLoading",
                  },
                ],
                ref: "tuiCal",
                staticStyle: { height: "800px" },
                attrs: {
                  "element-loading-text": _vm.listLoadingText,
                  "element-loading-spinner": "none",
                  "element-loading-background": "rgba(0, 0, 0, 0.2)",
                  "element-loading-custom-class": "calendar-loading-custom",
                  "use-detail-popup": _vm.useDetailPopup,
                  "use-creation-popup": _vm.useCreationPopup,
                  view: _vm.selectedView,
                  calendars: _vm.calendarList,
                  schedules: _vm.scheduleList,
                  theme: _vm.theme,
                  template: _vm.template,
                  "task-view": false,
                  "schedule-view": true,
                  month: _vm.month,
                  week: _vm.week,
                  "disable-dbl-click": _vm.disableDblClick,
                  "is-read-only": _vm.isReadOnly,
                },
                on: {
                  clickSchedule: _vm.onClickSchedule,
                  clickDayname: _vm.onClickDayname,
                  beforeCreateSchedule: _vm.onBeforeCreateSchedule,
                  beforeDeleteSchedule: _vm.onBeforeDeleteSchedule,
                  afterRenderSchedule: _vm.onAfterRenderSchedule,
                  beforeUpdateSchedule: _vm.onBeforeUpdateSchedule,
                  clickTimezonesCollapseBtn: _vm.onClickTimezonesCollapseBtn,
                },
              }),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: "Reschedule Appointment",
                    visible: _vm.appointmentRescheduleDialogVisible,
                    width: "30%",
                    center: "",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.appointmentRescheduleDialogVisible = $event
                    },
                  },
                },
                [
                  _c("span", [
                    _vm._v(
                      "Are you sure you want to Reschedule this appointment?"
                    ),
                  ]),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "plain" },
                          on: {
                            click: function ($event) {
                              _vm.appointmentRescheduleDialogVisible = false
                            },
                          },
                        },
                        [_vm._v("No")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              ;(_vm.appointmentRescheduleDialogVisible = false),
                                (_vm.appointmentDialog.isVisible = false),
                                _vm.appointmentReschedule()
                            },
                          },
                        },
                        [_vm._v("Yes")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: "Cancel Appointment",
                    visible: _vm.appointmentCancelDialogVisible,
                    width: "30%",
                    center: "",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.appointmentCancelDialogVisible = $event
                    },
                  },
                },
                [
                  _c("span", [
                    _vm._v("Are you sure you want to Cancel this appointment?"),
                  ]),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "plain" },
                          on: {
                            click: function ($event) {
                              _vm.appointmentCancelDialogVisible = false
                            },
                          },
                        },
                        [_vm._v("No")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              ;(_vm.appointmentCancelDialogVisible = false),
                                (_vm.appointmentDialog.isVisible = false),
                                _vm.appointmentCancel()
                            },
                          },
                        },
                        [_vm._v("Yes")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: "Appointment Date Passed",
                    visible: _vm.pastApptDialog,
                    width: "30%",
                    center: "",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.pastApptDialog = $event
                    },
                  },
                },
                [
                  _c("span", [
                    _vm._v(
                      "The appointment time and date you've chosen has already passed"
                    ),
                  ]),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.pastApptDialog = false
                            },
                          },
                        },
                        [_vm._v("Close")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: "Send Text?",
                    visible: _vm.textConfirmDialog.isVisible,
                    width: "30%",
                    center: "",
                  },
                  on: {
                    "update:visible": function ($event) {
                      return _vm.$set(
                        _vm.textConfirmDialog,
                        "isVisible",
                        $event
                      )
                    },
                  },
                },
                [
                  _c("span", [_vm._v("Send confirmation text to client?")]),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              ;(_vm.textConfirmDialog.isVisible = false),
                                _vm.cancelSendConfirmationText()
                            },
                          },
                        },
                        [_vm._v("Close")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            loading: _vm.textConfirmDialogLoading,
                            type: "primary",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.sendConfirmationText()
                            },
                          },
                        },
                        [_vm._v("Send")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }