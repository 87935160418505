<template>
  <el-dialog
    :visible.sync="modalVisible"
    :close-on-click-modal="false"
    title="Clio Account Name:"
    @close="onCloseModal"
    @open="onOpenModal"
  >
    <el-input
      v-model="accountName"
      placeholder="Please input account name."
    />
    <el-button
      @click="submitAccountName"
    >
      Accept
    </el-button>
  </el-dialog>
</template>
<script>

import * as apiClioAdmin from '@/api/clio-admin'
import { mapGetters, mapState } from 'vuex'
import { responseValidator } from '@/utils/response-validator'

export default {
  name: 'ExternalAccountsAddClioAcct',
  props: {
    modalVisible: {
      type: Boolean,
      default: false
    },
    accountToEdit: {
      type: Object,
      default: () => { return { } }
    }
  },
  data() {
    return {
      data: '0',
      accountName: '',
      externalAccountsAddClioAcctVisible: false,
      clioAccount: {}
    }
  },
  computed: {
    ...mapGetters([
      'name',
      'avatar',
      'roles'
    ])
  },
  created: async function() {},
  mounted: async function() {
  },
  methods: {
    async submitAccountName() {
      console.log(this.accountToEdit)
      this.clioAccount.account_name = this.accountName
      this.clioAccount.token_id = this.accountToEdit.token_id
      try {
        const resp = await apiClioAdmin.submitClioLinkedAccountName({ clio_account: this.clioAccount, inserted_by: this.name })
        const validator = responseValidator(resp)
        if (!validator.success) throw validator
        this.resetData()
        this.$emit('close-and-refresh')
      } catch (e) {
        this.$notify.error({ title: 'Account Submission Error!', message: e.message ? e.message : 'Unable to submit data.' })
      }
    },
    async getNamelessAccount() {
      try {
        const resp = await apiClioAdmin.getClioLinkedAccounts()
        const validator = responseValidator(resp)
        if (!validator.success) throw validator
        const accountList = resp.clio_accounts
        for (let i = 0; i < accountList.length; i++) {
          if (accountList[i].account_name === '') {
            this.clioAccount = accountList[i]
            break
          }
        }
      } catch (e) {
        this.$notify.error({ title: 'Account Submission Error!', message: e.message ? e.message : 'Unable to submit data.' })
      }
    },
    async onOpenModal() {
      await this.getNamelessAccount()
      console.log('modal load function')
    },
    async onCloseModal() {
      this.resetData()
      this.$emit('close-and-refresh')
    },
    async resetData() {
      this.accountName = ''
      this.clioAccount = {}
    }
  }
}
</script>
<style lang="scss" scoped>
  .el-col {
    border-radius: 4px;
    margin-left: 20px;
  }
  .el-row {
    margin-bottom: 20px;
  }
  .text-input-label {
    display: inline-block;
    width: 200px;
    height: 36px;
    text-align: right;
    padding-top: 10px;
    padding-right: 20px;
  }

</style>
