var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        { attrs: { type: "border-card", lazy: true } },
        [
          _c("el-tab-pane", { attrs: { label: "Calendars", lazy: true } }, [
            _c(
              "div",
              [
                _c("CalendarAttributes", {
                  attrs: { id: "CalendarAttributes", lazy: true },
                }),
              ],
              1
            ),
          ]),
          _c(
            "el-tab-pane",
            { attrs: { label: "External Account Linking", lazy: true } },
            [
              _c(
                "div",
                [
                  _c("ExternalAccounts", {
                    attrs: { id: "ExternalAccounts", lazy: true },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }