<template>
  <div>
    <el-row style="margin-top: 15px">
      <el-col
        :span="24"
      ><div>
        <el-table
          id="analyticsTable"
          v-loading="listLoading"
          :data="rows"
          border
          :fit="true"
          stripe
          :max-height="800"
          :show-summary="false"
          highlight-current-row
          style="width: 100%"
        >
          <el-table-column
            label="Location"
            min-width="180"
            column-key="Location"
            prop="Location"
            header-align="center"
          />
          <el-table-column
            label="Client Name"
            min-width="180"
            column-key="Client Name"
            prop="Client Name"
            header-align="center"
          />
          <el-table-column
            label="Phone Number"
            min-width="180"
            column-key="Phone Number"
            prop="Phone Number"
            header-align="center"
          />
          <el-table-column
            label="Attorney"
            min-width="180"
            column-key="Attorney"
            prop="Attorney"
            header-align="center"
          />
          <el-table-column
            label="Result"
            min-width="180"
            column-key="Result Type"
            prop="Result Type"
            header-align="center"
          />
          <!-- <el-table-column
            v-for="col in columns"
            :key="col"
            :label="col"
            :prop="col"
            sortable
            :resizable="true"
            show-overflow-tooltip
            align="left"
            header-align="center"
            :column-key="col"
          /> -->
        </el-table></div></el-col></el-row>
  </div>
</template>

<script>
import {
  getListData, lookupMatterData
} from '@/api/appointmentresult'
import XLSX from 'xlsx'
import alasql from 'alasql'
// import _ from 'lodash'

const today = new Date()

// var monthFirstDay = new Date(today.getFullYear(), today.getMonth(), 1)

export default {
  clientId: undefined,
  name: 'UnmarkedLeads',
  caller: '',
  components: {},
  props: {
    dateFilter: {
      type: Array,
      default: null
    },
    selectedLocation: {
      type: String,
      default: null
    }
  },
  data() {
    return {

      appointmentList: [],
      appointmentResultTypes: [],
      // dateFilter: [monthFirstDay, today],
      displayDate: today,
      filterTypes: {
        locationFilter: [],
        resultFilter: []
      },
      list: null,
      listLoading: false, // this should be set to true when the data is loaded
      listQuery: {
        page: 1,
        limit: 10,
        location: [],
        listDate: today,
        apptStatusType: null
      },
      locationOptions: [
        { value: null, label: 'All Locations' },
        { value: '1', label: 'Montgomery/Opelika' },
        { value: '2', label: 'Gadsden/Anniston' },
        { value: '3', label: 'Florence/Haleyville' },
        { value: '4', label: 'Huntsville/Decatur/Cullman' },
        { value: '5', label: 'Jackson/Vicksburg/Hattiesburg' },
        { value: '6', label: 'Mobile' },
        { value: '8', label: 'Birmingham' }
      ],
      reportTypes: [{
        value: 'lead',
        label: 'Lead Data'
      }, {
        value: 'responsible_attorney',
        label: 'Responsible Attorney Data'
      }, {
        value: 'originating_attorney',
        label: 'Originating Attorney Data'
      }, {
        value: 'appt_lead_summary',
        label: 'Appointment and Lead Summary'
      },
      {
        value: 'unmarked_leads',
        label: 'Unmarked Leads'
      },
      {
        value: 'lead_interaction_report',
        label: 'Lead Interactions'
      }],
      reportSelected: 'unmarked_leads',
      rows: [],
      selectListLoading: false
    //  selectedLocation: null
    }
  },
  computed: {
    columns: function columns() {
      if (this.rows.length === 0) {
        return []
      }
      return Object.keys(this.rows[0])
    }
  },
  watch: {
    dateFilter: function(newVal, oldVal) { // watch it
      console.log('dateFilter changed: ', newVal, ' | was: ', oldVal)
      this.fetchRecordsRange()
    },
    selectedLocation: function(newVal, oldVal) { // watch it
      console.log('selectedLocation changed: ', newVal, ' | was: ', oldVal)
      this.fetchRecordsRange()
      // this.filterByLocation()
    }
  },
  mounted: async function() {
    await this.fetchRecordsRange()
    // console.log('unmarked-leads mounted')
  },
  methods: {
    createColumnFilterList() {
      var columnArray = this.columns

      var arrayObject = []
      for (var i = 0; i < columnArray.length; i++) {
        arrayObject.push({ id: columnArray[i], hidden: false })
      }
      this.columnFilters = arrayObject
    },
    async getAppointmentResultTypes() {
      const resultTypes = await lookupMatterData([this.dateFilter, 'appointment_result_types'])
      this.appointmentResultTypes = resultTypes.data
    },
    async fetchRecordsRange() {
      //   console.log('fetchRecordsRange ')
    //  console.log('this.selectedLocation ', this.selectedLocation)
      this.listLoading = true
      console.log('this.reportSelected ', this.reportSelected)

      await this.getAppointmentResultTypes()

      console.log('appointmentResultTypes', this.appointmentResultTypes)
      var tableData = await getListData([this.dateFilter, this.reportSelected, this.selectedLocation, today])
      console.log('tableData is ', tableData)
      this.listLoading = false

      var tableDataResultTypes = alasql(`SELECT Location, [Client Name], [Phone Number], Attorney,
      (SELECT result_type FROM ? b WHERE b.id_result_type = a.[Result Type] ) [Result Type]
                                        FROM ? a`, [this.appointmentResultTypes, tableData.data])

      if (tableData.data !== 'none') {
        this.rows = tableDataResultTypes
      } else {
        this.rows = []
      }
    },
    handleExport() {
      var excelExportData = XLSX.utils.json_to_sheet(this.rows)
      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new() // make Workbook of Excel
      XLSX.utils.book_append_sheet(wb, excelExportData, 'Lead Data') // sheetName is name of Worksheet
      XLSX.writeFile(wb, `${this.reportSelected}.xlsx`) // name of the file is 'Export.xlsx'
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination-container {
  margin-top: 0px;
  padding: 15px 16px;
}

.chart-container {
  position: relative;
  width: 100%;
  height: calc(100vh - 84px);
}
</style>
