var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c("span", { staticClass: "label" }, [
            _vm._v("New Source Category "),
          ]),
          _c("el-input", {
            staticClass: "input",
            model: {
              value: _vm.newLeadSource,
              callback: function ($$v) {
                _vm.newLeadSource = $$v
              },
              expression: "newLeadSource",
            },
          }),
          _c(
            "el-button",
            {
              staticClass: "button",
              on: {
                click: function ($event) {
                  return _vm.submitNewSourceCategory(_vm.newLeadSource)
                },
              },
            },
            [_vm._v(" Submit ")]
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticStyle: { "margin-top": "15px" } },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.leadSourcesAndAliases,
                "span-method": _vm.objectSpanMethod,
                border: "",
              },
            },
            [
              _c("el-table-column", { attrs: { type: "index", width: "50" } }),
              _c("el-table-column", {
                attrs: { prop: "lead_source_name", label: "Source" },
              }),
              _c("el-table-column", {
                attrs: { prop: "lead_alias_name", label: "Alias" },
              }),
              _c("el-table-column", {
                attrs: { label: "Change Source" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: { filterable: "", placeholder: "Select" },
                            on: {
                              change: function ($event) {
                                return _vm.handleSelection(
                                  scope.$index,
                                  scope.row
                                )
                              },
                            },
                            model: {
                              value:
                                _vm.leadSourcesAndAliases[scope.$index]
                                  .id_lead_source,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.leadSourcesAndAliases[scope.$index],
                                  "id_lead_source",
                                  $$v
                                )
                              },
                              expression:
                                "leadSourcesAndAliases[scope.$index].id_lead_source",
                            },
                          },
                          _vm._l(_vm.leadSources, function (source) {
                            return _c("el-option", {
                              key: source.id_lead_source,
                              attrs: {
                                label: source.lead_source_name,
                                value: source.id_lead_source,
                              },
                            })
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }