var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-container" },
    [
      _vm.loginState === "signIn"
        ? [
            _c(
              "el-form",
              {
                ref: "loginForm",
                staticClass: "login-form",
                attrs: {
                  model: _vm.loginForm,
                  rules: _vm.loginRules,
                  autocomplete: "on",
                  "label-position": "left",
                },
              },
              [
                _c("div", { staticClass: "title-container" }, [
                  _c("div", [
                    _c("h1", { staticClass: "sidebar-title-legal" }, [
                      _vm._v(_vm._s(_vm.titleLegal) + " "),
                    ]),
                    _c("h1", { staticClass: "sidebar-title-merge" }, [
                      _vm._v(_vm._s(_vm.titleMerge) + " "),
                    ]),
                  ]),
                ]),
                _c(
                  "el-form-item",
                  { attrs: { prop: "username" } },
                  [
                    _c(
                      "span",
                      { staticClass: "svg-container" },
                      [_c("svg-icon", { attrs: { "icon-class": "user" } })],
                      1
                    ),
                    _c("el-input", {
                      ref: "username",
                      attrs: {
                        placeholder: "Email",
                        name: "username",
                        type: "text",
                        tabindex: "1",
                        autocomplete: "on",
                      },
                      model: {
                        value: _vm.loginForm.username,
                        callback: function ($$v) {
                          _vm.$set(_vm.loginForm, "username", $$v)
                        },
                        expression: "loginForm.username",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      content: "Caps lock is On",
                      placement: "right",
                      manual: "",
                    },
                    model: {
                      value: _vm.capsTooltip,
                      callback: function ($$v) {
                        _vm.capsTooltip = $$v
                      },
                      expression: "capsTooltip",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "password" } },
                      [
                        _c(
                          "span",
                          { staticClass: "svg-container" },
                          [
                            _c("svg-icon", {
                              attrs: { "icon-class": "password" },
                            }),
                          ],
                          1
                        ),
                        _c("el-input", {
                          key: _vm.passwordType,
                          ref: "password",
                          attrs: {
                            type: _vm.passwordType,
                            placeholder: "Password",
                            name: "password",
                            tabindex: "2",
                            autocomplete: "on",
                          },
                          on: {
                            blur: function ($event) {
                              _vm.capsTooltip = false
                            },
                          },
                          nativeOn: {
                            keyup: [
                              function ($event) {
                                return _vm.checkCapslock($event)
                              },
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.handleLogin($event)
                              },
                            ],
                          },
                          model: {
                            value: _vm.loginForm.password,
                            callback: function ($$v) {
                              _vm.$set(_vm.loginForm, "password", $$v)
                            },
                            expression: "loginForm.password",
                          },
                        }),
                        _c(
                          "span",
                          {
                            staticClass: "show-pwd",
                            on: { click: _vm.showPwd },
                          },
                          [
                            _c("svg-icon", {
                              attrs: {
                                "icon-class":
                                  _vm.passwordType === "password"
                                    ? "eye"
                                    : "eye-open",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-button",
                  {
                    staticStyle: { width: "100%", "margin-bottom": "30px" },
                    attrs: { loading: _vm.loading, type: "primary" },
                    nativeOn: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.handleLogin($event)
                      },
                    },
                  },
                  [_vm._v("Login")]
                ),
                _c("div", { staticStyle: { position: "relative" } }, [
                  _c(
                    "div",
                    { staticClass: "tips" },
                    [
                      _c("span", [_vm._v("Forgot your password? ")]),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.setLoginState("resetPasswordRequest")
                            },
                          },
                        },
                        [_vm._v("Reset password")]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm.loginState === "resetPasswordRequest"
        ? [
            _c(
              "el-form",
              {
                ref: "resetPasswordRequestForm",
                staticClass: "login-form",
                attrs: {
                  model: _vm.resetPasswordRequestForm,
                  rules: _vm.resetPasswordRequestRules,
                  autocomplete: "on",
                  "label-position": "left",
                },
              },
              [
                _c("div", { staticClass: "title-container" }, [
                  _c("div", [
                    _c("h1", { staticClass: "sidebar-title-legal" }, [
                      _vm._v(_vm._s(_vm.titleLegal) + " "),
                    ]),
                    _c("h1", { staticClass: "sidebar-title-merge" }, [
                      _vm._v(_vm._s(_vm.titleMerge) + " "),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "reset-container" }, [
                  _c("div", [_c("h5", {}, [_vm._v("Reset your password")])]),
                ]),
                _c(
                  "el-form-item",
                  { attrs: { prop: "usernameToReset" } },
                  [
                    _c(
                      "span",
                      { staticClass: "svg-container" },
                      [_c("svg-icon", { attrs: { "icon-class": "user" } })],
                      1
                    ),
                    _c("el-input", {
                      ref: "usernameToReset",
                      attrs: {
                        placeholder: "Email",
                        name: "username",
                        type: "text",
                        tabindex: "1",
                        autocomplete: "on",
                      },
                      model: {
                        value: _vm.resetPasswordRequestForm.usernameToReset,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.resetPasswordRequestForm,
                            "usernameToReset",
                            $$v
                          )
                        },
                        expression: "resetPasswordRequestForm.usernameToReset",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-button",
                  {
                    staticStyle: { width: "100%", "margin-bottom": "30px" },
                    attrs: { loading: _vm.loading, type: "primary" },
                    nativeOn: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.requestCode($event)
                      },
                    },
                  },
                  [_vm._v("Send Code")]
                ),
                _c("div", { staticStyle: { position: "relative" } }, [
                  _c(
                    "div",
                    { staticClass: "tips" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.setLoginState("resetPasswordVerify")
                            },
                          },
                        },
                        [_vm._v("Back to Sign in")]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm.loginState === "resetPasswordVerify"
        ? [
            _c(
              "el-form",
              {
                ref: "resetPasswordVerifyForm",
                staticClass: "login-form",
                attrs: {
                  model: _vm.resetPasswordVerifyForm,
                  rules: _vm.resetPasswordVerifyRules,
                  autocomplete: "on",
                  "label-position": "left",
                },
              },
              [
                _c("div", { staticClass: "title-container" }, [
                  _c("div", [
                    _c("h1", { staticClass: "sidebar-title-legal" }, [
                      _vm._v(_vm._s(_vm.titleLegal) + " "),
                    ]),
                    _c("h1", { staticClass: "sidebar-title-merge" }, [
                      _vm._v(_vm._s(_vm.titleMerge) + " "),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "reset-container" }, [
                  _c("div", [_c("h5", {}, [_vm._v("Reset your password")])]),
                ]),
                _c(
                  "el-form-item",
                  { attrs: { prop: "verificationCode" } },
                  [
                    _c(
                      "span",
                      { staticClass: "svg-container" },
                      [_c("svg-icon", { attrs: { "icon-class": "user" } })],
                      1
                    ),
                    _c("el-input", {
                      ref: "verificationCode",
                      attrs: {
                        placeholder: "Enter Code",
                        name: "verificationCode",
                        type: "text",
                        tabindex: "1",
                      },
                      model: {
                        value: _vm.resetPasswordVerifyForm.verificationCode,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.resetPasswordVerifyForm,
                            "verificationCode",
                            $$v
                          )
                        },
                        expression: "resetPasswordVerifyForm.verificationCode",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "password" } },
                  [
                    _c(
                      "span",
                      { staticClass: "svg-container" },
                      [_c("svg-icon", { attrs: { "icon-class": "password" } })],
                      1
                    ),
                    _c("el-input", {
                      key: _vm.passwordType,
                      ref: "password",
                      attrs: {
                        placeholder: "Password",
                        name: "password",
                        tabindex: "2",
                      },
                      model: {
                        value: _vm.resetPasswordVerifyForm.password,
                        callback: function ($$v) {
                          _vm.$set(_vm.resetPasswordVerifyForm, "password", $$v)
                        },
                        expression: "resetPasswordVerifyForm.password",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-button",
                  {
                    staticStyle: { width: "100%", "margin-bottom": "30px" },
                    attrs: { loading: _vm.loading, type: "primary" },
                    nativeOn: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.submitCode($event)
                      },
                    },
                  },
                  [_vm._v("Submit Code")]
                ),
                _c("div", { staticStyle: { position: "relative" } }, [
                  _c(
                    "div",
                    { staticClass: "tips" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.setLoginState("signIn")
                            },
                          },
                        },
                        [_vm._v("Back to Sign in")]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm.loginState === "newPasswordRequired"
        ? [
            _c(
              "el-form",
              {
                ref: "newPasswordRequiredForm",
                staticClass: "login-form",
                attrs: {
                  model: _vm.newPasswordRequiredForm,
                  rules: _vm.newPasswordRequiredRules,
                  autocomplete: "on",
                  "label-position": "left",
                },
              },
              [
                _c("div", { staticClass: "title-container" }, [
                  _c("div", [
                    _c("h1", { staticClass: "sidebar-title-legal" }, [
                      _vm._v(_vm._s(_vm.titleLegal) + " "),
                    ]),
                    _c("h1", { staticClass: "sidebar-title-merge" }, [
                      _vm._v(_vm._s(_vm.titleMerge) + " "),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "reset-container" }, [
                  _c("div", [_c("h5", {}, [_vm._v("Enter new password")])]),
                ]),
                _c(
                  "el-form-item",
                  { attrs: { prop: "password" } },
                  [
                    _c(
                      "span",
                      { staticClass: "svg-container" },
                      [_c("svg-icon", { attrs: { "icon-class": "password" } })],
                      1
                    ),
                    _c("el-input", {
                      key: _vm.passwordType,
                      ref: "password",
                      attrs: {
                        placeholder: "Enter New Password",
                        name: "password",
                        tabindex: "1",
                      },
                      model: {
                        value: _vm.newPasswordRequiredForm.password,
                        callback: function ($$v) {
                          _vm.$set(_vm.newPasswordRequiredForm, "password", $$v)
                        },
                        expression: "newPasswordRequiredForm.password",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-button",
                  {
                    staticStyle: { width: "100%", "margin-bottom": "30px" },
                    attrs: { loading: _vm.loading, type: "primary" },
                    nativeOn: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.submitNewPasswordRequired($event)
                      },
                    },
                  },
                  [_vm._v("Submit")]
                ),
                _c("div", { staticStyle: { position: "relative" } }, [
                  _c(
                    "div",
                    { staticClass: "tips" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.setLoginState("signIn")
                            },
                          },
                        },
                        [_vm._v("Back to Sign in")]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }