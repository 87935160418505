var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { margin: "10px" } },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c("div", { staticClass: "card-header" }, [
                _vm._v("Location Reference"),
              ]),
              _c(
                "el-scrollbar",
                { attrs: { "wrap-style": "max-height: 100vh;" } },
                [
                  _c(
                    "el-collapse",
                    {
                      attrs: { accordion: "" },
                      model: {
                        value: _vm.activeLocationItem,
                        callback: function ($$v) {
                          _vm.activeLocationItem = $$v
                        },
                        expression: "activeLocationItem",
                      },
                    },
                    _vm._l(_vm.locationReferenceItems, function (item) {
                      return _c(
                        "el-collapse-item",
                        {
                          key: item.label,
                          attrs: { name: item.label, title: item.label },
                        },
                        [
                          _c("ul", [
                            item.addresses
                              ? _c("li", [_vm._v(_vm._s(item.addresses))])
                              : _vm._e(),
                            item.directions
                              ? _c("li", [_vm._v(_vm._s(item.directions))])
                              : _vm._e(),
                            item.noAppointmentsRange
                              ? _c("li", [
                                  _vm._v(_vm._s(item.noAppointmentsRange)),
                                ])
                              : _vm._e(),
                            item.owners
                              ? _c("li", [_vm._v(_vm._s(item.owners))])
                              : _vm._e(),
                            item.attorneys
                              ? _c("li", [
                                  _c("b", [_vm._v("Attorneys:")]),
                                  _vm._v(" " + _vm._s(item.attorneys)),
                                ])
                              : _vm._e(),
                            item.counties.length > 0
                              ? _c("li", [
                                  _c("b", [_vm._v("Counties: ")]),
                                  _c(
                                    "ul",
                                    _vm._l(item.counties, function (county) {
                                      return _c("li", { key: county }, [
                                        _vm._v(" " + _vm._s(county) + " "),
                                      ])
                                    }),
                                    0
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }