import request from '@/utils/request'

/*
*   Calendar ATTRIBUTES HANDLER
*/

/**
 * @description adds a new calendar
 * @param {*} req body = { calendar_name, calendar_street_address, calendar_city, calendar_state, calendar_zip, calendar_directions, calendar_appointment_instructions, calendar_owners, token_id, clio_id_calendar, inserted_by}
 * @param {*} res { success, message }
 */
export function addCalendarAttributesList(data) {
  return request({
    url: '/calendar/attributes',
    method: 'post',
    data
  })
}

/**
 * @description gets calendar attributes
 * @param {*} req body = { id_location_attributes_list, calendar_name, calendar_street_address, calendar_city, calendar_state, calendar_zip, calendar_directions, calendar_appointment_instructions, calendar_owners, token_id, clio_id_calendar, inserted_by}
 * @param {*} res { success, message }
 */
export function getCalendarAttributesLists() {
  return request({
    url: '/calendar/attributes?type=get_all',
    method: 'get'
  })
}

/**
 * @description gets Account Calendars From provided Linked Calendar clio id
 * @param {*} req body = { calendar_id_clio }
 * @param {*} res { success, message }
 */
export function getAccountCalendarsFromLinkedCalendar(calendar_id_clio) {
  return request({
    url: `/calendar/attributes?type=get_acct_from_cal_id${calendar_id_clio}`,
    method: 'get'
  })
}

/**
 * @description gets clio linked calendars
 * @param {*} req body = {token_id}
 * @param {*} res {status, clio_linked_calendars}
 */
export function getClioLinkedCalendars(token_id) {
  return request({
    url: `/calendar/attributes?type=clio_linked&token_id=${token_id}`,
    method: 'get'
  })
}

/**
 * @description edits calendar
 * @param {*} req body = { id_location_attributes_list, calendar_name, calendar_street_address, calendar_city, calendar_state, calendar_zip, calendar_directions, calendar_appointment_instructions, calendar_owners, token_id, clio_id_calendar, inserted_by}
 * @param {*} res { success, message }
 */
export function editCalendarAttributesList(data) {
  return request({
    url: '/calendar/attributes',
    method: 'put',
    data
  })
}

/**
 * @description removes calendar
 * @param {*} req body = { id_location_attributes_list, inserted_by }
 * @param {*} res { success, message }
 */
export function deleteCalendarAttributesList(data) {
  return request({
    url: '/calendar/attributes',
    method: 'delete',
    data
  })
}

/*
*   CONSULTATION TYPES HANDLER
*/

/**
 * @description adds a new consultation type
 * @param {*} req body = { calendar_name, calendar_street_address, calendar_city, calendar_state, calendar_zip, calendar_directions, calendar_appointment_instructions, calendar_owners, token_id, clio_id_calendar, inserted_by}
 * @param {*} res { success, message }
 */
export function addConsultationTypes(data) {
  return request({
    url: '/calendar/consultationTypes',
    method: 'post',
    data
  })
}

/**
 * @description gets all consultation types for associated lm account
 * @param {*} req body = { id_location_attributes_list, calendar_name, calendar_street_address, calendar_city, calendar_state, calendar_zip, calendar_directions, calendar_appointment_instructions, calendar_owners, token_id, clio_id_calendar, inserted_by }
 * @param {*} res { success, consultation_types }
 */
export function getConsultationTypes() {
  return request({
    url: '/calendar/consultationTypes?type=get_all',
    method: 'get'
  })
}

/**
 * @description Edits a consultation type
 * @param {*} req body = { id_location_attributes_list, calendar_name, calendar_street_address, calendar_city, calendar_state, calendar_zip, calendar_directions, calendar_appointment_instructions, calendar_owners, token_id, clio_id_calendar, inserted_by }
 * @param {*} res { success, message }
 */
export function editConsultationType(data) {
  return request({
    url: '/calendar/consultationTypes',
    method: 'put',
    data
  })
}

/**
 * @description removes calendar
 * @param {*} req body = { id_consultation_type, inserted_by }
 * @param {*} res { success, message }
 */
export function deleteConsultationType(data) {
  return request({
    url: '/calendar/consultationTypes',
    method: 'delete',
    data
  })
}

/**
 * @description links existing consultation type(s) to calendar
 * @param {*} req body = { existingConsultationTypes[id_consultation_type], inserted_by }
 * @param {*} res {status, message}
 */
export function linkExistingConsultationTypesToCalendar(data) {
  return request({
    url: '/calendar/consultationTypes',
    method: 'put',
    data
  })
}

/**
 * @description gets all serviced locations for lm account
 * @param {*} req
 * @param {*} res {status, serviced_locations}
 */
export function getAllServicedLocations() {
  return request({
    url: '/calendar/servicedLocations?type=get_all',
    method: 'get'
  })
}

