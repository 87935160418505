<template>
  <div class="login-container">
    <template v-if="loginState === 'signIn'">
      <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" autocomplete="on" label-position="left">

        <div class="title-container">
          <div>
            <h1 class="sidebar-title-legal">{{ titleLegal }} </h1>
            <h1 class="sidebar-title-merge">{{ titleMerge }} </h1>
          </div>
        </div>

        <el-form-item prop="username">
          <span class="svg-container">
            <svg-icon icon-class="user" />
          </span>
          <el-input
            ref="username"
            v-model="loginForm.username"
            placeholder="Email"
            name="username"
            type="text"
            tabindex="1"
            autocomplete="on"
          />
        </el-form-item>

        <el-tooltip v-model="capsTooltip" content="Caps lock is On" placement="right" manual>
          <el-form-item prop="password">
            <span class="svg-container">
              <svg-icon icon-class="password" />
            </span>
            <el-input
              :key="passwordType"
              ref="password"
              v-model="loginForm.password"
              :type="passwordType"
              placeholder="Password"
              name="password"
              tabindex="2"
              autocomplete="on"
              @keyup.native="checkCapslock"
              @blur="capsTooltip = false"
              @keyup.enter.native="handleLogin"
            />
            <span class="show-pwd" @click="showPwd">
              <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" />
            </span>
          </el-form-item>
        </el-tooltip>

        <el-button :loading="loading" type="primary" style="width:100%;margin-bottom:30px;" @click.native.prevent="handleLogin">Login</el-button>

        <div style="position:relative">
          <div class="tips">
            <span>Forgot your password? </span>
            <el-button type="text" @click="setLoginState('resetPasswordRequest')">Reset password</el-button>
          </div>
        </div>

      </el-form>
    </template>

    <template v-if="loginState === 'resetPasswordRequest'">
      <el-form ref="resetPasswordRequestForm" :model="resetPasswordRequestForm" :rules="resetPasswordRequestRules" class="login-form" autocomplete="on" label-position="left">

        <div class="title-container">
          <div>
            <h1 class="sidebar-title-legal">{{ titleLegal }} </h1>
            <h1 class="sidebar-title-merge">{{ titleMerge }} </h1>
          </div>
        </div>

        <div class="reset-container">
          <div>
            <h5 class="">Reset your password</h5>
          </div>
        </div>

        <el-form-item prop="usernameToReset">
          <span class="svg-container">
            <svg-icon icon-class="user" />
          </span>
          <el-input
            ref="usernameToReset"
            v-model="resetPasswordRequestForm.usernameToReset"
            placeholder="Email"
            name="username"
            type="text"
            tabindex="1"
            autocomplete="on"
          />
        </el-form-item>

        <el-button :loading="loading" type="primary" style="width:100%;margin-bottom:30px;" @click.native.prevent="requestCode">Send Code</el-button>

        <div style="position:relative">
          <div class="tips">
            <el-button type="text" @click="setLoginState('resetPasswordVerify')">Back to Sign in</el-button>
          </div>
        </div>

      </el-form>
    </template>

    <template v-if="loginState === 'resetPasswordVerify'">
      <el-form ref="resetPasswordVerifyForm" :model="resetPasswordVerifyForm" :rules="resetPasswordVerifyRules" class="login-form" autocomplete="on" label-position="left">

        <div class="title-container">
          <div>
            <h1 class="sidebar-title-legal">{{ titleLegal }} </h1>
            <h1 class="sidebar-title-merge">{{ titleMerge }} </h1>
          </div>
        </div>

        <div class="reset-container">
          <div>
            <h5 class="">Reset your password</h5>
          </div>
        </div>

        <el-form-item prop="verificationCode">
          <span class="svg-container">
            <svg-icon icon-class="user" />
          </span>
          <el-input
            ref="verificationCode"
            v-model="resetPasswordVerifyForm.verificationCode"
            placeholder="Enter Code"
            name="verificationCode"
            type="text"
            tabindex="1"
          />
        </el-form-item>

        <el-form-item prop="password">
          <span class="svg-container">
            <svg-icon icon-class="password" />
          </span>
          <el-input
            :key="passwordType"
            ref="password"
            v-model="resetPasswordVerifyForm.password"
            placeholder="Password"
            name="password"
            tabindex="2"
          />
        </el-form-item>

        <el-button :loading="loading" type="primary" style="width:100%;margin-bottom:30px;" @click.native.prevent="submitCode">Submit Code</el-button>

        <div style="position:relative">
          <div class="tips">
            <el-button type="text" @click="setLoginState('signIn')">Back to Sign in</el-button>
          </div>
        </div>

      </el-form>
    </template>

    <template v-if="loginState === 'newPasswordRequired'">
      <el-form ref="newPasswordRequiredForm" :model="newPasswordRequiredForm" :rules="newPasswordRequiredRules" class="login-form" autocomplete="on" label-position="left">

        <div class="title-container">
          <div>
            <h1 class="sidebar-title-legal">{{ titleLegal }} </h1>
            <h1 class="sidebar-title-merge">{{ titleMerge }} </h1>
          </div>
        </div>

        <div class="reset-container">
          <div>
            <h5 class="">Enter new password</h5>
          </div>
        </div>

        <el-form-item prop="password">
          <span class="svg-container">
            <svg-icon icon-class="password" />
          </span>
          <el-input
            :key="passwordType"
            ref="password"
            v-model="newPasswordRequiredForm.password"
            placeholder="Enter New Password"
            name="password"
            tabindex="1"
          />
        </el-form-item>

        <el-button :loading="loading" type="primary" style="width:100%;margin-bottom:30px;" @click.native.prevent="submitNewPasswordRequired">Submit</el-button>

        <div style="position:relative">
          <div class="tips">
            <el-button type="text" @click="setLoginState('signIn')">Back to Sign in</el-button>
          </div>
        </div>

      </el-form>
    </template>

  </div>
</template>

<script>

export default {
  name: 'Login',
  data() {
    const validateUsername = (rule, value, callback) => {
      if (value.length < 1) {
        callback(new Error('Please enter email'))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (value.length < 8) {
        callback(new Error('The password can not be less than 8 digits'))
      } else {
        callback()
      }
    }
    const validateVerificationCode = (rule, value, callback) => {
      if (value.length < 1) {
        callback(new Error('Please enter code'))
      } else {
        callback()
      }
    }
    return {
      loginState: 'signIn',
      titleLegal: 'Legal',
      titleMerge: 'Merge',
      loginForm: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [{ required: true, trigger: 'blur', validator: validateUsername }],
        password: [{ required: true, trigger: 'blur', validator: validatePassword }]
      },
      resetPasswordRequestForm: {
        usernameToReset: ''
      },
      resetPasswordRequestRules: {
        usernameToReset: [{ required: true, trigger: 'blur', validator: validateUsername }]
      },
      resetPasswordVerifyForm: {
        verificationCode: '',
        password: ''
      },
      newPasswordRequiredForm: {
        password: ''
      },
      newPasswordRequiredRules: {
        password: [{ required: true, trigger: 'blur', validator: validatePassword }]
      },
      resetPasswordVerifyRules: {
        verificationCode: [{ required: true, trigger: 'blur', validator: validateVerificationCode }],
        password: [{ required: true, trigger: 'blur', validator: validatePassword }]
      },
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
      tempUser: undefined
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        const query = route.query
        if (query) {
          this.redirect = query.redirect
          this.otherQuery = this.getOtherQuery(query)
        }
      },
      immediate: true
    }
  },
  created() {
    // window.addEventListener('storage', this.afterQRScan)
  },
  mounted() {
    if (this.loginForm.username === '') {
      this.$refs.username.focus()
    } else if (this.loginForm.password === '') {
      this.$refs.password.focus()
    }
  },
  destroyed() {
    // window.removeEventListener('storage', this.afterQRScan)
  },
  methods: {
    setLoginState(state) {
      this.loginState = state
    },
    checkCapslock(e) {
      const { key } = e
      if (key) {
        this.capsTooltip = key && key.length === 1 && (key >= 'A' && key <= 'Z')
      }
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    requestCode() {
      this.$refs.resetPasswordRequestForm.validate(valid => {
        if (valid) {
          this.loading = true
          var body = {
            usernameToReset: this.resetPasswordRequestForm.usernameToReset
          }
          this.$store.dispatch('user/forgotPassword', body)
            .then(() => {
              this.setLoginState('resetPasswordVerify')
              this.loading = false
            })
            .catch((e) => {
              this.setLoginState('resetPasswordVerify')
              this.loading = false
            })
        } else {
          return false
        }
      })
    },
    showNewPasswordRequired() {
      this.setLoginState('newPasswordRequired')
    },
    submitCode() {
      this.$refs.resetPasswordVerifyForm.validate(valid => {
        if (valid) {
          this.loading = true
          var body = {
            verificationCode: this.resetPasswordVerifyForm.verificationCode,
            password: this.resetPasswordVerifyForm.password,
            usernameToReset: this.resetPasswordRequestForm.usernameToReset
          }
          this.$store.dispatch('user/forgotPasswordSubmit', body)
            .then(() => {
              this.setLoginState('signIn')
              this.$message.success('Password successfully reset.')
              this.loading = false
            })
            .catch((e) => {
              this.$message.error('Could not reset password please try again.')
              this.loading = false
            })
        } else {
          return false
        }
      })
    },
    submitNewPasswordRequired() {
      this.$refs.newPasswordRequiredForm.validate(valid => {
        if (valid) {
          this.loading = true
          var body = {
            newPassword: this.newPasswordRequiredForm.password,
            user: this.tempUser
          }
          this.$store.dispatch('user/completeNewPassword', body)
            .then(() => {
              this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
              this.loading = false
            })
            .catch((e) => {
              this.loading = false
            })
        } else {
          return false
        }
      })
    },
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true
          this.$store.dispatch('user/login', this.loginForm)
            .then((user) => {
              if (user && user.challengeName && user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                this.tempUser = user
                this.showNewPasswordRequired()
                this.loading = false
              } else {
                this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
                this.loading = false
              }
            })
            .catch((e) => {
              if (e && e.message) {
                this.$message.error(e.message)
              }
              this.loading = false
            })
        } else {
          return false
        }
      })
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    }
  }
}
</script>

<style lang="scss">
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

@import url('https://fonts.googleapis.com/css2?family=Cormorant+SC:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Yellowtail&display=swap');

$bg:#283443;
$light_gray:#fff;
$cursor: #fff;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}

/* reset element-ui css */
.login-container {
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: $light_gray;
      height: 47px;
      caret-color: $cursor;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px $bg inset !important;
        -webkit-text-fill-color: $cursor !important;
      }
    }
  }

  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
  }
}
</style>

<style lang="scss" scoped>
$bg:#2d3a4b;
$dark_gray:#889aa4;
$light_gray:#eee;

.login-container {
  min-height: 100%;
  width: 100%;
  background-color: $bg;
  overflow: hidden;

  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .reset-container {
    position: relative;
    font-size: 22px;
    color: $light_gray;
    margin: 0px auto 40px auto;
    text-align: center;
  }

  .title-container {
    position: relative;
    font-size: 26px;
    color: $light_gray;
    margin: 0px auto 40px auto;
    text-align: center;
    font-weight: bold;

    .sidebar-title-legal {
      display: inline-block;
      margin: 0;
      color: #fff;
      font-weight: 600;
      line-height: 50px;
      font-family: 'Cormorant SC', serif;
      vertical-align: middle;
      letter-spacing: 2px;
      padding-right: 2px;
    }

    .sidebar-title-merge {
        display: inline-block;
        margin: 0;
        color: #fff;
        font-weight: 1;
        line-height: 50px;
        font-family: 'Yellowtail', cursive;
        vertical-align: middle;
        letter-spacing: 2px;
      }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }

  .thirdparty-button {
    position: absolute;
    right: 0;
    bottom: 6px;
  }

  @media only screen and (max-width: 470px) {
    .thirdparty-button {
      display: none;
    }
  }
}
</style>
