import request from '@/utils/request'

export function addmatter(data) {
  return request({
    url: '/lmdbManagement/addmatter',
    method: 'post',
    data
  })
}

export function addcontact(data) {
  return request({
    url: '/lmdbManagement/addcontact',
    method: 'post',
    data
  })
}

export function addMatterAndContact(data) {
  return request({
    url: '/lmdbManagement/addMatterAndContact',
    method: 'post',
    data
  })
}

export function addContactedLead(data) {
  return request({
    url: '/lmdbManagement/addContactedLead',
    method: 'post',
    data
  })
}

export function addappointment(data) {
  return request({
    url: '/lmdbManagement/addappointment',
    method: 'post',
    data
  })
}

export function getMatterClientInfo(data) {
  return request({
    url: '/lmdbManagement/getContactIdFromMatterId',
    method: 'post',
    data
  })
}

export function getCanClientReceiveText(data) {
  return request({
    url: '/lmdbManagement/getCanClientReceiveText',
    method: 'post',
    data
  })
}

export function updateAppointmentResult(data) {
  return request({
    url: '/lmdbManagement/updateAppointmentResult',
    method: 'post',
    data
  })
}

export function getClientTextingInfo(data) {
  return request({
    url: '/lmdbManagement/getClientTextingInfo',
    method: 'post',
    data
  })
}

