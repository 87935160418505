var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "rightPanel",
      staticClass: "rightPanel-container",
      class: { show: _vm.show },
    },
    [
      _c("div", { staticClass: "rightPanel-background" }),
      _c("div", { staticClass: "rightPanel" }, [
        _c(
          "div",
          {
            staticClass: "handle-button",
            style: { "background-color": _vm.theme },
            on: {
              click: function ($event) {
                _vm.show = !_vm.show
              },
            },
          },
          [_c("i", { class: _vm.show ? "el-icon-close" : "el-icon-tickets" })]
        ),
        _c("div", { staticClass: "rightPanel-items" }, [_vm._t("default")], 2),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }