<template>
  <div :class="{'has-logo':showLogo}">
    <logo v-if="showLogo" :collapse="isCollapse" />
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :background-color="variables.menuBg"
        :text-color="variables.menuText"
        :unique-opened="false"
        :active-text-color="variables.menuActiveText"
        :collapse-transition="false"
        mode="vertical"
      >
        <sidebar-item v-for="route in permission_routes" :key="route.path" :item="route" :base-path="route.path" />
      </el-menu>
    </el-scrollbar>
    <transition name="fade">
      <!-- <div v-if="!isCollapse" class="as-of-date">
        <el-row class="content">
          <el-row type="flex">
            <el-col :span="24">Data as of:</el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="24">{{ asOfDate }}</el-col>
          </el-row>
        </el-row>
      </div> -->
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from './Logo'
import SidebarItem from './SidebarItem'
import variables from '@/styles/variables.scss'

export default {
  components: { SidebarItem, Logo },
  data() {
    return {
      asOfDate: 'Friday, January 31, 2021'
    }
  },
  computed: {
    ...mapGetters([
      'permission_routes',
      'sidebar'
    ]),
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo
    },
    variables() {
      return variables
    },
    isCollapse() {
      return !this.sidebar.opened
    }
  }
}
</script>
<style lang="scss" scoped>
.as-of-date {
  padding-left: 20px;
  font-size: 12px;
  height: 100px;
  color: white;
  .content {
    position: absolute;
    bottom: 0;
    padding-bottom: 5px;
  }
}
.fade-enter-active {
  transition: opacity 1.5s;
}

.fade-leave-active {
  transition: opacity .0s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
