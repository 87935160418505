const state = {
  selectedOffice: undefined
}

const mutations = {
  CHANGE_SELECTED_OFFICE: (state, value) => {
    state.selectedOffice = value
  }
}

const actions = {
  changeSelectedOffice({ commit }, office) {
    commit('CHANGE_SELECTED_OFFICE', office)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
