<template>
  <el-dialog
    element-loading-text="Adding Questionnaire URL..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    title="Add Questionnaire URL"
    :visible.sync="modalVisible"
    width="575px"
    :close-on-click-modal="false"
    @close="closeModal"
  >

    <el-input v-model="questionnaire_url" label="Questionnaire URL" type="text" />

    <span slot="footer" class="dialog-footer">
      <el-button @click="closeModal">Cancel</el-button>
      <el-button type="primary" @click="submit()">Submit</el-button>
    </span>
  </el-dialog>
</template>

<script>
import * as apiLocations from '@/api/locations'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'AddQuestionnaireUrlForm',
  props: {
    modalVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      questionnaire_url: ''
    }
  },
  computed: {
    ...mapGetters([
      'name',
      'avatar',
      'roles'
    ])
  },
  created: async function() {},
  mounted: async function() {
  },
  methods: {
    // handling the mechanics of the modal
    async submit() {
      await apiLocations.addQuestionnaireUrl({ questionnaire_url: this.questionnaire_url })
      this.$emit('update-urls')
      this.closeModal()
    },
    async closeModal() {
      this.questionnaire_url = ''
      this.$emit('close')
    }
  }
}
</script>
<style lang="scss" scoped>
  .el-col {
    border-radius: 4px;
    margin-left: 20px;
  }
  .el-row {
    margin-bottom: 20px;
  }
  .text-input-label {
    display: inline-block;
    width: 200px;
    height: 36px;
    text-align: right;
    padding-top: 10px;
    padding-right: 20px;
  }

</style>

<style lang="scss">
  .el-tabs--border-card > .el-tabs__content {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    overflow: auto !important;
  }
  .el-tab-pane {
    height: 100vh;
    overflow: auto !important;
    overflow-y: auto !important;
  }
</style>
