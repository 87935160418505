<template>
  <div style="margin: 10px;">
    <el-row>
      <el-col :span="24">
        <div class="card-header">Intake Script</div>
        <el-collapse v-model="activeItem" accordion>
          <el-collapse-item v-for="item in scriptItems" :key="item.label" :name="item.label" :title="item.label">
            <div v-for="(statement, index) in item.text" :key="statement" style="padding-left: 10px; padding-right: 10px">
              <div style="font-weight: bold">Statement {{ index + 1 }}:</div>{{ statement }}
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-col>
      <!-- <el-col :span="12">
        <div class="card-header">Location Reference</div>
        <el-collapse v-model="activeLocationItem" accordion>
          <el-collapse-item v-for="item in locationReferenceItems" :key="item.label" :name="item.label" :title="item.label">
            <ul>
              <li>{{ item.addresses }}</li>
              <li>{{ item.directions }}</li>
              <li>{{ item.noAppointmentsRange }}</li>
              <li>{{ item.owners }}</li>
              <li>Attorneys: {{ item.attorneys }}</li>
              <li>
                <ul>
                  <li v-for="county in item.counties" :key="county">
                    {{ county }}
                  </li>
                </ul>
              </li>
            </ul>
          </el-collapse-item>
        </el-collapse>
      </el-col> -->
    </el-row>
  </div>
</template>

<script>

export default {
  name: 'MotivatorScript',
  props: {},
  data() {
    return {
      activeItem: 'Motivator',
      callerNumber: '',
      callerName: '',
      scriptItems: [
        {
          label: 'Motivator',
          text: [
            'Hello (introduce self and get name of person speaking with), I understand you are having some financial struggles?',
            'What prompted you to call us today, did something happen in particular that finally made you think about bankruptcy?',
            'What is going on in your life that has made you reach out to us right now?'
          ]
        },
        {
          label: 'Pain Points',
          text: [
            'How is all this affecting you and your life?',
            'How are you feeling about all of this?',
            'Is all of this causing you other problems?'
          ]
        },
        {
          label: 'The Hope',
          text: [
            'What needs to change for you?',
            'What would you like to change in your life?',
            'What would be the best case scenario for you in all this?'
          ]
        },
        {
          label: 'Appointment',
          text: [
            'The first step in making that happen is to sit down with us. I have (day) & (day) available.',
            'What we would like to do is set up a free financial consultation so we can get a better, more detailed look at your situation so we can make sure every ones on the same page FIRST so we can come up with a financial game plan and go from there.'
          ]
        },
        {
          label: 'Responsibility',
          text: [
            'You told me you wanted xyz to be different. That cannot happen until you come and talk with us.',
            'When you spoke about xyz earlier, and how that has affected you, wouldnt you want to be free from all of that and get a fresh start on your life? Then you will need to take that first step and come in and meet with us.'
          ]
        }
      ],
      locationReferenceItems: [
        {
          label: 'Annistion',
          addresses: '1302 Noble Street, Suite 2C, Anniston, AL 36201',
          directions: 'Corner of 12th and Noble Street inside the Lyric Square Building 1 block from Federal building 2nd floor',
          noAppointmentsRange: 'NO APPOINTMENTS BETWEEN 12 NOON - 1:30',
          owners: 'BOND, BOTES, & HANDY P.C.',
          attorneys: 'Carla Handy',
          counties: ['St. Clair',
            'Cherokee',
            'Dekalb',
            'Etowah',
            'Marshall',
            'Calhoun',
            'Clay',
            'Cleburne',
            'Talladega']
        },
        {
          label: 'Gadsden',
          addresses: '430B Chestnut Street, Gadsden, AL 35901',
          directions: '',
          noAppointmentsRange: 'NO APPOINTMENTS BETWEEN 12 NOON - 1:00',
          owners: 'BOND, BOTES, & HANDY P.C.',
          attorneys: 'Carla Handy',
          counties: ['St. Clair',
            'Cherokee',
            'Dekalb',
            'Etowah',
            'Marshall',
            'Calhoun',
            'Clay',
            'Cleburne',
            'Talladega']
        },
        {
          label: 'Birmingham Hoover',
          addresses: '15 Southlake Lane, Suite 140, Birmingham, AL 35244',
          directions: 'Off Valleydale Road (I-65 exit 247, turn left) near Lowes Home improvement, Hardees, and Faulkner University. 3 story building',
          noAppointmentsRange: '',
          owners: 'BOND, BOTES, & HANDY P.C.',
          attorneys: 'Brad Botes, Robert Reese, Suzanne Shinn, Grafton Weinaker',
          counties: ['Bibb',
            'Blount',
            'Fayette',
            'Greene',
            'Jefferson',
            'Pickens',
            'Shelby',
            'Sumter',
            'Tuscaloosa',
            'Walker']
        },
        {
          label: 'Huntsville',
          addresses: '225 Pratt Avenue, Huntsville, AL 35801',
          directions: 'Next to Big Eds Pizza in the spaceship building',
          noAppointmentsRange: '',
          owners: 'BOND, BOTES, SYKSTUS, TANNER, & EZELL, P.C.',
          attorneys: 'Ron Sykstus, Amy Tanner, Kathryn Davis, James Ezell',
          counties: ['Madison', 'Jackson', 'Morgan', 'Limestone', 'Lawrence', 'Cullman']
        },
        {
          label: 'Decatur',
          addresses: '',
          directions: '',
          noAppointmentsRange: '',
          owners: 'BOND, BOTES, SYKSTUS, TANNER, & EZELL, P.C.',
          attorneys: '',
          counties: []
        },
        {
          label: 'Cullman',
          addresses: '',
          directions: '',
          noAppointmentsRange: '',
          owners: 'BOND, BOTES, SYKSTUS, TANNER, & EZELL, P.C.',
          attorneys: '',
          counties: []
        },
        {
          label: 'Florence',
          addresses: '',
          directions: '',
          noAppointmentsRange: '',
          owners: 'BOND, BOTES, SYKSTUS, TANNER, & MCNUTT',
          attorneys: '',
          counties: []
        },
        {
          label: 'Haleyville',
          addresses: '',
          directions: '',
          noAppointmentsRange: '',
          owners: 'BOND, BOTES, SYKSTUS, TANNER, & MCNUTT',
          attorneys: '',
          counties: []
        },
        {
          label: 'Mobile',
          addresses: '1 St Louis St #1002, Mobile, AL 36602',
          directions: 'PLEASE ADVISE THE CLIENTS THAT THEY SHOULD NOT PARK IN THE PARKING LOTS AROUND THE BUILDING AS THEY ARE ALL RESERVED & RENTED OUT. The public entrance to the building is on the North side (the one the overhead picture is facing, with a handicap ramp on the NW corner of the building). There are approx. (6) 2-hr parking spaces (parallel parking) on the North side of St. Louis St. that run parallel with the street. These spots are across the street from the handicap ramp area. There is also metered parking along St. Louis St. up near the Candlewood Suites & Alabama Power Buildings to the West of our office. As well as metered parking along St. Anthony St.',
          noAppointmentsRange: '',
          owners: '',
          attorneys: '',
          counties: ['Baldwin', 'Conecuh', 'Escambia', 'Mobile', 'Monroe', 'Washington']
        },
        {
          label: 'Montgomery',
          addresses: '311 Catoma Street Montgomery AL 36104',
          directions: 'Between AmerBank and River Bank and Trust. Few Blocks up from Renaissance Hotel.',
          noAppointmentsRange: '',
          owners: '',
          attorneys: '',
          counties: []
        },
        {
          label: 'Opelilka',
          addresses: '713 Ave A Opelika Al 36801',
          directions: 'Behind edwards jones and 2 blocks up from courthouse',
          noAppointmentsRange: '',
          owners: '',
          attorneys: '',
          counties: []
        },
        {
          label: 'Hattiesburg',
          addresses: '607 Corrine Street, Suite B8, Hattiesburg, AL 39401',
          directions: 'Look for Edwards Jones sign, house looking, breezeway on left, last door on the left',
          noAppointmentsRange: '',
          owners: 'BOND, BOTES, & WOODS P.C',
          attorneys: 'Edwin “ED” Woods, Merideth Drummond',
          counties: ['Hinds',
            'Holmes',
            'Rankin',
            'Madison',
            'Copiah',
            'Simpson',
            'Smith',
            'Scott',
            'Leake',
            'Forrest',
            'Perry',
            'Lamar',
            'Greene',
            'Jones',
            'Wayne',
            'Covington',
            'Jefferson',
            'Davis',
            'Marion',
            'Warren',
            'Yazoo',
            'Sharkey',
            'Claiborne',
            'Issaquena',
            'Hancok',
            'Harrison',
            'Jackson',
            'Pearl River']
        },
        {
          label: 'Jackson',
          addresses: '5760 Highway I55 N Frontage Road, Suite 100, Jackson, MS 39201',
          directions: 'Across from Twin Peaks, next to dental office, 3 story white building',
          noAppointmentsRange: '',
          owners: 'BOND, BOTES, & WOODS P.C ',
          attorneys: 'Edwin “ED” Woods, Merideth Drummond',
          counties: ['Hinds',
            'Holmes',
            'Rankin',
            'Madison',
            'Copiah',
            'Simpson',
            'Smith',
            'Scott',
            'Leake',
            'Forrest',
            'Perry',
            'Lamar',
            'Greene',
            'Jones',
            'Wayne',
            'Covington',
            'Jefferson',
            'Davis',
            'Marion',
            'Warren',
            'Yazoo',
            'Sharkey',
            'Claiborne',
            'Issaquena',
            'Hancok',
            'Harrison',
            'Jackson',
            'Pearl River']
        },
        {
          label: 'Vicksburg',
          addresses: '1212 Farmer Street Vicksburg, Mississippi 39183',
          directions: 'Clay and Farmer Street Inside Ellis & Ellis building next door to State farm',
          noAppointmentsRange: '',
          owners: 'BOND, BOTES, & WOODS P.C',
          attorneys: 'Edwin “ED” Woods, Merideth Drummond',
          counties: ['Hinds',
            'Holmes',
            'Rankin',
            'Madison',
            'Copiah',
            'Simpson',
            'Smith',
            'Scott',
            'Leake',
            'Forrest',
            'Perry',
            'Lamar',
            'Greene',
            'Jones',
            'Wayne',
            'Covington',
            'Jefferson',
            'Davis',
            'Marion',
            'Warren',
            'Yazoo',
            'Sharkey',
            'Claiborne',
            'Issaquena',
            'Hancok',
            'Harrison',
            'Jackson',
            'Pearl River']
        },
        {
          label: 'Knoxville',
          addresses: '',
          directions: 'NOT SUPPORTED',
          noAppointmentsRange: '',
          owners: '',
          attorneys: '',
          counties: []
        }
      ]
    }
  },

  computed: {
    callerExists: function() {
      if (this.callerNumber) {
        return 'yes'
      } else {
        return 'no'
      }
    }
  },
  created() {
  },
  mounted() {
    this.callerNumber = this.$store.getters.currentCallerNumber
    this.callerName = this.$store.getters.currentCallerName
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.card-header {
  text-align: center;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
