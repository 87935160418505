const getters = {
  sidebar: state => state.app.sidebar,
  size: state => state.app.size,
  device: state => state.app.device,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  introduction: state => state.user.introduction,
  roles: state => state.user.roles,
  permission_routes: state => state.permission.routes,
  errorLogs: state => state.errorLog.logs,
  selectedOffice: state => state.offices.selectedOffice,

  // client information
  clientIntakeInformation: state => state.intake.clientIntakeInformation,

  // scheduling information
  newAppointmentInformation: state => state.scheduling.newAppointmentInformation,
  calendarScheduleState: state => state.scheduling.calendarScheduleState
}
export default getters
