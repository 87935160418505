var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "account-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { staticClass: "label", attrs: { span: 2 } }, [
            _c("span", [_vm._v("Email:")]),
          ]),
          _c("el-col", { staticClass: "label", attrs: { span: 6 } }, [
            _c("span", [_vm._v(_vm._s(_vm.user.email))]),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { staticClass: "label", attrs: { span: 2 } }, [
            _c("span", [_vm._v("Roles:")]),
          ]),
          _c("el-col", { attrs: { span: 22 } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }