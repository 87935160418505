<template>
  <div>
    <el-row style="margin-top: 15px">
      <el-col
        :span="24"
      ><div>
        <el-table
          id="analyticsTable"
          v-loading="listLoading"
          :data="rows"
          border
          :fit="false"
          stripe
          :max-height="800"
          :show-summary="false"
          highlight-current-row
          style="width: 100%"
        >
          <el-table-column
            label="Attorney"
            min-width="180"
            column-key="Originating Attorney"
            prop="Originating Attorney"
            header-align="center"
          />
          <el-table-column
            label="Total Leads"
            min-width="180"
            column-key="Total Leads"
            prop="Total Leads"
            header-align="center"
          />
          <el-table-column
            label="SBB"
            min-width="180"
            column-key="SBB"
            prop="SBB"
            header-align="center"
          />
          <el-table-column
            label="SBB Rate"
            min-width="180"
            column-key="SBB Rate"
            prop="SBB Rate"
            header-align="center"
          />
          <el-table-column
            label="No Show"
            min-width="180"
            column-key="NS"
            prop="NS"
            header-align="center"
          />
          <el-table-column
            label="No Show Rate"
            min-width="180"
            column-key="NS Rate"
            prop="NS Rate"
            header-align="center"
          />
          <el-table-column
            label="Retainer Sent"
            min-width="180"
            column-key="Retainer Sent"
            prop="Retainer Sent"
            header-align="center"
          />
          <el-table-column
            label="Retainer Sent Rate"
            min-width="180"
            column-key="Retainer Sent Rate"
            prop="Retainer Sent Rate"
            header-align="center"
          />
          <el-table-column
            label="Not Retained"
            min-width="180"
            column-key="Not Retained"
            prop="Not Retained"
            header-align="center"
          />
          <el-table-column
            label="Not Retained Rate"
            min-width="180"
            column-key="Not Retained Rate"
            prop="Not Retained Rate"
            header-align="center"
          />
          <el-table-column
            label="DNQ"
            min-width="180"
            column-key="DNQ"
            prop="DNQ"
            header-align="center"
          />
          <el-table-column
            label="DNQ Rate"
            min-width="180"
            column-key="DNQ Rate"
            prop="DNQ Rate"
            header-align="center"
          />

          <el-table-column
            label="Filed"
            min-width="180"
            column-key="Filed"
            prop="Filed"
            header-align="center"
          />
          <el-table-column
            label="Filed Rate"
            min-width="180"
            column-key="Filed Rate"
            prop="Filed Rate"
            header-align="center"
          />
          <el-table-column
            label="Closed"
            min-width="180"
            column-key="Closed"
            prop="Closed"
            header-align="center"
          />
          <el-table-column
            label="Closed Rate"
            min-width="180"
            column-key="Closed Rate"
            prop="Closed Rate"
            header-align="center"
          />
          <el-table-column
            label="UBB"
            min-width="180"
            column-key="UBB"
            prop="UBB"
            header-align="center"
          />
          <el-table-column
            label="UBB Rate"
            min-width="180"
            column-key="UBB Rate"
            prop="UBB Rate"
            header-align="center"
          />
          <el-table-column
            label="Retained"
            min-width="180"
            column-key="Retained"
            prop="Retained"
            header-align="center"
          />
          <el-table-column
            label="Retain Rate"
            min-width="180"
            column-key="Retain Rate"
            prop="Retain Rate"
            header-align="center"
          />

        </el-table></div></el-col></el-row>
  </div>
</template>

<script>
import {
  getListData
} from '@/api/appointmentresult'
import XLSX from 'xlsx'
// import alasql from 'alasql'
// import _ from 'lodash'

const today = new Date()

export default {
  clientId: undefined,
  name: 'AttorneyData',
  caller: '',
  components: {},
  props: {
    dateFilter: {
      type: Array,
      default: null
    },
    selectedLocation: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      appointmentList: [],
      displayDate: today,
      filterTypes: {
        locationFilter: [],
        resultFilter: []
      },
      list: null,
      listLoading: false,
      reportSelected: 'originating_attorney',
      rows: [],
      selectListLoading: false
    }
  },
  computed: {
    columns: function columns() {
      if (this.rows.length === 0) {
        return []
      }
      return Object.keys(this.rows[0])
    }
  },
  watch: {
    dateFilter: function(newVal, oldVal) { // watch it
      console.log('dateFilter changed: ', newVal, ' | was: ', oldVal)
      this.fetchRecordsRange()
    },
    selectedLocation: function(newVal, oldVal) { // watch it
      console.log('location location location')
      console.log('selectedLocation changed: ', newVal, ' | was: ', oldVal)
      this.fetchRecordsRange()
      // this.filterByLocation()
    }
  },
  mounted: async function() {
    await this.fetchRecordsRange()
    console.log('attorney-data mounted')
  },
  methods: {
    createColumnFilterList() {
      var columnArray = this.columns

      var arrayObject = []
      for (var i = 0; i < columnArray.length; i++) {
        arrayObject.push({ id: columnArray[i], hidden: false })
      }
      this.columnFilters = arrayObject
    },
    async fetchRecordsRange() {
      //   console.log('fetchRecordsRange ')
      //  console.log('this.selectedLocation ', this.selectedLocation)
      this.listLoading = true
      //  console.log('this.reportSelected ', this.reportSelected)
      var tableData = await getListData([this.dateFilter, this.reportSelected, this.selectedLocation, today])
      console.log('tableData is ', tableData)
      console.log('tableData data is ', tableData.data)
      // eslint-disable-next-line
      var tableFormatted = tableData.data.map(function(x) {
        x['SBB Rate'] = x['SBB Rate'] + '%'
        x['NS Rate'] = x['NS Rate'] + '%'
        x['Retainer Sent Rate'] = x['Retainer Sent Rate'] + '%'
        x['Not Retained Rate'] = x['Not Retained Rate'] + '%'
        x['DNQ Rate'] = x['DNQ Rate'] + '%'
        x['Retain Rate'] = x['Retain Rate'] + '%'
        x['Filed Rate'] = x['Filed Rate'] + '%'
        x['Closed Rate'] = x['Closed Rate'] + '%'
        x['UBB Rate'] = x['UBB Rate'] + '%'

        return x
      })

      this.listLoading = false
      if (tableData.data !== 'none') {
        // sort excel

        const sortOrder = { 'Originating Attorney': 1, 'Total Leads': 2, 'SBB': 3, 'SBB Rate': 4, 'NS': 5, 'NS Rate': 6, 'Retainer Sent': 7, 'Retainer Sent Rate': 8, 'Not Retained': 9, 'Not Retained Rate': 10, 'DNQ': 11, 'DNQ Rate': 12, 'Filed': 13, 'Filed Rate': 14, 'Closed': 15, 'Closed Rate': 16, 'UBB': 17, 'UBB Rate': 18, 'Retained': 19, 'Retain Rate': 20 }
        const sortedData = tableData.data.map(o => Object.assign({}, ...Object.keys(o).sort((a, b) => sortOrder[a] - sortOrder[b]).map(x => { return { [x]: o[x] } })))

        this.rows = sortedData
        // this.rows = tableData.data
      } else {
        this.rows = []
      }
    },
    handleExport() {
      var excelExportData = XLSX.utils.json_to_sheet(this.rows)
      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new() // make Workbook of Excel
      XLSX.utils.book_append_sheet(wb, excelExportData, 'Lead Data') // sheetName is name of Worksheet
      XLSX.writeFile(wb, `${this.reportSelected}.xlsx`) // name of the file is 'Export.xlsx'
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination-container {
  margin-top: 0px;
  padding: 15px 16px;
}

.analytics-filter-container {
  .filter-item {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }
  .export-button {
    vertical-align: middle;
    position: absolute;
    right: 0;
  }
}

.date-control-container {
  padding-top: 10px;
  padding-bottom: 10px;
}

.filter-item {
  width: 100%;
}

.chart-container {
  position: relative;
  width: 100%;
  height: calc(100vh - 84px);
}
</style>
