<template>
  <div>
    <AddQuestionnaireUrlForm
      :modal-visible="isShowAddUrlDialog"
      :lazy="true"
      @close="isShowAddUrlDialog = false"
      @update-urls="getQuestionnaireUrls"
    />
    <el-dialog
      v-loading="isLoading"
      element-loading-text="Submitting Calendar..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      title="Add Calendar"
      :visible.sync="modalVisible"
      width="575px"
      :close-on-click-modal="false"
      @close="closeModal"
    >
      <el-form ref="calendarAttributesForm" :model="calendarAttributesForm" :rules="rules" label-width="auto">
        <el-form-item label="Clio Account" label-width="auto" prop="token_id">
          <el-select
            v-model="calendarAttributesForm.token_id"

            placeholder="Select Linked Account"
            style="width=100%"
            @change="getClioCalendars"
          >
            <el-option
              v-for="obj in linkedClioAccounts"
              :key="obj.id"
              :value="obj.id"
              :label="obj.name"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="Associated Calendar" label-width="auto" prop="calendar_id_clio">
          <el-select
            v-model="calendarAttributesForm.calendar_id_clio"

            filterable
            placeholder="Select Linked Calendar"
            style="width=100%"
            @change="getConsultTypes"
          >
            <el-option
              v-for="obj in calendarsInClioAccount"
              :key="obj.id"
              :value="obj.id"
              :label="obj.name"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="Display Name" prop="calendar_name">
          <el-input
            v-model="calendarAttributesForm.calendar_name"

            type="text"
            maxlength="45"
          />
        </el-form-item>
        <el-form-item label="Consultation Types" label-width="auto" prop="consultation_types">
          <el-select
            v-model="calendarAttributesForm.consultation_types"

            multiple
            filterable
            allow-create
            value-key="id_consultation_type"
            default-first-option
            placeholder="Select Consultation Types"
            style="width=100%"
          >
            <el-option
              v-for="obj in consultationTypes"
              :key="obj.id_consultation_type"
              :value="obj"
              :label="obj.consultation_type"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="Locations Serviced" label-width="auto">
          <el-select
            v-model="calendarAttributesForm.serviced_locations"
            multiple
            filterable
            allow-create
            default-first-option
            value-key="id_serviced_location"
            placeholder="Select Serviced Locations"
            style="width=100%"
          >
            <el-option
              v-for="obj in servicedLocations"
              :key="obj.id_serviced_location"
              :value="obj"
              :label="obj.serviced_location"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="Questionnaire URL" label-width="auto" prop="id_questionnaireurl">
          <el-select
            v-model="calendarAttributesForm.id_questionnaireurl"
            placeholder="Select Questionnaire URL"
            style="width=100%"
          >
            <el-option
              v-for="obj in questionnaireurls"
              :key="obj.id_questionnaireurl"
              :value="obj.id_questionnaireurl"
              :label="obj.questionnaireurl"
            />
          </el-select>
          <el-button type="primary" style="margin-left: 5px" @click="isShowAddUrlDialog = true">Add</el-button>
          <!-- <el-button type="primary" style="margin-left: 5px" @click="test">test</el-button> -->
        </el-form-item>
        <el-form-item label="Street Address">
          <el-input v-model="calendarAttributesForm.calendar_street_address" type="text" maxlength="45" />
        </el-form-item>
        <el-form-item label="City">
          <el-input v-model="calendarAttributesForm.calendar_city" type="text" maxlength="45" />
        </el-form-item>
        <el-form-item label="State">
          <el-input v-model="calendarAttributesForm.calendar_state" type="text" maxlength="4" />
        </el-form-item>
        <el-form-item label="Zip Code">
          <el-input v-model="calendarAttributesForm.calendar_zip" type="text" maxlength="6" />
        </el-form-item>
        <el-form-item label="Special Directions to Office: ">
          <el-input v-model="calendarAttributesForm.calendar_directions" type="textarea" maxlength="255" show-word-limit />
        </el-form-item>
        <el-form-item label="Special Appointment Instructions: " label-position="top">
          <el-input v-model="calendarAttributesForm.calendar_appointment_instructions" type="textarea" maxlength="255" show-word-limit />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeModal">Cancel</el-button>
        <el-button type="primary" @click="submitNewCalendar(calendarAttributesForm)">Submit</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import * as apiClioAdmin from '@/api/clio-admin'
import * as apiCalendarAdmin from '@/api/admin-calendar-management'
import * as apiLocation from '@/api/locations'
import { responseValidator } from '@/utils/response-validator'
import { mapGetters, mapState } from 'vuex'
import * as rules from './formRules.js'
import * as test from './test.json'
import AddQuestionnaireUrlForm from './calendar-add-questionnaire-url'

export default {
  name: 'CalendarAddAttributesForm',
  components: {
    'AddQuestionnaireUrlForm': AddQuestionnaireUrlForm
  },
  props: {
    modalVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: false,
      data: '0',
      rules: rules.attributesForm,
      calendarAttributesForm: {
        token_id: undefined,
        calendar_name: undefined,
        calendar_street_address: undefined,
        calendar_city: undefined,
        calendar_state: undefined,
        calendar_zip: undefined,
        calendar_directions: undefined,
        calendar_appointment_instructions: undefined,
        calendar_owners: undefined,
        calendar_id_clio: undefined,
        id_questionnaireurl: null,
        consultation_types: undefined, // array
        serviced_locations: undefined,
        inserted_by: undefined
      },
      linkedClioAccounts: [],
      calendarsInClioAccount: {},
      consultationTypes: {},
      servicedLocations: [],
      questionnaireurls: [],
      isShowAddUrlDialog: false
    }
  },
  computed: {
    ...mapGetters([
      'name',
      'avatar',
      'roles'
    ])
  },
  created: async function() {},
  mounted: async function() {
    try {
      await this.getLinkedClioAccounts()
      await this.getConsultTypes()
      await this.getServicedLocations()
      await this.getQuestionnaireUrls()
    } catch (e) {
      // if error, do nothing to reduce error spam
    }
  },
  methods: {
    // testing
    async test(val) {
      console.log(val)
    },

    // submitters
    async submitNewCalendar(form) {
      const result = await this.validateForm()
      if (result) {
        this.isLoading = true
        form.inserted_by = this.name
        const calendar_attributes_list = form
        this.calendarAttributesForm = {
          token_id: undefined,
          calendar_name: undefined,
          calendar_street_address: undefined,
          calendar_city: undefined,
          calendar_state: undefined,
          calendar_zip: undefined,
          calendar_directions: undefined,
          calendar_appointment_instructions: undefined,
          calendar_owners: undefined,
          calendar_id_clio: undefined,
          id_questionnaireurl: null,
          consultation_types: undefined, // array
          serviced_locations: undefined,
          inserted_by: undefined
        }
        try {
          const resp = await apiCalendarAdmin.addCalendarAttributesList(calendar_attributes_list)
          const validator = responseValidator(resp)
          if (!validator.success) throw validator
          this.$emit('close-and-refresh')
        } catch (e) {
          this.$notify.error({ title: 'Error!', message: e.message ? e.message : 'Unable to submit data.' })
        }
      }
      this.isLoading = false
    },

    // getter for external information
    async getLinkedClioAccounts() {
      try {
        const resp = await apiClioAdmin.getClioLinkedAccounts()
        const validator = responseValidator(resp)
        if (!validator.success) throw validator
        this.linkedClioAccounts = resp.clio_accounts.map((v, i) => {
          return { id: v.token_id, name: v.name }
        })
      } catch (e) {
        this.$notify.error({ title: 'Error!', message: e.message ? e.message : 'Unable to get data.' })
      }
    },
    async getClioCalendars(token_id) {
      try {
        const resp = await apiClioAdmin.getClioLinkedAccountCalendars(token_id)
        const validator = responseValidator(resp)
        if (!validator.success) throw validator
        this.calendarsInClioAccount = resp.calendars.map((v, i) => {
          return { id: v.id, name: v.name }
        })
      } catch (e) {
        this.$notify.error({ title: 'Error!', message: e.message ? e.message : 'Unable to get data.' })
      }
    },
    async getConsultTypes() {
      try {
        const resp = await apiCalendarAdmin.getConsultationTypes()
        const validator = responseValidator(resp)
        if (!validator.success) throw validator
        this.consultationTypes = resp.consultation_types
      } catch (e) {
        this.$notify.error({ title: 'Error!', message: e.message ? e.message : 'Unable to get consultation types.' })
      }
    },
    async getServicedLocations() {
      try {
        const resp = await apiCalendarAdmin.getAllServicedLocations()
        const validator = responseValidator(resp)
        if (!validator.success) throw validator
        this.servicedLocations = resp.serviced_locations
      } catch (e) {
        this.$notify.error({ title: 'Error!', message: e.message ? e.message : 'Unable to get serviced locations.' })
      }
    },
    async getQuestionnaireUrls() {
      try {
        const resp = await apiLocation.getAllQuestionnaireUrls()
        const validator = responseValidator(resp)
        if (!validator.success) throw validator
        this.questionnaireurls = resp.questionnaire_urls
      } catch (e) {
        this.$notify.error({ title: 'Error!', message: e.message ? e.message : 'Unable to get questionnaire urls.' })
      }
    },
    validateForm() {
      return new Promise((resolve, reject) => {
        this.$refs.calendarAttributesForm.validate(valid => {
          if (valid) {
            resolve(() => { return true }) // Resolve the promise if the form is valid
          } else {
            reject('Form validation failed') // Reject with an error message if validation fails
          }
        })
      })
    },
    // handling the mechanics of the modal
    async closeModal() {
      this.calendarAttributesForm = {
        token_id: undefined,
        calendar_name: undefined,
        calendar_street_address: undefined,
        calendar_city: undefined,
        calendar_state: undefined,
        calendar_zip: undefined,
        calendar_directions: undefined,
        calendar_appointment_instructions: undefined,
        calendar_owners: undefined,
        calendar_id_clio: undefined,
        consultation_types: undefined, // array
        serviced_locations: undefined,
        inserted_by: undefined
      }
      this.$emit('close')
    }
  }
}
</script>
<style lang="scss" scoped>
  .el-col {
    border-radius: 4px;
    margin-left: 20px;
  }
  .el-row {
    margin-bottom: 20px;
  }
  .text-input-label {
    display: inline-block;
    width: 200px;
    height: 36px;
    text-align: right;
    padding-top: 10px;
    padding-right: 20px;
  }

</style>
