var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-select",
        {
          attrs: { placeholder: "Lead Source" },
          on: { change: _vm.handleSelectChange },
          model: {
            value: _vm.selectedLeadSource,
            callback: function ($$v) {
              _vm.selectedLeadSource = $$v
            },
            expression: "selectedLeadSource",
          },
        },
        _vm._l(_vm.leadSources, function (leadSource) {
          return _c("el-option", {
            key: leadSource.id_lead_source,
            attrs: {
              label: leadSource.lead_source_name,
              value: leadSource.id_lead_source,
            },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }