<template>
  <div>
    <div>
      <ExternalAccountsAddClioAcct
        :modal-visible="externalAccountsAddClioAcctVisible"
        :account-to-edit="accountToEdit"
        :lazy="true"
        @close-and-refresh="closeAndRefresh"
      />
    </div>
    <div>
      <ExternalAccountsEditClioAcct
        :modal-visible="externalAccountsEditClioAcctVisible"
        :account-to-edit="accountToEdit"
        :lazy="true"
        @close-and-refresh="closeAndRefresh"
      />
    </div>
    <div
      v-loading="isLoading"
      class="table-container"
      element-loading-text="Fetching Accounts..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <h2> Linked Clio Accounts</h2>
      <el-table ref="clioAccountsTableRef" :data="clioAccountsTable" style="width: 100%">
        <el-table-column fixed prop="name" label="Account" />
        <el-table-column fixed prop="accountLink" label="Link" />
        <el-table-column align="center" label="Operations">
          <template slot-scope="scope">
            <el-button
              link
              align="center"
              type="primary"
              size="small"
              @click="editAccount(scope.$index, clioAccountsTable)"
            >Edit</el-button>
            <el-button link type="primary" size="small" @click.native.prevent="removeAccount(scope.$index, clioAccountsTable)">Remove</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-button
        class="mt-4"
        style="width: 100%"
        @click="addClioAccount"
      >Link Office</el-button>
      <p>Note: When adding multiple accounts, click the settings button on the popup, log out of your clio account and close the popup.  Then go through the link office process again to add another clio account. </p>
    </div>
  </div>
</template>

<script>

import * as apiClioAdmin from '@/api/clio-admin'
import { mapGetters, mapState } from 'vuex'
import ExternalAccountsAddClioAcct from './external-accounts-add-clio-acct.vue'
import ExternalAccountsEditClioAcct from './external-accounts-edit-clio-acct.vue'
import { responseValidator } from '@/utils/response-validator'

export default {
  name: 'ExternalAccountLinking',
  components: {
    ExternalAccountsAddClioAcct: ExternalAccountsAddClioAcct,
    ExternalAccountsEditClioAcct: ExternalAccountsEditClioAcct
  },
  props: {},
  data() {
    return {
      isLoading: false,
      data: '0',
      // bad lead category
      externalAccountsAddClioAcctVisible: false,
      externalAccountsEditClioAcctVisible: false,
      accountToEdit: {},
      clioAccountToName: undefined,
      clioAccount: '',
      clioAccountsTable: [],
      clioAccountsColumns: ['name', 'inserted_on', 'inserted_by', 'accountLink'],
      clioAccountRow: null
    }
  },
  computed: {
    ...mapGetters([
      'name',
      'avatar',
      'roles'
    ])
  },
  created: async function() {},
  mounted: async function() {
    this.isLoading = true
    await this.fetchClioAccounts()
    this.isLoading = false
  },
  methods: {

    /*
    *   External Accounts TABLE
    */
    async addClioAccount() {
      try {
        const resp = await apiClioAdmin.getAuthUri()
        var win = window.open(resp.auth_uri, '_blank', 'width=800,height=900,status=0,toolbar=0')
        var timer = setInterval(async() => {
          if (win.closed) {
            clearInterval(timer)
            const existingAccountsLength = this.clioAccountsTable.length
            await this.fetchClioAccounts()
            if (this.clioAccountsTable.length > existingAccountsLength) {
              this.externalAccountsAddClioAcctVisible = true
              this.accountToEdit = this.clioAccountsTable[this.clioAccountsTable.length - 1]
            }
          }
        }, 1000)
      } catch (e) {
        this.$notify.error({ title: 'Error!', message: e.message ? e.message : 'Unable to get auth link.' })
      }
    },
    async removeAccount(index, rows) {
      try {
        if (rows[index]['token_id'] !== undefined) {
          const resp = await apiClioAdmin.removeClioLinkedAccount({
            token_id: rows[index].token_id,
            inserted_by: this.name
          })
          const validator = responseValidator(resp)
          if (!validator.success) throw validator
        }
      } catch (e) {
        this.$notify.error({ title: 'Error!', message: e.message ? e.message : 'Unable to get auth link.' })
      }
      await this.fetchClioAccounts()
    },
    async editAccount(index, rows) {
      if (rows[index]['token_id'] !== undefined) {
        this.accountToEdit = rows[index]
        this.externalAccountsEditClioAcctVisible = true
      }
    },
    async fetchClioAccounts() {
      try {
        const resp = await apiClioAdmin.getClioLinkedAccounts()
        const validator = responseValidator(resp)
        if (!validator.success) throw validator
        var clioAccountsTable = resp.clio_accounts
        for (let i = 0; i < clioAccountsTable.length; i++) {
          var accountLink = await apiClioAdmin.getAccountLinkStatus(clioAccountsTable[i].token_id)
          clioAccountsTable[i]['accountLink'] = accountLink.account_link
        }
        console.log(clioAccountsTable)
        this.clioAccountsTable = clioAccountsTable
      } catch (e) {
        this.$notify.error({ title: 'Error!', message: e.message ? e.message : 'Unable to get data.' })
      }
    },
    async handleClioAccountsSelect(row) {
      this.selectedClioAccountRow = row
    },
    async closeAndRefresh() {
      this.externalAccountsAddClioAcctVisible = false
      this.externalAccountsEditClioAcctVisible = false
      await this.fetchClioAccounts()
    }
  }
}
</script>
<style lang="scss" scoped>
  .el-col {
    border-radius: 4px;
    margin-left: 20px;
  }
  .el-row {
    margin-bottom: 20px;
  }
  .text-input-label {
    display: inline-block;
    width: 200px;
    height: 36px;
    text-align: right;
    padding-top: 10px;
    padding-right: 20px;
  }

  .table-container {
    display: flex;
    width: 40%;
    min-width: 500px;
    flex-direction: column;
    align-items: center;
  }

</style>
