var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { margin: "10px" } },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c("div", { staticClass: "card-header" }, [
                _vm._v("Intake Script"),
              ]),
              _c(
                "el-collapse",
                {
                  attrs: { accordion: "" },
                  model: {
                    value: _vm.activeItem,
                    callback: function ($$v) {
                      _vm.activeItem = $$v
                    },
                    expression: "activeItem",
                  },
                },
                _vm._l(_vm.scriptItems, function (item) {
                  return _c(
                    "el-collapse-item",
                    {
                      key: item.label,
                      attrs: { name: item.label, title: item.label },
                    },
                    _vm._l(item.text, function (statement, index) {
                      return _c(
                        "div",
                        {
                          key: statement,
                          staticStyle: {
                            "padding-left": "10px",
                            "padding-right": "10px",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { "font-weight": "bold" } },
                            [_vm._v("Statement " + _vm._s(index + 1) + ":")]
                          ),
                          _vm._v(_vm._s(statement) + " "),
                        ]
                      )
                    }),
                    0
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }