<template>
  <div class="app-container">
    <el-form ref="feedbackForm" :model="feedbackForm" label-position="top" label-width="120px">
      <el-form-item
        label="Subject"
        prop="subject"
        :rules="[
          {
            required: true,
            message: 'Field required.',
            trigger: 'blur',
          },
        ]"
      >
        <el-col :span="10">
          <el-input v-model="feedbackForm.subject" />
        </el-col>
      </el-form-item>

      <el-form-item
        label="Feedback"
        prop="feedback"
        :rules="[
          {
            required: true,
            message: 'Field required.',
            trigger: 'blur',
          },
        ]"
      >
        <el-col :span="10">
          <el-input v-model="feedbackForm.feedback" type="textarea" :rows="3" />
        </el-col>
      </el-form-item>
      <el-form-item size="large">
        <el-button type="primary" @click="submitForm('feedbackForm')">
          Submit
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { submitFeedback } from '@/api/feedback'

export default {
  name: 'Feedback',
  props: {},
  data() {
    return {
      feedbackForm: {
        subject: '',
        feedback: ''
      }
    }
  },
  created() {},
  mounted() {},

  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var payLoad = {
            subject: this.feedbackForm.subject,
            feedback: this.feedbackForm.feedback
          }

          submitFeedback(payLoad).then(() => {
            this.feedbackForm.subject = ''
            this.feedbackForm.feedback = ''
            this.$notify({
              title: 'Thank you',
              message: 'Your feedback has been submitted.',
              type: 'success',
              duration: 2000
            })
          })
        } else {
          console.log('Submission Error!')
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
