import { getToken, setToken, removeToken } from '@/utils/auth'
import router, { resetRouter } from '@/router'
import { Auth } from 'aws-amplify'

const state = {
  token: getToken(),
  name: '',
  avatar: '',
  introduction: '',
  roles: []
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  }
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      Auth.signIn(username.trim(), password).then(user => {
        const { signInUserSession, challengeName } = user
        if (challengeName === 'NEW_PASSWORD_REQUIRED') {
          resolve(user)
        } else {
          console.log('my token: ', signInUserSession.accessToken.jwtToken)
          commit('SET_TOKEN', signInUserSession.accessToken.jwtToken)
          setToken(signInUserSession.accessToken.jwtToken)
          resolve()
        }
      }).catch(error => {
        reject(error)
      })
    })
  },

  async refreshToken({ commit }) {
    const user = Auth.currentAuthenticatedUser()
    await Auth.userSession(user).then(user => {
      commit('SET_TOKEN', user.getIdToken().jwtToken)
      setToken(user.getIdToken().jwtToken)
      Promise.resolve()
    }).catch(error => {
      Promise.reject(error)
    })
  },

  completeNewPassword({ commit }, body) {
    return new Promise((resolve, reject) => {
      Auth.completeNewPassword(body.user, body.newPassword).then(user => {
        const { signInUserSession } = user
        commit('SET_TOKEN', signInUserSession.accessToken.jwtToken)
        setToken(signInUserSession.accessToken.jwtToken)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  forgotPassword({ commit }, body) {
    console.log('forgotPassword', body)
    return new Promise((resolve, reject) => {
      Auth.forgotPassword(body.usernameToReset).then(data => {
        console.log('forgotPassword', data)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  forgotPasswordSubmit({ commit }, body) {
    return new Promise((resolve, reject) => {
      Auth.forgotPasswordSubmit(body.usernameToReset, body.verificationCode, body.password).then(data => {
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser().then(response => {
        const { attributes } = response

        if (response?.signInUserSession?.accessToken?.payload) {
          console.log(response.signInUserSession)
          var cognitoGroups = response.signInUserSession.accessToken.payload['cognito:groups']
          if (cognitoGroups && cognitoGroups.length > 0) {
            // attributes.roles = ['admin']
            attributes.roles = cognitoGroups
          }
        }

        if (!attributes) {
          reject('Verification failed, please Login again.')
        }

        const { email, roles } = attributes

        // roles must be a non-empty array
        if (!roles || roles.length <= 0) {
          reject('getInfo: roles must be a non-null array!')
        }

        commit('SET_ROLES', attributes.roles)
        commit('SET_NAME', email)
        commit('SET_AVATAR', 'https://s3.amazonaws.com/legalmerge.com/images/Person-7.svg')
        commit('SET_INTRODUCTION', 'I am an admin')
        resolve(attributes)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      Auth.signOut().then(() => {
        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        removeToken()
        resetRouter()

        // reset visited views and cached views
        // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
        dispatch('tagsView/delAllViews', null, { root: true })

        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  },

  // dynamically modify permissions
  async changeRoles({ commit, dispatch }, role) {
    const token = role + '-token'

    commit('SET_TOKEN', token)
    setToken(token)

    const { roles } = await dispatch('getInfo')

    resetRouter()

    // generate accessible routes map based on roles
    const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })
    // dynamically add accessible routes
    router.addRoutes(accessRoutes)

    // reset visited views and cached views
    dispatch('tagsView/delAllViews', null, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
