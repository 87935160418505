import request from '@/utils/request'

export function fetchAppointmentList(data) {
  return request({
    url: '/appointmentresult/fetch',
    method: 'post',
    data
  })
}

export function submitAppointmentResult(data) {
  return request({
    url: '/appointmentresult/other',
    method: 'post',
    data
  })
}

export function getListData(data) {
  return request({
    url: '/appointmentresult/filter',
    method: 'post',
    data
  })
}

export function lookupMatterData(data) {
  return request({
    url: '/appointmentresult/lookup',
    method: 'post',
    data
  })
}
