var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.modalVisible,
        "close-on-click-modal": false,
        title: "Clio Account Name:",
      },
      on: {
        "update:visible": function ($event) {
          _vm.modalVisible = $event
        },
        close: _vm.onCloseModal,
        open: _vm.onOpenModal,
      },
    },
    [
      _c("el-input", {
        attrs: { placeholder: "Please input account name." },
        model: {
          value: _vm.accountName,
          callback: function ($$v) {
            _vm.accountName = $$v
          },
          expression: "accountName",
        },
      }),
      _c("el-button", { on: { click: _vm.submitAccountName } }, [
        _vm._v(" Accept "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }