<template>
  <el-dialog
    :visible.sync="existingCallerFoundDialogVisible"
    width="50%"
  >
    <span slot="title">
      <svg-icon icon-class="phone-call" />
      <el-divider direction="vertical" />
      <span>Incoming Call from Existing Client</span>
    </span>
    <el-table :data="existingCallerTableData" style="width: 100%" border :show-header="false">
      <el-table-column
        prop="attribute"
        label="Attribute"
        width="180"
      />
      <el-table-column
        prop="value"
        label="Value"
      />
    </el-table>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="populateForm">OK</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { bus } from '@/main'
import { tryFindExistingCaller } from '@/api/clio'
import { parsePhoneNumber } from 'libphonenumber-js'

export default {
  name: 'ScreenPop',
  data() {
    return {
      existingCaller: undefined,
      existingCallerFoundDialogVisible: false,
      searchingClio: false
    }
  },
  computed: {
    existingCallerTableData() {
      var summary = [
        { attribute: 'Clio Office', value: this.existingCaller?.clioOffice },
        { attribute: 'First Name', value: this.existingCaller?.first_name },
        { attribute: 'Last Name', value: this.existingCaller?.last_name },
        { attribute: 'Email Address', value: this.existingCaller?.primary_email_address },
        { attribute: 'Phone Number', value: this.existingCaller?.phoneNumber }
      ]
      return summary
    }
  },
  created() {
    var me = this
    bus.$on('screen-pop', function(fromNumber) {
      if (fromNumber) {
        const incomingNumberParsed = parsePhoneNumber(fromNumber, 'US')
        var phoneNumber = incomingNumberParsed?.nationalNumber
        try {
          me.$message('Searching Clio locations for incoming caller ' + phoneNumber)
          if (phoneNumber) {
            tryFindExistingCaller({ strippedCallerNoSpace: phoneNumber, strippedCallerWithSpace: phoneNumber }).then(response => {
              if (response && response.length > 0) {
                me.existingCaller = response[0].data[0]
                me.existingCaller.clioOffice = response[0].clioOffice
                me.existingCallerFoundDialogVisible = true
                me.existingCaller.phoneNumber = phoneNumber
                me.existingCallerFoundDialogVisible = true
              } else {
                me.$message('Incoming caller not found in any Clio locations')
              }
            })
          }
        } catch (e) {
          console.log(e)
        }
      }
    })
  },

  methods: {
    populateForm() {
      this.existingCallerFoundDialogVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
