import request from '@/utils/request'

export function getLeadInfo(query) {
  return request({
    url: '/lead-info/costs/' + query,
    method: 'get'
  })
}

export function insertLeadCost(data) {
  return request({
    url: '/lead-info/costs',
    method: 'post',
    data
  })
}

export function getLeadSourceNames(data) {
  return request({
    url: '/lead-info/getLeadSources',
    method: 'get'
  })
}

export function updateLeadSource(data) {
  return request({
    url: '/lead-info/updateLeadSources',
    method: 'post',
    data
  })
}

export function addLeadSource(data) {
  return request({
    url: '/lead-info/addLeadSource',
    method: 'post',
    data
  })
}

export function invalidateLeadCost(data) {
  return request({
    url: '/lead-info/costs/invalidate',
    method: 'post',
    data
  })
}
