<template>
  <div class="app-container">
    <el-row :gutter="20">
      <el-col :span="7">
        <div class="date-control-container">
          <el-date-picker
            v-model="dateFilter"
            size="mini"
            type="daterange"
            range-separator="To"
            start-placeholder="Start date"
            end-placeholder="End date"
            value-format="yyyy-MM-dd"
            @change="fetchRecordsRange"
          />
        </div>
      </el-col>
      <div />
      <!-- <el-col
        :span="3"
        style="
          padding-left: 0px;
          padding-right: 10px;
          padding-top: 10px;
          padding-bottom: 10px;
        "
      >
        <div
          class="lead-number"
        >
          <el-input
            v-model="leadNumber"
            placeholder="Lead Number"
            size="mini"
          />
        </div>
      </el-col> -->

      <!-- <el-col
        :span="4"
        style="
          padding-left: 10px;
          padding-right: 10px;
          padding-top: 10px;
          padding-bottom: 10px;
        "
      ><div>
        <el-button
          size="mini"
          type="primary"
          icon="el-icon-document"
          @click="fetchRecordsRange"
        >
          Find
        </el-button>
      </div></el-col> -->

      <el-col
        :span="4"
        style="padding-left: 10px;padding-right: 10px;padding-top: 10px;padding-bottom: 10px;"
      ><div />
        <el-input
          v-model="searchBox"
          size="mini"
          placeholder="Filter by Phone Number"
        /></el-col>
    </el-row>
    <el-row :gutter="20" style="margin-top: 15px">
      <el-col
        :span="24"
      ><div>
        <el-table
          id="analyticsTable"
          v-loading="listLoading"
          :data="rows.filter(data => !searchBox || data['Lead Phone'].toLowerCase().includes(searchBox.toLowerCase()))"
          border
          fit
          stripe
          :max-height="800"
          :show-summary="false"
          highlight-current-row
          style="width: 100%"
        >
          <el-table-column
            v-for="col in columns"
            :key="col"
            :label="col"
            :prop="col"
            sortable
            :resizable="true"
            show-overflow-tooltip
            align="left"
            header-align="center"
            :column-key="col"
          />
        </el-table></div></el-col></el-row>
  </div>
</template>

<script>
import {
  getRecords
} from '@/api/twilio'

const endDay = new Date()
var startDay = new Date(endDay.getFullYear(), endDay.getMonth(), endDay.getDate() - 1)

export default {
  clientId: undefined,
  name: 'MessageDashboard',
  components: {},
  data() {
    return {
    //  leadNumber: '',
      dateFilter: [startDay, endDay],
      displayDate: endDay,
      filterTypes: {
        locationFilter: [],
        resultFilter: []
      },
      list: null,
      listLoading: false, // this should be set to true when the data is loaded
      listQuery: {
        page: 1,
        limit: 10,
        location: [],
        listDate: endDay,
        apptStatusType: null
      },
      rows: [],
      searchBox: '',
      locationOptions: [
        { value: 'null', label: 'All Locations' },
        { value: '1', label: 'Montgomery/Opelika' },
        { value: '2', label: 'Gadsden/Anniston' },
        { value: '3', label: 'Florence/Haleyville' },
        { value: '4', label: 'Huntsville/Decatur/Cullman' },
        { value: '5', label: 'Jackson/Vicksburg/Hattiesburg' },
        { value: '6', label: 'Mobile' },
        { value: '8', label: 'Birmingham' }
      ]
    }
  },
  computed: {
    columns: function columns() {
      if (this.rows.length === 0) {
        return []
      }
      return Object.keys(this.rows[0])
    }
  },
  mounted: async function() {
    await this.fetchRecordsRange()
  },
  methods: {
    createColumnFilterList() {
      var columnArray = this.columns
      var arrayObject = []
      for (var i = 0; i < columnArray.length; i++) {
        arrayObject.push({ id: columnArray[i], hidden: false })
      }
      this.columnFilters = arrayObject
    },
    async fetchRecordsRange() {
      this.searchBox = ''
      this.listLoading = true
      var tableData = await getRecords({

        dateSent: this.dateFilter,
        limit: 500
      })
      tableData.messages.forEach(function(value, index) {
        tableData.messages[index]['Date Sent'] = new Date(tableData.messages[index]['Date Sent']).toLocaleString()
      })

      this.listLoading = false
      if (tableData.data !== 'none') {
        this.rows = tableData.messages
      } else {
        this.rows = []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination-container {
  margin-top: 0px;
  padding: 15px 16px;
}
.analytics-filter-container {
  .filter-item {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }
  .export-button {
    vertical-align: middle;
    position: absolute;
    right: 0;
  }
}

.lead-number .el-input__inner {
  height: 28px;
}

.date-control-container {
  padding-top: 10px;
  padding-bottom: 10px;
}

.filter-item {
  width: 100%;
}

.chart-container {
  position: relative;
  width: 100%;
  height: calc(100vh - 84px);
}
</style>
