import request from '@/utils/request'

export function fetchList(query) {
  return request({
    url: '/clio/api/v4/calendar_entries.json' + query,
    method: 'get'
  })
}

export function fetchEvent(id) {
  return request({
    url: '/event/detail',
    method: 'get',
    params: { id }
  })
}

export function createEvent(data) {
  return request({
    url: '/event/create',
    method: 'post',
    data
  })
}

export function updateEvent(data) {
  return request({
    url: '/event/update',
    method: 'post',
    data
  })
}
