var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        _c("ExternalAccountsAddClioAcct", {
          attrs: {
            "modal-visible": _vm.externalAccountsAddClioAcctVisible,
            "account-to-edit": _vm.accountToEdit,
            lazy: true,
          },
          on: { "close-and-refresh": _vm.closeAndRefresh },
        }),
      ],
      1
    ),
    _c(
      "div",
      [
        _c("ExternalAccountsEditClioAcct", {
          attrs: {
            "modal-visible": _vm.externalAccountsEditClioAcctVisible,
            "account-to-edit": _vm.accountToEdit,
            lazy: true,
          },
          on: { "close-and-refresh": _vm.closeAndRefresh },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.isLoading,
            expression: "isLoading",
          },
        ],
        staticClass: "table-container",
        attrs: {
          "element-loading-text": "Fetching Accounts...",
          "element-loading-spinner": "el-icon-loading",
          "element-loading-background": "rgba(0, 0, 0, 0.8)",
        },
      },
      [
        _c("h2", [_vm._v(" Linked Clio Accounts")]),
        _c(
          "el-table",
          {
            ref: "clioAccountsTableRef",
            staticStyle: { width: "100%" },
            attrs: { data: _vm.clioAccountsTable },
          },
          [
            _c("el-table-column", {
              attrs: { fixed: "", prop: "name", label: "Account" },
            }),
            _c("el-table-column", {
              attrs: { fixed: "", prop: "accountLink", label: "Link" },
            }),
            _c("el-table-column", {
              attrs: { align: "center", label: "Operations" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            link: "",
                            align: "center",
                            type: "primary",
                            size: "small",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.editAccount(
                                scope.$index,
                                _vm.clioAccountsTable
                              )
                            },
                          },
                        },
                        [_vm._v("Edit")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { link: "", type: "primary", size: "small" },
                          nativeOn: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.removeAccount(
                                scope.$index,
                                _vm.clioAccountsTable
                              )
                            },
                          },
                        },
                        [_vm._v("Remove")]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c(
          "el-button",
          {
            staticClass: "mt-4",
            staticStyle: { width: "100%" },
            on: { click: _vm.addClioAccount },
          },
          [_vm._v("Link Office")]
        ),
        _c("p", [
          _vm._v(
            "Note: When adding multiple accounts, click the settings button on the popup, log out of your clio account and close the popup. Then go through the link office process again to add another clio account. "
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }