<template>
  <div>
    <AddQuestionnaireUrlForm
      :modal-visible="isShowAddUrlDialog"
      :lazy="true"
      @close="isShowAddUrlDialog = false"
      @update-urls="getQuestionnaireUrls"
    />
    <el-dialog
      v-loading="isLoading"
      element-loading-text="Loading..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      title="Edit Calendar"
      :visible.sync="modalVisible"
      width="575px"
      :close-on-click-modal="false"
      @close="closeModal"
    >
      <el-form ref="calendarAttributesToEdit" :model="calendarAttributesToEdit" :rules="rules" label-width="auto">
        <el-form-item label="Clio Account" label-width="auto">
          <el-select
            v-model="calendarAttributesToEdit.name"
            filterable
            placeholder="Select Linked Account"
            style="width=100%"
            edit
            disabled
            @change="getClioCalendars"
          >
            <el-option
              v-for="obj in linkedClioAccounts"
              :key="obj.id"
              :value="obj.id"
              :label="obj.name"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="Associated Calendar" label-width="auto">
          <el-select
            v-model="calendarAttributesToEdit.clio_calendar_name"
            filterable
            disabled
            placeholder="Select Linked Account"
            style="width=100%"
            @change="getConsultTypes"
          >
            <el-option
              v-for="obj in calendarsInClioAccount"
              :key="obj.id"
              :value="obj.id"
              :label="obj.name"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="Consultation Types" label-width="auto" prop="consultation_types">
          <el-select
            v-model="calendarAttributesToEdit.consultation_types"
            multiple
            filterable
            allow-create
            :value-key="consultationTypeKey"
            default-first-option
            placeholder="Select Consultation Types"
            style="width=100%"
          >
            <el-option
              v-for="obj in consultationTypes"
              :key="obj.id_consultation_type"
              :value="obj"
              :label="obj.consultation_type"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="Calendar Name" prop="calendar_name">
          <el-input v-model="calendarAttributesToEdit.calendar_name" type="text" maxlength="45" />
        </el-form-item>
        <el-form-item label="Locations Serviced" label-width="auto">
          <el-select
            v-model="calendarAttributesToEdit.serviced_locations"
            multiple
            filterable
            allow-create
            default-first-option
            :value-key="servicedLocationKey"
            placeholder="Select Locations Serviced"
            style="width=100%"
          >
            <el-option
              v-for="obj in servicedLocations"
              :key="obj.id_serviced_location"
              :value="obj"
              :label="obj.serviced_location"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="Questionnaire URL" label-width="auto" prop="id_questionnaireurl">
          <el-select
            v-model="calendarAttributesToEdit.id_questionnaireurl"
            placeholder="Select Questionnaire URL"
            style="width=100%"
            :value-key="questionnaireUrlKey"
            @change="test"
          >
            <el-option
              v-for="obj in questionnaireurls"
              :key="obj.id_questionnaireurl"
              :value="obj.id_questionnaireurl"
              :label="obj.questionnaireurl"
            />
          </el-select>
          <el-button type="primary" style="margin-left: 5px" @click="isShowAddUrlDialog = true">Add</el-button>
          <!-- <el-button type="primary" style="margin-left: 5px" @click="test">test</el-button> -->
        </el-form-item>
        <el-form-item label="Street Address" prop="calendar_street_address">
          <el-input v-model="calendarAttributesToEdit.calendar_street_address" type="text" maxlength="45" />
        </el-form-item>
        <el-form-item label="City" prop="calendar_city">
          <el-input v-model="calendarAttributesToEdit.calendar_city" type="text" maxlength="45" />
        </el-form-item>
        <el-form-item label="State" prop="calendar_state">
          <el-input v-model="calendarAttributesToEdit.calendar_state" type="text" maxlength="4" />
        </el-form-item>
        <el-form-item label="Zip Code" prop="calendar_zip">
          <el-input v-model="calendarAttributesToEdit.calendar_zip" type="text" maxlength="6" />
        </el-form-item>
        <el-form-item label="Special Directions to Calendar: " prop="calendar_directions">
          <el-input v-model="calendarAttributesToEdit.calendar_directions" type="textarea" maxlength="255" show-word-limit />
        </el-form-item>
        <el-form-item label="Special Appointment Instructions: " label-position="top" prop="calendar_directions">
          <el-input v-model="calendarAttributesToEdit.calendar_appointment_instructions" type="textarea" maxlength="255" show-word-limit />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeModal">Cancel</el-button>
        <el-button type="primary" @click="submitEditedCalendar(calendarAttributesToEdit)">Submit</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import * as apiClioAdmin from '@/api/clio-admin'
import * as apiCalendarAdmin from '@/api/admin-calendar-management'
import * as apiLocation from '@/api/locations'
import { mapGetters, mapState } from 'vuex'
import { responseValidator } from '@/utils/response-validator'
import AddQuestionnaireUrlForm from './calendar-add-questionnaire-url'
import * as rules from './formRules'

export default {
  name: 'CalendarEditAttributesForm',
  components: {
    'AddQuestionnaireUrlForm': AddQuestionnaireUrlForm
  },
  props: {
    modalVisible: {
      type: Boolean,
      default: false
    },
    calendarAttributesToEdit: {
      type: Object,
      default: () => { return { } }
    }
  },
  data() {
    return {
      isLoading: false,
      data: '0',
      rules: rules.editAttributesForm,
      calendarAttributesForm: {
        id_location_attributes_list: undefined,
        token_id: null,
        calendar_name: null,
        calendar_street_address: null,
        calendar_city: null,
        calendar_state: null,
        calendar_zip: null,
        calendar_directions: null,
        calendar_appointment_instructions: null,
        calendar_owners: null,
        clio_id_calendar: null,
        id_questionnaireurl: null,
        consultation_types: null, // array
        serviced_locations: null,
        inserted_by: this.name
      },
      linkedClioAccounts: {},
      calendarsInClioAccount: {},
      consultationTypeKey: 'id_consultation_type',
      consultationTypes: [],
      servicedLocationKey: 'id_serviced_location',
      servicedLocations: [],
      questionnaireUrlKey: 'id_questionnaireurl',
      questionnaireurls: [],
      isShowAddUrlDialog: false
    }
  },
  computed: {
    ...mapGetters([
      'name',
      'avatar',
      'roles'
    ])
  },
  created: async function() {},
  mounted: async function() {
    await this.getConsultTypes()
    await this.getServicedLocations()
    await this.getQuestionnaireUrls()
  },
  methods: {
    // testing
    // async testFunction(val) {
    //   console.log(val)
    // },

    // submitters
    async test() {
      console.log(this.calendarAttributesToEdit)
      console.log(this.calendarAttributesToEdit.questionnaireurl)
      console.log(this.questionnaireurls)
    },
    async submitEditedCalendar(form) {
      Object.keys(form).forEach(key => {
        if (form[key] === undefined || form[key] === null) {
          form[key] = ' '
        }
      })
      const calendar_attributes_list = form
      await apiCalendarAdmin.editCalendarAttributesList(calendar_attributes_list)
      this.$emit('close-and-refresh')
    },

    // getter for external information
    async getLinkedClioAccounts() {
      try {
        const resp = await apiClioAdmin.getClioLinkedAccounts()
        const validator = responseValidator(resp)
        if (!validator.success) throw validator
        this.linkedClioAccounts = resp.clio_accounts.map((v, i) => {
          return { id: v.token_id, name: v.account_name }
        })
      } catch (e) {
        this.$notify.error({ title: 'Error!', message: e.message ? e.message : 'Unable to get data.' })
      }
    },
    async getClioCalendars(token_id) {
      try {
        const resp = await apiClioAdmin.getClioLinkedAccountCalendars({ token_id: token_id })
        const validator = responseValidator(resp)
        if (!validator.success) throw validator
        this.calendarsInClioAccount = resp.calendars.map((v, i) => {
          return { id: v.id, name: v.name }
        })
      } catch (e) {
        this.$notify.error({ title: 'Error!', message: e.message ? e.message : 'Unable to get data.' })
      }
    },
    async getConsultTypes() {
      try {
        const resp = await apiCalendarAdmin.getConsultationTypes()
        const validator = responseValidator(resp)
        if (!validator.success) throw validator
        this.consultationTypes = resp.consultation_types
      } catch (e) {
        this.$notify.error({ title: 'Error!', message: e.message ? e.message : 'Unable to get data.' })
      }
    },
    async getServicedLocations() {
      try {
        const resp = await apiCalendarAdmin.getAllServicedLocations()
        const validator = responseValidator(resp)
        if (!validator.success) throw validator
        this.servicedLocations = resp.serviced_locations
      } catch (e) {
        this.$notify.error({ title: 'Error!', message: e.message ? e.message : 'Unable to get data.' })
      }
    },
    async getQuestionnaireUrls() {
      try {
        const resp = await apiLocation.getAllQuestionnaireUrls()
        const validator = responseValidator(resp)
        if (!validator.success) throw validator
        this.questionnaireurls = resp.questionnaire_urls
      } catch (e) {
        this.$notify.error({ title: 'Error!', message: e.message ? e.message : 'Unable to get questionnaire urls.' })
      }
    },
    // data manipulation
    async isNumber(char) {
      if (typeof char === 'number') {
        return true
      }
      return false
    },

    // handling the mechanics of the modal
    async closeModal() {
      this.calendarAttributesForm = {
        id_location_attributes_list: undefined,
        token_id: null,
        calendar_name: null,
        calendar_street_address: null,
        calendar_city: null,
        calendar_state: null,
        calendar_zip: null,
        calendar_directions: null,
        calendar_appointment_instructions: null,
        calendar_owners: null,
        clio_id_calendar: null,
        consultation_types: null, // array
        serviced_locations: null,
        inserted_by: this.name
      }
      this.$emit('close')
    }
  }
}
</script>
<style lang="scss" scoped>
  .el-col {
    border-radius: 4px;
    margin-left: 20px;
  }
  .el-row {
    margin-bottom: 20px;
  }
  .text-input-label {
    display: inline-block;
    width: 200px;
    height: 36px;
    text-align: right;
    padding-top: 10px;
    padding-right: 20px;
  }

</style>

<style lang="scss">
  .el-tabs--border-card > .el-tabs__content {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    overflow: auto !important;
  }
  .el-tab-pane {
    height: 100vh;
    overflow: auto !important;
    overflow-y: auto !important;
  }
</style>
