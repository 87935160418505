<template>
  <div class="block">
    <el-timeline>
      <el-timeline-item v-for="(item,index) of timeline" :key="index" :timestamp="item.timestamp" placement="top">
        <el-card>
          <h4>{{ item.title }}</h4>
          <p>{{ item.content }}</p>
        </el-card>
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timeline: [
        {
          timestamp: '2020/4/20',
          title: 'Answered Call',
          content: ''
        },
        {
          timestamp: '2020/4/20',
          title: 'Created Contact',
          content: ''
        },
        {
          timestamp: '2020/4/20',
          title: 'Created Matter',
          content: ''
        },
        {
          timestamp: '2020/4/20',
          title: 'Scheduled Appointment',
          content: ''
        }
      ]
    }
  }
}
</script>
