const state = {
  clientIntakeInformation: {}
}

const mutations = {
  SET_CLIENT_INTAKE_INFORMATION: (state, clientIntakeInformation) => {
    state.clientIntakeInformation = clientIntakeInformation
  },
  CLEAR_CLIENT_INTAKE_INFORMATION: (state) => {
    state.clientIntakeInformation = {}
  }
}

const actions = {
  async setClientIntakeInformation({ commit }, clientIntakeInformation) {
    commit('SET_CLIENT_INTAKE_INFORMATION', clientIntakeInformation)
  },
  async clearClientIntakeInformation({ commit }) {
    commit('CLEAR_CLIENT_INTAKE_INFORMATION')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
