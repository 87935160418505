<template>
  <div>
    <el-select
      v-model="selectedLeadSource"
      placeholder="Lead Source"
      @change="handleSelectChange"
    >
      <el-option v-for="leadSource in leadSources" :key="leadSource.id_lead_source" :label="leadSource.lead_source_name" :value="leadSource.id_lead_source" />
    </el-select>
  </div>
</template>

<script>
import {
  getListData
} from '@/api/appointmentresult'

export default {
  name: 'LeadSources',
  props: {
    selectedLeadSourceID: {
      // type: String,
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      selectedLeadSource: undefined,
      leadSources: [],
      reportSelected: 'lead_sources',
      officeQuery: {
        firmId: 1
      }
    }
  },
  watch: {
    selectedLeadSourceID(newValue, oldValue) {
      this.selectedLeadSource = newValue
      console.log('selectedLeadSource new value', newValue)
      console.log('selectedLeadSource old value', oldValue)
      this.handleSelectChange()
    }
  },
  mounted() {
    console.log('lead sources select list mounted')
    console.log('selectedLeadSourceID', this.selectedLeadSourceID)
    if (this.selectedLeadSourceID) {
      this.selectedLeadSource = this.selectedLeadSourceID
    }
    this.fetchLeadSources()
  },
  methods: {
    async fetchLeadSources() {
      var leadSourceData = await getListData([['dateBegin, dateEnd'], this.reportSelected, 'location', 'currentTime'])
      this.leadSources = leadSourceData.data
    },
    handleSelectChange() {
      console.log('handleSelectChange')
      this.$emit('update-selected-lead-source', this.selectedLeadSource)
    }
  }
}
</script>
