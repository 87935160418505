var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _c("roi-form", {
            ref: "roi_form",
            attrs: {
              "dialog-visible": _vm.formDialogVisible,
              "existing-row": _vm.tempExistingRow,
            },
            on: { close: _vm.closeFormDialog },
          }),
        ],
        1
      ),
      _c(
        "el-row",
        { staticStyle: { "margin-top": "15px" } },
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _c(
              "div",
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.listLoading,
                        expression: "listLoading",
                      },
                    ],
                    staticStyle: { width: "100%" },
                    attrs: {
                      id: "analyticsTable",
                      data: _vm.rows,
                      border: "",
                      fit: false,
                      stripe: "",
                      "max-height": 800,
                      "show-summary": false,
                      "highlight-current-row": "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        label: "Lead Source",
                        width: "180",
                        "column-key": "lead_source_name",
                        prop: "lead_source_name",
                        "header-align": "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Cost Type",
                        width: "180",
                        "column-key": "lead_cost_type",
                        prop: "lead_cost_type",
                        "header-align": "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Cost Period",
                        width: "180",
                        "column-key": "cost_period",
                        prop: "cost_period",
                        formatter: _vm.dateFormatter,
                        "header-align": "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Montgomery/Opelika",
                        width: "180",
                        "column-key": "1.amount",
                        prop: "1.amount",
                        formatter: _vm.currencyFormatter,
                        "header-align": "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Gadsden/Anniston",
                        width: "180",
                        "column-key": "2.amount",
                        prop: "2.amount",
                        formatter: _vm.currencyFormatter,
                        "header-align": "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Florence/Haleyville",
                        width: "180",
                        "column-key": "3.amount",
                        prop: "3.amount",
                        formatter: _vm.currencyFormatter,
                        "header-align": "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Huntsville/Decatur/Cullman",
                        width: "180",
                        "column-key": "4.amount",
                        prop: "4.amount",
                        formatter: _vm.currencyFormatter,
                        "header-align": "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Jackson/Vicksburg/Hattiesburg",
                        width: "180",
                        "column-key": "5.amount",
                        prop: "5.amount",
                        formatter: _vm.currencyFormatter,
                        "header-align": "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Mobile",
                        width: "180",
                        "column-key": "6.amount",
                        prop: "6.amount",
                        formatter: _vm.currencyFormatter,
                        "header-align": "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Birmingham",
                        width: "180",
                        "column-key": "8.amount",
                        prop: "8.amount",
                        formatter: _vm.currencyFormatter,
                        "header-align": "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Actions",
                        align: "center",
                        width: "230",
                        "class-name": "small-padding",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            var $index = ref.$index
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleUpdate(row, $index)
                                    },
                                  },
                                },
                                [_vm._v(" Edit ")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    slot: "reference",
                                    size: "mini",
                                    type: "danger",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.confirmDelete(row, $index)
                                    },
                                  },
                                  slot: "reference",
                                },
                                [_vm._v(" Delete ")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }