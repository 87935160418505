<template>
  <el-dialog
    width="650px"
    title="Enter ROI Cost"
    :visible.sync="dialogVisible"
    :center="true"
    :close-on-click-modal="false"
    :show-close="false"
  >
    <el-form ref="leadCostForm" :model="leadCostForm" label-width="auto" style="padding-left: 5px;padding-right: 5px;padding-top: 0px;padding-bottom: 0px;">
      <el-col
        :span="24"
      >
        <el-form-item
          prop="dateMonth"
          label="Period"
        >
          <el-date-picker
            v-model="leadCostForm.dateMonth"
            type="month"
            placeholder="Pick a month"
            value-format="yyyyMM"
            @change="getOverheadCosts"
          />
        </el-form-item>
        <el-form-item
          prop="selectedCostType"
          label="Cost Type"
        >
          <el-radio-group v-model="leadCostForm.selectedCostType" size="small" @change="radioChanged()">
            <el-radio-button label="Fixed" />
            <el-radio-button label="Variable" />
            <el-radio-button label="Overhead" />
          </el-radio-group>
        </el-form-item>

        <el-form-item
          v-if="leadCostForm.selectedCostType!=='Overhead'"
          prop="selectedLeadSource"
          label="Lead Source"
        >
          <lead-sources id="lead-source-select" :selected-lead-source-i-d="leadCostForm.selectedLeadSource" @update-selected-lead-source="onUpdateSelectedLeadSource" />
        </el-form-item>

        <template v-if="leadCostForm.selectedCostType!=='Overhead'">
          <el-row>
            <el-col :span="14">
              <el-form-item label="Birmingham" prop="birmingham.base">
                <el-input v-model="leadCostForm.birmingham.base" size="small">
                  <template slot="prepend" style="padding-left: 2px;padding-right: 2px">$</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="Vendor Fees" prop="birmingham.vendorFee">
                <el-input v-model="leadCostForm.birmingham.vendorFee" size="small">
                  <template slot="append" style="padding-left: 2px;padding-right: 2px">%</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="14">
              <el-form-item label="Florence" prop="florence.base">
                <el-input v-model="leadCostForm.florence.base" size="small">
                  <template slot="prepend" style="padding-left: 2px;padding-right: 2px">$</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="Vendor Fees" prop="florence.vendorFee">
                <el-input v-model="leadCostForm.florence.vendorFee" size="small">
                  <template slot="append" style="padding-left: 2px;padding-right: 2px">%</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="14">
              <el-form-item label="Gadsden" prop="gadsden.base">
                <el-input v-model="leadCostForm.gadsden.base" size="small">
                  <template slot="prepend" style="padding-left: 2px;padding-right: 2px">$</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="Vendor Fees" prop="gadsden.vendorFee">
                <el-input v-model="leadCostForm.gadsden.vendorFee" size="small">
                  <template slot="append" style="padding-left: 2px;padding-right: 2px">%</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="14">
              <el-form-item label="Huntsville" prop="huntsville.base">
                <el-input v-model="leadCostForm.huntsville.base" size="small">
                  <template slot="prepend" style="padding-left: 2px;padding-right: 2px">$</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="Vendor Fees" prop="huntsville.vendorFee">
                <el-input v-model="leadCostForm.huntsville.vendorFee" size="small">
                  <template slot="append" style="padding-left: 2px;padding-right: 2px">%</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="14">
              <el-form-item label="Mississippi" prop="mississippi.base">
                <el-input v-model="leadCostForm.mississippi.base" size="small">
                  <template slot="prepend" style="padding-left: 2px;padding-right: 2px">$</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="Vendor Fees" prop="mississippi.vendorFee">
                <el-input v-model="leadCostForm.mississippi.vendorFee" size="small">
                  <template slot="append" style="padding-left: 2px;padding-right: 2px">%</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="14">
              <el-form-item label="Mobile" prop="mobile.base">
                <el-input v-model="leadCostForm.mobile.base" size="small">
                  <template slot="prepend" style="padding-left: 2px;padding-right: 2px">$</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item prop="mobile.vendorFee" label="Vendor Fees">
                <el-input v-model="leadCostForm.mobile.vendorFee" size="small">
                  <template slot="append" style="padding-left: 2px;padding-right: 2px">%</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="14">
              <el-form-item label="Montgomery" prop="montgomery.base">
                <el-input v-model="leadCostForm.montgomery.base" size="small">
                  <template slot="prepend" style="padding-left: 2px;padding-right: 2px">$</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item prop="montgomery.vendorFee" label="Vendor Fees">
                <el-input v-model="leadCostForm.montgomery.vendorFee" size="small">
                  <template slot="append" style="padding-left: 2px;padding-right: 2px">%</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
        <template>
          <el-row>
            <el-col :span="14">
              <el-form-item v-if="leadCostForm.selectedCostType==='Overhead'" label="Overhead" prop="overhead">
                <el-input v-model="leadCostForm.overhead" size="small">
                  <template slot="prepend">$</template>
                </el-input>
              </el-form-item></el-col>
          </el-row>
        </template>
        <!-- overhead section -->
        <template v-if="leadCostForm.selectedCostType==='Overhead'">
          <el-row>
            <el-col :span="24">
              <el-transfer
                v-model="leadCostForm.overheadCategories"
                style="text-align: left; display: inline-block; padding-bottom:20px"
                :titles="['All Sources', 'Overhead']"
                :props="{
                  key: 'id_lead_source',
                  label: 'lead_source_name'
                }"
                :data="leadSources"
              />
            </el-col></el-row></template>

      </el-col>
      <!-- </el-row> -->
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeModal('leadCostForm')">Cancel</el-button>
      <!-- <el-button type="primary" @click="submitForm('leadCostForm')">Submit Lead Cost</el-button> -->
      <el-button type="primary" @click="submitForm('leadCostForm')">{{ (leadCostForm.selectedCostType==='Overhead') ? 'Submit Overhead' : 'Submit Lead Cost' }}</el-button>
    </span>
  </el-dialog>
</template>

<script>

import LeadSources from '@/components/LeadSources'
import {
  insertLeadCost, getLeadInfo
} from '@/api/lead-info'

export default {
  components: { LeadSources },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    existingRow: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      leadCostForm: {
        dateMonth: undefined,
        leadSource: undefined,
        selectedCostType: undefined,
        selectedLeadSource: undefined,
        birmingham: { id: 8, base: null, vendorFee: null, existingKey: null },
        florence: { id: 3, base: null, vendorFee: null, existingKey: null },
        gadsden: { id: 2, base: null, vendorFee: null, existingKey: null },
        huntsville: { id: 4, base: null, vendorFee: null, existingKey: null },
        mississippi: { id: 5, base: null, vendorFee: null, existingKey: null },
        mobile: { id: 6, base: null, vendorFee: null, existingKey: null },
        montgomery: { id: 1, base: null, vendorFee: null, existingKey: null },
        overhead: undefined,
        overheadCategories: []
      },
      leadSources: []
    }
  },
  watch: {
    existingRow(newValue, oldValue) {
      if (newValue.cost_period) {
      // console.log('cost period is not null', newValue.cost_period)
        this.setExistingFormData()
      }
    },
    dialogVisible(newValue) {
      if (newValue) {
        console.log('diag visible leadcostform', this.leadCostForm)
      }
    }
  },
  mounted: async function() {
    const res_lead_sources = await getLeadInfo('lead_sources')
    console.log('lead source data is: ', res_lead_sources.data)
    this.leadSources = res_lead_sources.data
  },
  methods: {
    capitalizeFirstLetter(lowercaseWord) {
      let capitalizedWord = null
      if (lowercaseWord) {
        capitalizedWord = lowercaseWord.charAt(0).toUpperCase() + lowercaseWord.slice(1)
      }
      return capitalizedWord
    },
    closeModal(formName) {
      this.resetForm(formName)
      this.resetExistingKeys()
      this.$emit('close')
    },
    async getOverheadCosts() {
      // query firm_overhead_costs
      var overheadCostQuery = `firm_overhead_costs?period=${this.leadCostForm.dateMonth}`
      const res_overhead_costs = await getLeadInfo(overheadCostQuery)
      if (res_overhead_costs) {
        const [overhead_costs] = res_overhead_costs?.data
        var overheadCategoryQuery
        if (overhead_costs) {
          const { id_firm_overhead_cost = null, overhead_cost_amount = null } = overhead_costs
          this.leadCostForm.overhead = overhead_cost_amount
          overheadCategoryQuery = `overhead_categories?id_firm_overhead_cost=${id_firm_overhead_cost}`
        }
        const res_overhead_categories = await getLeadInfo(overheadCategoryQuery)
        console.log('res_overhead_categories: ', res_overhead_categories)
        this.leadCostForm.overheadCategories = res_overhead_categories.data.map(a => a.id_lead_source)
      }
    },
    radioChanged() {
      if (this.leadCostForm.selectedCostType === 'Overhead') {
        this.getOverheadCosts()
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    resetExistingKeys() {
      this.leadCostForm.birmingham.existingKey = null
      this.leadCostForm.florence.existingKey = null
      this.leadCostForm.gadsden.existingKey = null
      this.leadCostForm.huntsville.existingKey = null
      this.leadCostForm.mississippi.existingKey = null
      this.leadCostForm.mobile.existingKey = null
      this.leadCostForm.montgomery.existingKey = null
    },
    onUpdateSelectedLeadSource(val) {
      this.leadCostForm.selectedLeadSource = val
    },
    notifyError(e) {
      this.$notify({
        title: 'Error Type',
        message: e,
        type: 'error',
        duration: 4000
      })
    },
    notifySuccess(msg) {
      this.$notify({
        title: 'Success',
        message: msg,
        type: 'success',
        duration: 4000
      })
    },
    setExistingFormData() {
      this.leadCostForm.dateMonth = this.existingRow.cost_period + ''
      // this.leadCostForm.leadSource = this.existingRow.lead_source_name
      this.leadCostForm.selectedCostType = this.capitalizeFirstLetter(this.existingRow.lead_cost_type)
      this.leadCostForm.selectedLeadSource = this.existingRow.id_lead_source
      this.leadCostForm.birmingham.base = this.existingRow['8'].amount
      this.leadCostForm.birmingham.vendorFee = this.existingRow['8'].vendor_fee_percentage
      this.leadCostForm.birmingham.existingKey = this.existingRow['8'].id_lead_cost
      this.leadCostForm.florence.base = this.existingRow['3'].amount
      this.leadCostForm.florence.vendorFee = this.existingRow['3'].vendor_fee_percentage
      this.leadCostForm.florence.existingKey = this.existingRow['3'].id_lead_cost
      this.leadCostForm.gadsden.base = this.existingRow['2'].amount
      this.leadCostForm.gadsden.vendorFee = this.existingRow['2'].vendor_fee_percentage
      this.leadCostForm.gadsden.existingKey = this.existingRow['2'].id_lead_cost
      this.leadCostForm.huntsville.base = this.existingRow['4'].amount
      this.leadCostForm.huntsville.vendorFee = this.existingRow['4'].vendor_fee_percentage
      this.leadCostForm.huntsville.existingKey = this.existingRow['4'].id_lead_cost
      this.leadCostForm.mississippi.base = this.existingRow['5'].amount
      this.leadCostForm.mississippi.vendorFee = this.existingRow['5'].vendor_fee_percentage
      this.leadCostForm.mississippi.existingKey = this.existingRow['5'].id_lead_cost
      this.leadCostForm.mobile.base = this.existingRow['6'].amount
      this.leadCostForm.mobile.vendorFee = this.existingRow['6'].vendor_fee_percentage
      this.leadCostForm.mobile.existingKey = this.existingRow['6'].id_lead_cost
      this.leadCostForm.montgomery.base = this.existingRow['1'].amount
      this.leadCostForm.montgomery.vendorFee = this.existingRow['1'].vendor_fee_percentage
      this.leadCostForm.montgomery.existingKey = this.existingRow['1'].id_lead_cost
    },
    async submitForm(formName) {
      console.log('form submitted')
      this.selectedCostIdentifier = this.selectedCostID
      var res = await insertLeadCost(this.leadCostForm)
      console.log('res', res)
      if (res.success) {
        this.notifySuccess('Lead Cost Successfully Created')
        this.closeModal(formName)
      } else {
        this.notifyError('Error Creating Lead Cost: ', res.error)
      }
    }

  }
}
</script>

<style >
.el-input-group__append, .el-input-group__prepend {
    background-color: #F5F7FA;
    color: #909399;
    vertical-align: middle;
    display: table-cell;
    position: relative;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    padding: 0 3px;
    width: 1px;
    white-space: nowrap;
}
</style>
