import request from '@/utils/request'
import requestLookup from '@/utils/request-lookup'

export function getClioItems(query) {
  return request({
    url: '/clio/api/v4/' + query,
    method: 'get'
  })
}

export function createClioItems(type, data) {
  return request({
    url: '/clio/api/v4/' + type,
    method: 'post',
    data
  })
}

export function patchClioItems(type, data) {
  return request({
    url: '/clio/api/v4/' + type,
    method: 'patch',
    data
  })
}

export function updateClioItems(data) {
  return request({
    url: '/clio/update',
    method: 'put',
    data
  })
}

export function deleteClioItems(data) {
  return request({
    url: '/clio/delete',
    method: 'delete',
    data
  })
}

export function tryFindExistingCaller(data) {
  return request({
    url: '/clio/existingcaller',
    method: 'post',
    data
  })
}

export function getPastClioData(data) {
  return request({
    url: '/clio/matters/getpastcliomatterdata',
    method: 'post',
    data
  })
}
