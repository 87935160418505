const state = {
  newAppointmentInformation: {},
  calendarScheduleState: ''
}

const mutations = {
  SET_NEW_APPOINTMENT_INFORMATION: (state, newAppointmentInformation) => {
    state.newAppointmentInformation = newAppointmentInformation
  },
  CLEAR_NEW_APPOINTMENT_INFORMATION: (state) => {
    state.newAppointmentInformation = {}
  },
  SET_CALENDAR_SCHEDULE_STATE: (state, calendarScheduleState) => {
    state.calendarScheduleState = calendarScheduleState
  },
  CLEAR_CALENDAR_SCHEDULE_STATE: (state) => {
    state.calendarScheduleState = ''
  }
}

const actions = {
  async setNewAppointmentInformation({ commit }, newAppointmentInformation) {
    commit('SET_NEW_APPOINTMENT_INFORMATION', newAppointmentInformation)
  },
  async clearNewAppointmentInformation({ commit }) {
    commit('CLEAR_NEW_APPOINTMENT_INFORMATION')
  },
  async setCalendarScheduleState({ commit }, calendarScheduleState) {
    commit('SET_RESCHEDULE_INFORMATION', calendarScheduleState)
  },
  async clearCalendarScheduleState({ commit }) {
    commit('CLEAR_RESCHEDULE_INFORMATION')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
