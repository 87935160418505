import request from '@/utils/request'

/**
 * @description gets clio accounts from database
 * @returns {*} {status, clio_accounts[{token_id, name}, ...]}
 */
export function getClioLinkedAccounts() {
  return request({
    url: '/clioIntegrations/account?type=all_linked',
    method: 'get'
  })
}

/**
 * @description checks the link status of an account, if its up or down
 * @param {*} token_id id of linked account
 * @returns account_link: 'up' or account_link: 'down'
 */
export function getAccountLinkStatus(token_id) {
  return request({
    url: `/clioIntegrations/account?type=link_status&token_id=${token_id}`,
    method: 'get'
  })
}

/**
 * @description gets a list of clio users for that account
 * @param {*} token_id
 * @returns clio_account_users: data
 */
export function getClioLinkedAccountUsers(token_id) {
  return request({
    url: `/clioIntegrations/account?type=clio_users&token_id=${token_id}`,
    method: 'get'
  })
}

/**
 * @description submits a new clio account display name
 * @param {*} data
 * @returns clio_users
 */
export function submitClioLinkedAccountName(data) {
  return request({
    url: '/clioIntegrations/account?type=submit_name',
    method: 'post',
    data: data
  })
}

/**
 * @description updates the access token in the database manually
 * @param {*} data { inserted_by, token_id }
 * @returns status
 */
export function refreshAccountLink(data) {
  return request({
    url: '/clioIntegrations/account?type=refresh_link',
    method: 'put',
    data: data
  })
}

/**
 * @description submit a name to change account display name
 * @param {*} data {clio_account: { token_id, account_name} inserted_by}
 * @returns status
 */
export function editClioLinkedAccountName(data) {
  return request({
    url: '/clioIntegrations/account',
    method: 'put',
    data
  })
}

/**
 * @description removes a clio account
 * @param {*} data {token_id, inserted_by}
 * @returns status
 */
export function removeClioLinkedAccount(data) {
  return request({
    url: '/clioIntegrations/account',
    method: 'delete',
    data
  })
}

/**
 * @description gets a list of all clio calendars associated with an account
 * @param {*} token_id
 * @returns
 */
export function getClioLinkedAccountCalendars(token_id) {
  return request({
    url: `/clioIntegrations/calendar?type=get_all_clio_calendars&token_id=${token_id}`,
    method: 'get'
  })
}

/**
 * @description gets a clio calendar name (the name appearing in clio)
 * @param {*} token_id
 * @returns
 */
export function getCalendarName(token_id) {
  return request({
    url: `/clioIntegrations/calendar?type=get_calendar_name&token_id=${token_id}`,
    method: 'get'
  })
}

/**
 * @description gets the auth uri to authorize a new account in legal merge
 * @returns
 */
export function getAuthUri() {
  return request({
    url: '/clioIntegrations/getAuthUri',
    method: 'get'
  })
}

