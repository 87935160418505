<template>
  <div>
    <div>
      <roi-form ref="roi_form" :dialog-visible="formDialogVisible" :existing-row="tempExistingRow" @close="closeFormDialog" />
    </div>
    <el-row style="margin-top: 15px">
      <el-col
        :span="24"
      ><div>
        <el-table
          id="analyticsTable"
          v-loading="listLoading"
          :data="rows"
          border
          :fit="false"
          stripe
          :max-height="800"
          :show-summary="false"
          highlight-current-row
          style="width: 100%"
        >

          <el-table-column
            label="Lead Source"
            width="180"
            column-key="lead_source_name"
            prop="lead_source_name"
            header-align="center"
          />
          <el-table-column
            label="Cost Type"
            width="180"
            column-key="lead_cost_type"
            prop="lead_cost_type"
            header-align="center"
          />
          <el-table-column
            label="Cost Period"
            width="180"
            column-key="cost_period"
            prop="cost_period"
            :formatter="dateFormatter"
            header-align="center"
          />
          <el-table-column
            label="Montgomery/Opelika"
            width="180"
            column-key="1.amount"
            prop="1.amount"
            :formatter="currencyFormatter"
            header-align="center"
          />
          <el-table-column
            label="Gadsden/Anniston"
            width="180"
            column-key="2.amount"
            prop="2.amount"
            :formatter="currencyFormatter"
            header-align="center"
          />
          <el-table-column
            label="Florence/Haleyville"
            width="180"
            column-key="3.amount"
            prop="3.amount"
            :formatter="currencyFormatter"
            header-align="center"
          />
          <el-table-column
            label="Huntsville/Decatur/Cullman"
            width="180"
            column-key="4.amount"
            prop="4.amount"
            :formatter="currencyFormatter"
            header-align="center"
          />
          <el-table-column
            label="Jackson/Vicksburg/Hattiesburg"
            width="180"
            column-key="5.amount"
            prop="5.amount"
            :formatter="currencyFormatter"
            header-align="center"
          />
          <el-table-column
            label="Mobile"
            width="180"
            column-key="6.amount"
            prop="6.amount"
            :formatter="currencyFormatter"
            header-align="center"
          />
          <el-table-column
            label="Birmingham"
            width="180"
            column-key="8.amount"
            prop="8.amount"
            :formatter="currencyFormatter"
            header-align="center"
          />
          <el-table-column label="Actions" align="center" width="230" class-name="small-padding">
            <template slot-scope="{row,$index}">
              <el-button type="primary" size="mini" @click="handleUpdate(row,$index)">
                Edit
              </el-button>
              <!-- <el-popconfirm
                confirm-button-text="OK"
                cancel-button-text="No"
                icon="el-icon-info"
                icon-color="red"
                title="Are you sure you want to delete cost?"
              > -->
              <el-button slot="reference" size="mini" type="danger" @click="confirmDelete(row,$index)">
                <!-- <el-button slot="reference" size="mini" type="danger" @click="handleDelete(row,$index)"> -->
                Delete
              </el-button>
              <!-- </el-popconfirm> -->
            </template>
          </el-table-column>
        </el-table></div></el-col></el-row>
  </div>
</template>

<script>
import {
  getListData
} from '@/api/appointmentresult'
import roiForm from '@/views/analytics-tabs/components/roi-entry/roi-form'
import XLSX from 'xlsx'
import { getLeadInfo, invalidateLeadCost } from '@/api/lead-info'
import alasql from 'alasql'
// import { DateTime } from 'luxon'
// import _ from 'lodash'

export default {
  clientId: undefined,
  name: 'ROIEntry',
  components: { roiForm },
  props: {
    dateFilter: {
      type: Array,
      default: undefined
    },
    selectedLocation: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      appointmentList: [],
      filterTypes: {
        locationFilter: [],
        resultFilter: []
      },
      formDialogVisible: false,
      leadSources: undefined,
      list: null,
      listLoading: false, // this should be set to true when the data is loaded
      reportSelected: 'roi_entry',
      rows: [],
      selectListLoading: false,
      tableFormatted: undefined,
      tempExistingRow: {}
    //  storeStuff: [],
      // locationOptions: [
      //   { loc_value: 1, loc_label: 'Montgomery' },
      //   { loc_value: 2, loc_label: 'Gadsden' },
      //   { loc_value: 3, loc_label: 'Florence' },
      //   { loc_value: 4, loc_label: 'Huntsville' },
      //   { loc_value: 5, loc_label: 'Mississippi' },
      //   { loc_value: 6, loc_label: 'Mobile' },
      //   { loc_value: 8, loc_label: 'Birmingham' }]
    }
  },
  watch: {
    dateFilter: function(newVal, oldVal) {
      console.log('dateFilter changed: ', newVal, ' | was: ', oldVal)
      console.log('call get lead info?')
      console.log('this datefilter', this.dateFilter)
      this.callGetLeadInfo()
    }
  },
  mounted: async function() {
    await this.callGetLeadInfo()
    console.log('roi-entry mounted')
  },
  methods: {
    createColumnFilterList() {
      var columnArray = this.columns

      var arrayObject = []
      for (var i = 0; i < columnArray.length; i++) {
        arrayObject.push({ id: columnArray[i], hidden: false })
      }
      this.columnFilters = arrayObject
    },
    addNewLine(updateExisting) {
      if (!updateExisting) {
        console.log('not update existing')
        this.tempExistingRow = {}
      }
      console.log('add new line')
      // this.$refs.roi_form.setFormData()
      this.formDialogVisible = true
    },
    closeFormDialog() {
      this.formDialogVisible = false
      this.callGetLeadInfo()
    },
    confirmDelete(row, index) {
      this.$confirm('Are you sure you want to delete this cost?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.handleDelete(row, index)
        // this.$message({
        //   type: 'success',
        //   message: 'Delete completed'
        // })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Delete canceled'
        })
      })
    },
    currencyFormatter(row, column, cellValue, index) {
      let returnValue = null
      if (cellValue) {
        const currencyFormatter = Intl.NumberFormat('en-US', {
          style: 'currency', currency: 'USD'
        })
        returnValue = currencyFormatter.format(cellValue)
      }
      return returnValue
    },
    async callGetLeadInfo() {
      this.listLoading = true
      const periodFrom = this.getPeriodFromDate(this.dateFilter[0])
      const periodTo = this.getPeriodFromDate(this.dateFilter[1])

      var query = `lead_costs?from=${periodFrom}&to=${periodTo}`

      const res_lead_costs = await getLeadInfo(query)

      const res_lead_cost_types = await getLeadInfo('lead_cost_types')

      alasql.options.cache = false

      // pivot lead_costs rows into columns for each location
      const lead_costs_pivot = alasql(
        'SELECT [id_lead_source], [id_office], [lead_cost_amount], [cost_period], [id_lead_cost_type] FROM ? PIVOT (SUM([lead_cost_amount]) FOR [id_office])',
        [res_lead_costs.data])

      var leadSourceData = await getListData([['dateBegin, dateEnd'], 'lead_sources', 'location', 'currentTime'])

      this.leadSources = leadSourceData.data

      // replace lead source ids with names
      // do the same with cost types

      const lead_costs_pivot_names = alasql(`SELECT a.*, (SELECT b.lead_source_name FROM ? b WHERE a.id_lead_source = b.id_lead_source) AS lead_source_name, c.lead_cost_type FROM ? a \ LEFT JOIN ? c ON c.id_lead_cost_type = a.id_lead_cost_type `, [leadSourceData.data, lead_costs_pivot, res_lead_cost_types.data])

      // recombine office data with cost pk and vendor fee pct
      lead_costs_pivot_names.map((x) => {
        const primaryKeyData = alasql(
          'SELECT * FROM ? a WHERE a.cost_period = ? AND a.id_lead_cost_type = ? AND a.id_lead_source = ?',
          [res_lead_costs.data, x.cost_period, x.id_lead_cost_type, x.id_lead_source])

        for (let i = 0; i < primaryKeyData.length; i++) {
          const pk_office_id = primaryKeyData[i]['id_office']

          x[pk_office_id] = {}
          x[pk_office_id]['amount'] = primaryKeyData[i]['lead_cost_amount']
          x[pk_office_id]['id_lead_cost'] = primaryKeyData[i]['id_lead_cost']
          x[pk_office_id]['vendor_fee_percentage'] = primaryKeyData[i]['vendor_fee_percentage']
        }
      })

      this.rows = lead_costs_pivot_names

      console.log('rows in table:', this.rows)

      this.listLoading = false
    },
    dateFormatter(row, column, cellValue, index) {
      let returnValue = null
      if (cellValue) {
        const dateString = '' + cellValue + ''
        const year = dateString.slice(0, 4)
        const month = dateString.slice(4)
        const date = new Date(Date.UTC(year, month))
        const options = { year: 'numeric', month: 'long' }
        returnValue = date.toLocaleDateString('en-US', options)
      }
      return returnValue
    },
    getPeriodFromDate(inputDate) {
      // update later by checking if inputDate is a date and then formatting the string accordingly
      inputDate = inputDate.replace(/-/g, '')
      const period = inputDate.substring(0, 6)

      return period
    },
    handleExport() {
      var excelExportData = XLSX.utils.json_to_sheet(this.rows)
      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new() // make Workbook of Excel
      XLSX.utils.book_append_sheet(wb, excelExportData, 'Lead Data') // sheetName is name of Worksheet
      XLSX.writeFile(wb, `${this.reportSelected}.xlsx`) // name of the file is 'Export.xlsx'
    },
    async handleDelete(row, $index) {
      console.log('row is: ', row)
      console.log('delete')
      var deleteLeadCosts = this.recursiveSearch(row, 'id_lead_cost')
      console.log('cost delete array is:', deleteLeadCosts)
      var res = await invalidateLeadCost(deleteLeadCosts)

      if (res.success) {
        this.notifySuccess('Lead Cost Successfully Removed')
        this.callGetLeadInfo()
      } else {
        this.notifyError('Error Removing Lead Cost: ', res.error)
      }
    },
    handleUpdate(row) {
      console.log('handle update row is:', row)
      this.tempExistingRow = row
      this.addNewLine(true)
    },
    notifyError(e) {
      this.$notify({
        title: 'Error Type',
        message: e,
        type: 'error',
        duration: 4000
      })
    },
    notifySuccess(msg) {
      this.$notify({
        title: 'Success',
        message: msg,
        type: 'success',
        duration: 4000
      })
    },
    recursiveSearch(obj, searchKey) {
      var results = []

      function recurs(obj1, searchKey1) {
        Object.keys(obj1).forEach(key => {
          const value = obj1[key]

          switch (true) {
            case key === searchKey1 && typeof value !== 'object':
              results.push(value)
              break
            case !value:
              break
            case typeof value === 'object':
              recurs(value, searchKey1)
              break
          }
        })
      }

      recurs(obj, searchKey)

      return results
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination-container {
  margin-top: 0px;
  padding: 15px 16px;
}

.analytics-filter-container {
  .filter-item {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }
  .export-button {
    vertical-align: middle;
    position: absolute;
    right: 0;
  }
}

.date-control-container {
  padding-top: 10px;
  padding-bottom: 10px;
}

.filter-item {
  width: 100%;
}

.chart-container {
  position: relative;
  width: 100%;
  height: calc(100vh - 84px);
}

.small-padding {
  .cell {
    padding-left: 5px;
    padding-right: 5px;
  }
}
</style>
