var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          ref: "feedbackForm",
          attrs: {
            model: _vm.feedbackForm,
            "label-position": "top",
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "Subject",
                prop: "subject",
                rules: [
                  {
                    required: true,
                    message: "Field required.",
                    trigger: "blur",
                  },
                ],
              },
            },
            [
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.feedbackForm.subject,
                      callback: function ($$v) {
                        _vm.$set(_vm.feedbackForm, "subject", $$v)
                      },
                      expression: "feedbackForm.subject",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "Feedback",
                prop: "feedback",
                rules: [
                  {
                    required: true,
                    message: "Field required.",
                    trigger: "blur",
                  },
                ],
              },
            },
            [
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", rows: 3 },
                    model: {
                      value: _vm.feedbackForm.feedback,
                      callback: function ($$v) {
                        _vm.$set(_vm.feedbackForm, "feedback", $$v)
                      },
                      expression: "feedbackForm.feedback",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { size: "large" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("feedbackForm")
                    },
                  },
                },
                [_vm._v(" Submit ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }