var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { title: "Loading", visible: _vm.loadingDialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.loadingDialogVisible = $event
            },
          },
        },
        [
          _c("el-progress", {
            attrs: {
              "text-inside": true,
              "stroke-width": 26,
              percentage: _vm.loadingProgressPercentage,
            },
          }),
          _c("p", [
            _c("span", [_vm._v(" " + _vm._s(_vm.loadingProgressText) + " ")]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.loadingDialogVisible = false
                    },
                  },
                },
                [_vm._v("Hide")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticStyle: { "margin-top": "15px" }, attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _c(
              "div",
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.listLoading,
                        expression: "listLoading",
                      },
                    ],
                    ref: "analyticsTable",
                    staticStyle: { width: "100%" },
                    attrs: {
                      id: "analyticsTable",
                      data: _vm.rows,
                      border: "",
                      fit: false,
                      stripe: "",
                      "show-summary": true,
                      "highlight-current-row": "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        label: "Lead Source",
                        width: "180",
                        "column-key": "lead_source",
                        prop: "lead_source",
                        "header-align": "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Lead Count",
                        width: "180",
                        "column-key": "lead_count",
                        prop: "lead_count",
                        "header-align": "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Disputed",
                        width: "180",
                        "column-key": "disputed_count",
                        prop: "disputed_count",
                        "header-align": "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Unusable",
                        width: "180",
                        "column-key": "unusable_count",
                        prop: "unusable_count",
                        "header-align": "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Set Opportunities",
                        width: "180",
                        "column-key": "set_opportunities",
                        prop: "set_opportunities",
                        "header-align": "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Reschedules",
                        width: "180",
                        "column-key": "reschedule_count",
                        prop: "reschedule_count",
                        "header-align": "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Total Sets",
                        width: "180",
                        "column-key": "total_sets",
                        prop: "total_sets",
                        "header-align": "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Set Rate",
                        width: "180",
                        "column-key": "set_percentage",
                        prop: "set_percentage",
                        "header-align": "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Adjusted Set Rate",
                        width: "180",
                        "column-key": "adjusted_set_rate",
                        prop: "adjusted_set_rate",
                        "header-align": "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "On Calendar",
                        width: "180",
                        "column-key": "appointments_on_calendar",
                        prop: "appointments_on_calendar",
                        "header-align": "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Shows",
                        width: "180",
                        "column-key": "total_shows",
                        prop: "total_shows",
                        "header-align": "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Show Rate",
                        width: "180",
                        "column-key": "show_rate",
                        prop: "show_rate",
                        "header-align": "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Retained",
                        width: "180",
                        "column-key": "total_retains",
                        prop: "total_retains",
                        "header-align": "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Retain Rate",
                        width: "180",
                        "column-key": "retain_rate",
                        prop: "retain_rate",
                        "header-align": "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Cost Per Lead",
                        width: "180",
                        "column-key": "cost_per_lead",
                        prop: "cost_per_lead",
                        formatter: _vm.currencyFormatter,
                        "header-align": "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Admin Cost",
                        width: "180",
                        "column-key": "admin_cost",
                        prop: "admin_cost",
                        formatter: _vm.currencyFormatter,
                        "header-align": "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Lead Spend",
                        width: "180",
                        "column-key": "lead_spend",
                        prop: "lead_spend",
                        formatter: _vm.currencyFormatter,
                        "header-align": "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Total Spent",
                        width: "180",
                        "column-key": "total_spent",
                        prop: "total_spent",
                        formatter: _vm.currencyFormatter,
                        "header-align": "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Cost Per Retain",
                        width: "180",
                        "column-key": "cost_per_retain",
                        prop: "cost_per_retain",
                        formatter: _vm.currencyFormatter,
                        "header-align": "center",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }