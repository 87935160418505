<template>
  <div>
    <el-row style="margin-top: 15px">
      <el-col
        :span="24"
      ><div>
        <el-table
          id="analyticsTable"
          v-loading="listLoading"
          :data="rows"
          border
          :fit="false"
          stripe
          :max-height="800"
          :show-summary="false"
          highlight-current-row
          style="width: 100%"
        >
          <el-table-column
            label="CC Rep"
            min-width="180"
            column-key="Rep"
            prop="Rep"
            header-align="center"
          />
          <el-table-column
            label="Total Sets"
            min-width="180"
            column-key="Appointment Total"
            prop="Appointment Total"
            header-align="center"
          />
          <el-table-column
            label="Shows"
            min-width="180"
            column-key="Shows"
            prop="Shows"
            header-align="center"
          />
          <el-table-column
            label="Show Rate"
            min-width="180"
            column-key="Show Rate"
            prop="Show Rate"
            header-align="center"
          />
          <el-table-column
            label="No Shows"
            min-width="180"
            column-key="No Shows"
            prop="No Shows"
            header-align="center"
          />
          <el-table-column
            label="No Show Rate"
            min-width="180"
            column-key="No Show Rate"
            prop="No Show Rate"
            header-align="center"
          />
          <el-table-column
            label="None"
            min-width="180"
            column-key="No Results"
            prop="No Results"
            header-align="center"
          />

        </el-table></div></el-col></el-row>
  </div>
</template>

<script>
import {
  getListData
} from '@/api/appointmentresult'
import XLSX from 'xlsx'
import alasql from 'alasql'
// import _ from 'lodash'

const today = new Date()

export default {
  clientId: undefined,
  name: 'CallCenterData',
  caller: '',
  components: {},
  props: {
    dateFilter: {
      type: Array,
      default: null
    },
    selectedLocation: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      appointmentList: [],
      displayDate: today,
      filterTypes: {
        locationFilter: [],
        resultFilter: []
      },
      list: null,
      listLoading: false,
      reportSelected: 'call_center_data',
      rows: [],
      selectListLoading: false
    }
  },
  computed: {
    columns: function columns() {
      if (this.rows.length === 0) {
        return []
      }
      return Object.keys(this.rows[0])
    }
  },
  watch: {
    dateFilter: function(newVal, oldVal) { // watch it
      // console.log('dateFilter changed: ', newVal, ' | was: ', oldVal)
      this.fetchRecordsRange()
    },
    selectedLocation: function(newVal, oldVal) { // watch it
      // console.log('location location location')
      // console.log('selectedLocation changed: ', newVal, ' | was: ', oldVal)
      this.fetchRecordsRange()
      // this.filterByLocation()
    }
  },
  mounted: async function() {
    await this.fetchRecordsRange()
    console.log('attorney-data mounted')
  },
  methods: {
    createColumnFilterList() {
      var columnArray = this.columns

      var arrayObject = []
      for (var i = 0; i < columnArray.length; i++) {
        arrayObject.push({ id: columnArray[i], hidden: false })
      }
      this.columnFilters = arrayObject
    },
    async fetchRecordsRange() {
      function mergeObjectsByKey(xs, prop) {
        var list = []

        for (var i = 0; i < xs.length; i++) {
          var p = xs[i][prop]

          const found = list.find(obj => { return obj[prop] === p })

          if (!found) {
            list.push(xs[i])
          } else {
            Object.assign(found, xs[i])
          }
        }

        return list
      }

      this.listLoading = true
      //  console.log('this.reportSelected ', this.reportSelected)
      var tableData = await getListData([this.dateFilter, this.reportSelected])
      // console.log('tableData is ', tableData)
      // console.log('tableData data is ', tableData.data)

      var groupedCallCenterData = alasql(`SELECT cc_appointment_creator as cc_user, 
                                        result,
                                        COUNT(*) result_count
                                        FROM ?
                                        GROUP BY cc_appointment_creator, result`, [tableData.data])

      // console.log('groupedCallCenterData is:', groupedCallCenterData)

      var resultsFormatted = groupedCallCenterData.map(function(x) {
        function splitUsername(username) {
          return username.split('@')[0]
        }
        x.cc_user = splitUsername(x.cc_user)
        return x
      })

      // console.log('resultsFormatted is:', resultsFormatted)
      var results_combined = resultsFormatted.map(({ result, result_count, cc_user }) => ({ cc_user, [result]: result_count }))

      // console.log('results_combined is:', results_combined)

      var userStatsMerged = mergeObjectsByKey(results_combined, 'cc_user')

      // console.log('double_combined is: ', userStatsMerged)

      var statsSummary = userStatsMerged.map(function(x) {
        const shows = [x['5'], x['6'], x['8'], x['9'], x['11'], x['12'], x['13'], x['14']]
        const noShows = [x['7'], x['4']]
        const noResult = [x['10'], x['3'], x['2'], x['1']]
        const addStuff = (a, b) => a + (b || 0)
        // console.log(x['11'])
        x.shows = shows.reduce(addStuff, 0)
        x.noShows = noShows.reduce(addStuff, 0)
        x.noResults = noResult.reduce(addStuff, 0)
        x.appointmentTotal = x.shows + x.noShows + x.noResults

        return x
      })

      // console.log('statsSummary is: ', statsSummary)

      var ccStatsSummary = alasql(`SELECT appointmentTotal AS [Appointment Total], 
                                        cc_user AS Rep,
                                        noResults AS [No Results],
                                        noShows AS [No Shows],
                                        shows AS [Shows],
                                        CONCAT(ROUND((noShows / appointmentTotal * 100), 2), '%') AS [No Show Rate],
                                        CONCAT(ROUND((shows / appointmentTotal * 100), 2), '%') AS [Show Rate]
                                        FROM ?`, [statsSummary])

      // console.log('statsSummary is: ', ccStatsSummary)

      const sortOrder = { 'Rep': 1, 'Appointment Total': 2, 'No Results': 3, 'No Shows': 4, 'No Show Rate': 5, 'Shows': 6, 'Show Rate': 7 }
      const ccStatsSorted = ccStatsSummary.map(o => Object.assign({}, ...Object.keys(o).sort((a, b) => sortOrder[a] - sortOrder[b]).map(x => { return { [x]: o[x] } })))

      if (ccStatsSorted) {
        this.rows = ccStatsSorted
      } else {
        this.rows = []
      }

      this.listLoading = false
    },
    handleExport() {
      var excelExportData = XLSX.utils.json_to_sheet(this.rows)
      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new() // make Workbook of Excel
      XLSX.utils.book_append_sheet(wb, excelExportData, 'Lead Data') // sheetName is name of Worksheet
      XLSX.writeFile(wb, `${this.reportSelected}.xlsx`) // name of the file is 'Export.xlsx'
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination-container {
  margin-top: 0px;
  padding: 15px 16px;
}

.analytics-filter-container {
  .filter-item {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }
  .export-button {
    vertical-align: middle;
    position: absolute;
    right: 0;
  }
}

.date-control-container {
  padding-top: 10px;
  padding-bottom: 10px;
}

.filter-item {
  width: 100%;
}

.chart-container {
  position: relative;
  width: 100%;
  height: calc(100vh - 84px);
}
</style>
