<template>
  <el-dialog
    element-loading-text="Submitting Account..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    title="Edit Account"
    :visible.sync="modalVisible"
    width="575px"
    :close-on-click-modal="false"
    @close="closeModal"
  >
    <el-form ref="accountToEdit" :model="accountToEdit" :rules="rules" label-width="auto">
      <el-form-item label="Account Name">
        <el-input
          v-model="accountToEdit.account_name"
          label="Account Name"
          type="text"
        />
      </el-form-item>
      <el-button type="primary" @click="refreshAccount(accountToEdit)">Reauthorize Account</el-button>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitEditedAccount(accountToEdit)">Submit</el-button>
      <el-button type="primary" @click="closeModal">Cancel</el-button>
    </span>
  </el-dialog>
</template>

<script>

import * as apiClioAdmin from '@/api/clio-admin'
import { mapGetters, mapState } from 'vuex'
import { responseValidator } from '@/utils/response-validator'

export default {
  name: 'ExternalAccountsEditClioAcct',
  props: {
    modalVisible: {
      type: Boolean,
      default: false
    },
    accountToEdit: {
      type: Object,
      default: () => { return { } }
    }
  },
  data() {
    return {
      data: '0',
      rules: {
        number: [
          {
            required: true,
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters([
      'name',
      'avatar',
      'roles'
    ])
  },
  created: async function() {},
  mounted: async function() {
  },
  methods: {
    // testing
    async testFunction(val) {
      console.log(val)
    },

    // submitters
    async submitEditedAccount(data) {
      try {
        const clio_linked_account = data
        const resp = await apiClioAdmin.submitClioLinkedAccountName({ clio_account: clio_linked_account, inserted_by: this.name })
        const validator = responseValidator(resp)
        if (!validator.success) throw validator
        this.$emit('close-and-refresh')
      } catch (e) {
        this.$notify.error({ title: 'Account Submission Error!', message: e.message ? e.message : 'Unable to submit data.' })
      }
    },

    async refreshAccount(data) {
      try {
        const resp = await apiClioAdmin.refreshAccountLink({
          token_id: data.token_id,
          inserted_by: this.name
        })
        const validator = responseValidator(resp)
        if (!validator.success) throw validator
        if (resp.status === 200) {
          this.$notify({ message: 'Account refresh successful.' })
        }
      } catch (e) {
        this.$notify.error({ title: 'Account Refresh Error!', message: e.message ? e.message : 'Unable to get data.' })
      }
    },

    // handling the mechanics of the modal
    async closeModal() {
      this.$emit('close-and-refresh')
    }
  }
}
</script>
<style lang="scss" scoped>
  .el-col {
    border-radius: 4px;
    margin-left: 20px;
  }
  .el-row {
    margin-bottom: 20px;
  }
  .text-input-label {
    display: inline-block;
    width: 200px;
    height: 36px;
    text-align: right;
    padding-top: 10px;
    padding-right: 20px;
  }

</style>
