import request from '@/utils/request'

/**
 * @description gets calendar attributes
 * @param {*} req body = { id_location_attributes_list, calendar_name, calendar_street_address, calendar_city, calendar_state, calendar_zip, calendar_directions, calendar_appointment_instructions, calendar_owners, token_id, clio_id_calendar, inserted_by}
 * @param {*} res { status, calendar_attributes_lists}
 */
export function getCalendarAttributesLists() {
  return request({
    url: '/calendar/attributes?type=get_all',
    method: 'get'
  })
}

/**
 * @description gets clio linked calendars
 * @param {*} req body = {token_id}
 * @param {*} res {status, clio_linked_calendars}
 */
export function getClioLinkedCalendars() {
  return request({
    url: `/calendar/attributes?type=clio_linked`,
    method: 'get'
  })
}

/**
 * @description gets Account Calendars From provided Linked Calendar clio id
 * @param {*} req body = { calendar_id_clio }
 * @param {*} res { success, message }
 */
export function getAccountCalendarsFromLinkedCalendar(calendar_id_clio) {
  return request({
    url: `/calendar/attributes?type=get_acct_from_cal_id&calendar_id_clio=${calendar_id_clio}`,
    method: 'get'
  })
}

/**
 * @description gets all consultation types for associated lm account
 * @param {*} req body = { id_location_attributes_list, calendar_name, calendar_street_address, calendar_city, calendar_state, calendar_zip, calendar_directions, calendar_appointment_instructions, calendar_owners, token_id, clio_id_calendar, inserted_by }
 * @param {*} res { status, consultation_types }
 */
export function getConsultationTypes() {
  return request({
    url: '/calendar/consultationTypes?type=get_all',
    method: 'get'
  })
}

/**
 * @description gets all consultation types for associated lm account
 * @param {*} req body = { id_location_attributes_list, calendar_name, calendar_street_address, calendar_city, calendar_state, calendar_zip, calendar_directions, calendar_appointment_instructions, calendar_owners, token_id, clio_id_calendar, inserted_by }
 * @param {*} res { status, consultation_types }
 */
export function getConsultationTypesById(consultationTypes) {
  const stringyArray = JSON.stringify(consultationTypes)
  const encodedArray = encodeURIComponent(stringyArray)
  return request({
    url: `/calendar/consultationTypes?type=get_by_id&ids=${encodedArray}`,
    method: 'get'
  })
}

/**
 * @description gets all serviced locations for lm account
 * @param {*} req
 * @param {*} res {status, serviced_locations}
 */
export function getAllServicedLocations() {
  return request({
    url: '/calendar/servicedLocations?type=get_all',
    method: 'get'
  })
}
