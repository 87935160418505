
<template>
  <el-dialog
    v-loading="loading"
    element-loading-text="Submitting Lead..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    title="Bad Lead"
    :visible.sync="dialogVisible"
    :show-close="showCloseButton"
    width="575px"
    :close-on-click-modal="closeOnClickModal"
    @close="closeModal"
  >
    <el-form ref="badLeadForm" :model="badLeadForm" :rules="rules" label-width="auto">

      <el-form-item label="Bad Lead Type" prop="badLeadType">
        <el-radio-group v-model="badLeadForm.badLeadType">
          <el-radio border label="U">Unusable</el-radio>
          <el-radio border label="D">Disputed</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="Category" prop="category" label-width="auto">
        <el-select
          v-model="badLeadForm.category"
          filterable
          placeholder="Select a Category"
          style="width=100%"
        >
          <el-option
            v-for="source in badLeadCategories"
            :key="source.value"
            :value="source.value"
            :label="source.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="Phone Number" prop="number">
        <el-input v-model="badLeadForm.number" size="medium" maxlength="10" />
      </el-form-item>

      <el-form-item label="Notes" prop="note">
        <el-input v-model="badLeadForm.note" type="textarea" maxlength="255" show-word-limit />
      </el-form-item>

    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="closeModal">Cancel</el-button>
      <el-button type="primary" @click="onSubmit('badLeadForm')">Submit Bad Lead</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import { addContactedLead } from '@/api/lmdbManagement'
import { validPhone } from '@/utils/validate'

export default {
  name: 'BadLeadDialog',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const validatePhoneNumber = (rule, value, callback) => {
      if (value) {
        if (validPhone(value)) {
          return callback()
        } else {
          return callback(
            new Error('Invalid phone. Valid format is XXXXXXXXXX 10 digits')
          )
        }
      } else {
        return callback(new Error('Field cannot be left blank.'))
      }
    }
    return {
      badLeadForm: {
        number: undefined,
        note: undefined,
        badLeadType: undefined,
        category: undefined
      },
      loading: false,
      rules: {
        number: [
          {
            required: true,
            validator: validatePhoneNumber,
            trigger: 'blur'
          }
        ],
        note: [
          {
            required: true,
            message: 'Note field cannot be left blank.',
            trigger: 'blur'
          }
        ],
        badLeadType: [
          {
            required: true,
            message: 'Bad Lead Type field cannot be left blank.',
            trigger: 'blur'
          }
        ],
        category: [
          {
            required: true,
            message: 'Category field cannot be left blank.',
            trigger: 'blur'
          }
        ]
      },
      showCloseButton: false,
      closeOnClickModal: false,
      badLeadCategories: [
        { value: 'Outside of Geographic area' },
        { value: 'Outside of standard services' },
        { value: 'Currently a client' },
        { value: 'Spam or Robodialer' },
        { value: 'Creditor' },
        { value: 'Less than 5k total debt' },
        { value: 'Test Lead' },
        { value: 'Trying to apply for a loan' },
        { value: 'Already has an attorney' },
        { value: 'Other' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'name'
    ])
  },
  methods: {
    closeModal() {
      this.resetForm('badLeadForm')
      this.$emit('close')
    },
    notifyError(msg) {
      this.$notify({
        title: 'Error',
        message: msg,
        type: 'error',
        duration: 4000
      })
    },
    notifySuccess(msg) {
      this.$notify({
        title: 'Success',
        message: msg,
        type: 'success',
        duration: 4000
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // call add bad contact method
          this.callAddContactedLead()
        } else {
          console.log('invalid submission')
          return false
        }
      })
    },
    async callAddContactedLead() {
      var contactedLeadData = {
        phone_number: this.badLeadForm.number,
        lead_quality: this.badLeadForm.badLeadType,
        inserted_by: this.name,
        lead_quality_note: this.badLeadForm.note,
        bad_lead_category: this.badLeadForm.category
      }
      try {
        this.loading = true
        var addLeadReply = await addContactedLead(contactedLeadData)
        this.loading = false
        if (addLeadReply) {
          this.notifySuccess('Bad Lead successfully submitted')
          console.log(addLeadReply)
          this.closeModal()
        }
      } catch (e) {
        if (this.loading === true) {
          this.loading = false
        }
        this.notifyError('Error submitting bad lead')
        console.log(e)
      }
    }
  }
}
</script>
      <style>
      </style>
