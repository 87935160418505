<template>
  <div>
    <el-row :gutter="20">

      <el-form
        :inline="true"
        style="padding-left: 30px;padding-right: 0px;padding-top: 0px;padding-bottom: 0px;"
      >
        <el-form-item>

          <el-date-picker
            v-model="dateFilter"
            size="mini"
            type="daterange"
            range-separator="To"
            start-placeholder="Start date"
            end-placeholder="End date"
            value-format="yyyy-MM-dd"
            @change="fetchTabRecords"
          />
        </el-form-item>

        <el-form-item>
          <el-select v-model="selectedLocation" size="mini" placeholder="Location" @change="filterByLocation">
            <el-option
              v-for="location in locationOptions"
              :key="location.id_connection_entity"
              :label="location.name"
              :value="location.id_connection_entity"
            />
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button size="mini" type="primary" icon="el-icon-document" @click="handleExport">
            Export
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-button v-if="activeTab=='roi_entry'" size="mini" icon="el-icon-edit" @click="addNewLine">
            Add New Lead Cost
          </el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <el-row>

      <div>
        <el-tabs v-model="activeTab" type="card" style="padding: 0px">
          <el-tab-pane label="Leads ROI" name="adwords_leads">
            <adwords v-if="activeTab=='adwords_leads'" ref="adwords_leads" :date-filter="dateFilter" :selected-location="selectedLocation" />
          </el-tab-pane>
          <el-tab-pane label="Attorney Stats" name="attorney_stats">
            <admin-analytics v-if="activeTab=='attorney_stats'" ref="attorney_stats" :date-filter="dateFilter" :selected-location="selectedLocation" />
          </el-tab-pane>
          <!-- <el-tab-pane label="All Leads" name="all_leads">
            <admin-analytics v-if="activeTab=='all_leads'" ref="all_leads" :date-filter="dateFilter" :selected-location="selectedLocation" />
          </el-tab-pane> -->
          <el-tab-pane label="ROI Cost Entry" name="roi_entry">
            <roi-entry v-if="activeTab=='roi_entry'" ref="roi_entry" :date-filter="dateFilter" :selected-location="selectedLocation" />
          </el-tab-pane>
          <el-tab-pane label="Unmarked" name="unmarked_leads">
            <unmarked-leads v-if="activeTab=='unmarked_leads'" ref="unmarked_leads" :date-filter="dateFilter" :selected-location="selectedLocation" />
          </el-tab-pane>
          <el-tab-pane label="CC Stats" name="call_center_data">
            <call-center-data v-if="activeTab=='call_center_data'" ref="call_center_data" :date-filter="dateFilter" :selected-location="selectedLocation" />
          </el-tab-pane>
          <el-tab-pane label="Lead Source Management" name="lead_source_management">
            <lead-source-management v-if="activeTab=='lead_source_management'" ref="lead_source_management" />
          </el-tab-pane>
        </el-tabs>
      </div>

    </el-row>
  </div>
</template>

<script>
// import adminAnalytics from '@/components/admin_analytics'
import adminAnalytics from '@/views/analytics-tabs/components/attorney-data'
import unmarkedLeads from '@/views/analytics-tabs/components/unmarked-leads'
import adwords from '@/views/analytics-tabs/components/analytics-adwords'
import leadSourceManagement from '@/views/analytics-tabs/components/lead-source-management'
import roiEntry from '@/views/analytics-tabs/components/roi-entry'
import callCenterData from '@/views/analytics-tabs/components/call-center-data'
import { DateTime } from 'luxon'
import * as apiClioAdmin from '@/api/clio-admin'
import { responseValidator } from '@/utils/response-validator'

// const today = new Date()
/* eslint-disable */
// var monthFirstDay = new Date(today.getFullYear(), today.getMonth(), 1)
var lastMonthStart = DateTime.now().minus({ months: 1 }).startOf('month').toFormat('yyyy-MM-dd')
var lastMonthEnd = DateTime.now().minus({ months: 1 }).endOf('month').toFormat('yyyy-MM-dd')

export default {
  name: 'AnalyticsTabs',
  components: { adminAnalytics, adwords, leadSourceManagement, unmarkedLeads, roiEntry, callCenterData },
  data() {
    return {
      calendarKey: 'id_location_attributes_list',
      activeTab: undefined,
      // dateFilter: [monthFirstDay, today],
      dateFilter: [lastMonthStart, lastMonthEnd],
      locationOptions: [
        { id_connection_entity: null, name: 'All Locations' }
      ],
      selectedLocation: null
    }
  },
  // computed: {
  //   defaultDateRange: function defaultDateRange() {
  //     const start = DateTime.now().minus({ months: 1 }).startOf('month').toFormat('yyyy-MM-dd')
  //     const end = DateTime.now().minus({ months: 1 }).endOf('month').toFormat('yyyy-MM-dd')
  //     return [start, end]
  //   }
  // },
  async mounted() {
    try {
        const resp = await apiClioAdmin.getClioLinkedAccounts()
        const validator = responseValidator(resp)
        if (!validator.success) throw validator
        var clioAccounts = resp.clio_accounts
        for (let i = 0; i < clioAccounts.length; i++) {
          this.locationOptions.push(clioAccounts[i])
        }
      } catch (e) {
        this.$notify.error({ title: 'Error!', message: e.message ? e.message : 'Unable to get data.' })
      }
  },
  methods: {
    placeholder() {
      null
    },
    fetchTabRecords() {
      // this.$refs[this.activeTab].fetchRecordsRange([this.dateFilter], this.selectedLocation)
      console.log('fetchTabRecords')
    },
    filterByLocation() {
      // this.$refs[this.activeTab].filterByLocation()
      console.log('filterByLocation')
    },
    handleExport() {
      this.$refs[this.activeTab].handleExport()
    },
    addNewLine() {
      this.$refs[this.activeTab].addNewLine(false)
    }
  }
}
</script>

<style scoped>
  .tab-container {
    margin: 30px;
  }

</style>
