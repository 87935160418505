export const attributesForm =
  {
    token_id: [
      { required: true, message: 'Please select an Account', trigger: 'blur' }
    ],
    calendar_id_clio: [
      { required: true, message: 'Please select a Clio Calendar', trigger: 'blur' }
    ],
    calendar_name: [
      { required: true, message: 'Please enter a Display Name', trigger: 'blur' }
    ],
    consultation_types: [
      { 'type': 'array',
        'required': true,
        'message': 'Please select at least one consultation type',
        'trigger': 'change',
        validator: (rule, value, callback) => {
          if (Array.isArray(value) && value.length > 0) {
            callback()
          } else {
            callback(new Error('Please select at least one call consultation type'))
          }
        }
      }
    ],
    id_questionnaireurl: [
      { required: true, message: 'Please select a questionnaire url.', trigger: 'blur' }
    ]
  }

export const editAttributesForm =
  {
    calendar_name: [
      { required: true, message: 'Please enter a Display Name', trigger: 'blur' }
    ],
    consultation_types: [
      { 'type': 'array',
        'required': true,
        'message': 'Please select at least one consultation type',
        'trigger': 'change',
        validator: (rule, value, callback) => {
          if (Array.isArray(value) && value.length > 0) {
            callback()
          } else {
            callback(new Error('Please select at least one call consultation type'))
          }
        }
      }
    ],
    calendar_street_address: [
      { required: true, message: 'Please enter street address.', trigger: 'blur' }
    ],
    calendar_city: [
      { required: true, message: 'Please enter city.', trigger: 'blur' }
    ],
    calendar_state: [
      { required: true, message: 'Please enter state.', trigger: 'blur' }
    ],
    calendar_zip: [
      { required: true, message: 'Please enter zip.', trigger: 'blur' }
    ]
  }

// examples of rules & requirements
// formRules: {
//   username: [
//     { required: true, message: 'Please enter a username', trigger: 'blur' },
//     { min: 3, max: 20, message: 'Username length should be between 3 and 20 characters', trigger: 'blur' },
//     { pattern: /^[a-zA-Z0-9_-]+$/, message: 'Username can only contain letters, numbers, underscores, and dashes', trigger: 'blur' },
//   ],
//   password: [
//     { required: true, message: 'Please enter a password', trigger: 'blur' },
//     { min: 6, message: 'Password should be at least 6 characters long', trigger: 'blur' },
//   ],
//   email: [
//     { required: true, message: 'Please enter an email address', trigger: 'blur' },
//     { type: 'email', message: 'Please enter a valid email address', trigger: ['blur', 'change'] },
//   ],
//   acceptTerms: [
//     { type: 'boolean', required: true, message: 'You must accept the terms and conditions', trigger: 'change' },
//   ],
// }
