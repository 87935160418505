import request from '@/utils/request'

export function sendSms(data) {
  return request({
    url: '/twilio/sendSms',
    method: 'post',
    data
  })
}

export function testUpdateSms(data) {
  return request({
    url: '/twilioDailyExecutions/appointmentReminders',
    method: 'post',
    data
  })
}

export function getRecords(data) {
  return request({
    url: '/twilio/getRecords',
    method: 'post',
    data
  })
}
