var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "app-container" }, [
      _c("h1", [_vm._v("Welcome to Legal Merge")]),
      _c("h3", [_vm._v(" Whats New: ")]),
      _c("aside", [
        _c("p", [
          _c("b", [_vm._v("5/17/2023 ")]),
          _c("br"),
          _c("br"),
          _vm._v(" Fixes "),
          _c("br"),
          _vm._v(
            " - Fixed an issue in admin settings where adding a calendar would fail on fields that aren't required."
          ),
          _c("br"),
          _vm._v(
            " - Added in admin settings validation to the edit/add calendar forms."
          ),
          _c("br"),
          _vm._v(
            " - Fixed an issue where questionnaire urls wouldn't get pulled properly in some cases."
          ),
          _c("br"),
          _vm._v(
            " - Fixed an issue in analytics where results weren't being sorted by location."
          ),
          _c("br"),
        ]),
        _c("p", [
          _c("b", [_vm._v("5/09/2023 ")]),
          _c("br"),
          _c("br"),
          _vm._v(" New Features "),
          _c("br"),
          _vm._v(" - Added clio account linking, status and management."),
          _c("br"),
          _vm._v(" - Added calendar linking and management."),
          _c("br"),
          _vm._v(
            " - Locked scheduling calendar selection to clio account of origin."
          ),
          _c("br"),
          _vm._v(" Fixes "),
          _c("br"),
          _vm._v(
            " - Fixed an issue where scheduling calendar would allow date/time selection for appointment before data populates causing a failed create."
          ),
          _c("br"),
        ]),
        _c("p", [
          _c("b", [_vm._v("8/09/2022 ")]),
          _c("br"),
          _c("br"),
          _vm._v(" Fixes "),
          _c("br"),
          _vm._v(" - Fixed some front-end issues on calendar."),
          _c("br"),
        ]),
        _c("p", [
          _c("b", [_vm._v("8/07/2022 ")]),
          _c("br"),
          _c("br"),
          _vm._v(" Updates "),
          _c("br"),
          _vm._v(" - Removed Anniston from Calendar select options."),
          _c("br"),
        ]),
        _c("p", [
          _c("b", [_vm._v("7/13/2022 ")]),
          _c("br"),
          _c("br"),
          _vm._v(" New Feature "),
          _c("br"),
          _vm._v(" - Call Center Appointment Results Page."),
          _c("br"),
        ]),
        _c("p", [
          _c("b", [_vm._v("6/21/2022 ")]),
          _c("br"),
          _c("br"),
          _vm._v(" Updates "),
          _c("br"),
          _vm._v(" - Security update."),
          _c("br"),
          _vm._v(" - Increased timeout to 20s "),
          _c("br"),
          _c("br"),
        ]),
        _c("p", [
          _c("b", [_vm._v("6/18/2022 ")]),
          _c("br"),
          _c("br"),
          _vm._v(" Fixes "),
          _c("br"),
          _vm._v(
            " - Fixed an issue with the messaging dashboard, should display properly now."
          ),
          _c("br"),
          _c("br"),
        ]),
        _c("p", [
          _c("b", [_vm._v("6/17/2022 ")]),
          _c("br"),
          _c("br"),
          _vm._v(" New Features "),
          _c("br"),
          _vm._v(
            " - Added Message Dashboard for message sending/receiving verification. Leads can be looked up by phone number. The dash will only report a maximum of 150 records, so care must be taken to restrict the date range to 1-3 days."
          ),
          _c("br"),
          _vm._v(" Updates "),
          _c("br"),
          _vm._v(
            " - The analytics dashboard in LegalMerge has been updated to include more robust leads reporting. It should now deliver all of the requested data. "
          ),
          _c("br"),
          _c("br"),
        ]),
        _c("p", [
          _c("b", [_vm._v("6/7/2022 ")]),
          _c("br"),
          _c("br"),
          _vm._v(" New Features "),
          _c("br"),
          _vm._v(" - Added analytics Appointment Results for firm admins."),
          _c("br"),
          _c("br"),
        ]),
        _c("p", [
          _c("b", [_vm._v("5/11/2022 ")]),
          _c("br"),
          _c("br"),
          _vm._v(" Fixes "),
          _c("br"),
          _vm._v(
            " - Fixed an issue with texting the wrong appointment time on first text. "
          ),
          _c("br"),
          _vm._v(
            " - Fixed an issue with emails erroring out at the Mobile office."
          ),
          _c("br"),
          _c("br"),
        ]),
        _c("p", [
          _c("b", [_vm._v("5/8/2022 ")]),
          _c("br"),
          _c("br"),
          _vm._v(" New Features "),
          _c("br"),
          _vm._v(" - Enabled the analytics for admins. "),
          _c("br"),
          _vm._v(" Changes: "),
          _c("br"),
          _vm._v(
            " - Swapped the create matter and client create button with the 'bad lead' report button "
          ),
          _c("br"),
          _vm._v(
            " - Doubled the number of characters able to be entered for notes. "
          ),
          _c("br"),
          _vm._v(" - Added an 'Other' category for call reason. "),
          _c("br"),
          _c("br"),
        ]),
        _c("p", [
          _c("b", [_vm._v("4/25/2022 ")]),
          _c("br"),
          _c("br"),
          _vm._v(" New Features: "),
          _c("br"),
          _vm._v(" - Added a phone number lookup to search existing clients. "),
          _c("br"),
          _vm._v(
            " - Added a contacted but no appointment set button & data entry. "
          ),
          _c("br"),
          _vm._v(" - Added a bad lead button & data entry. "),
          _c("br"),
          _vm._v(" - Added backend tracking for bad leads. "),
          _c("br"),
          _vm._v(
            " - Added backend tracking for leads that contact but don't set appointments. "
          ),
          _c("br"),
          _c("br"),
        ]),
        _c("p", [
          _c("b", [_vm._v("3/21/2022 ")]),
          _c("br"),
          _c("br"),
          _vm._v(" Fixes: "),
          _c("br"),
          _vm._v(
            " - Fixed an issue where appointment was being marked wrong in Legal Merge database."
          ),
          _c("br"),
          _c("br"),
          _vm._v(" Changes: "),
          _c("br"),
          _vm._v(
            " - Added a backend webhook refresher for clio (wont affect anything in the front end). "
          ),
          _c("br"),
        ]),
        _c("p", [
          _c("b", [_vm._v("3/17/2022 ")]),
          _c("br"),
          _c("br"),
          _vm._v(" Fixes: "),
          _c("br"),
          _vm._v(
            " - Added a notification message when a matter/contact is not found in our database on reschedule. "
          ),
          _c("br"),
          _vm._v(
            " - Fixed the hangup when matter/contact not found in database on reschedule."
          ),
          _c("br"),
          _c("br"),
        ]),
        _c("p", [
          _c("b", [_vm._v("3/1/2022 ")]),
          _c("br"),
          _c("br"),
          _vm._v(" New Features: "),
          _c("br"),
          _vm._v(
            " - Added a 'Whats New!' page to keep call center specialists informed about site changes! "
          ),
          _c("br"),
          _c("br"),
          _vm._v(" Fixes: "),
          _c("br"),
          _vm._v(
            " - Fixed error where calendar was showing up in appointment type. "
          ),
          _c("br"),
          _vm._v(
            ' - Fixed bug where client name would be presented as "last, first" on reschedule instead of "first last" format '
          ),
          _c("br"),
          _c("br"),
          _vm._v(" Changes: "),
          _c("br"),
          _vm._v(
            " - Moved 'CONSULTATION TYPE' up in the description for appointments for faster viewing, moved 'CAN TEXT' down. "
          ),
          _c("br"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }