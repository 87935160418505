/**
 *
 * @param {*} res any response
 * @returns {*} {status: string, message: string, success: bool} success is a fast flag to determine if an error should be thrown
 */

export function responseValidator(res) {
  console.log(res.status)
  switch (res.status) {
    case 200:
      return { status: '200 Success', message: 'Success', success: true }
    case 400:
      return { status: '400 Bad Request', message: 'Oops, looks like the server was unable to understand this request.', success: false }
    case 401:
      return { status: '401 Unauthorized', message: 'Oops, looks like your authentication has expired. Please log in again.', success: false }
    case 403:
      return { status: '403 Forbidden', message: 'Oops, looks like you do not have permissions to do this action.', success: false }
    case 404:
      return { status: '404 Not Found', message: 'Oops, looks like this resource does not exist.', success: false }
    case 500:
      return { status: '500 Internal Server Error', message: res.message ? res.message : 'Oops, looks like the server has encountered a situation it does not know how to handle.', success: false }
    case 502:
      return { status: '502 Bad Gateway', message: 'Oops, looks like our server is having trouble contacting another service.', success: false }
    case 504:
      return { status: '504 Gateway Timeout', message: 'Oops, looks like our server is having trouble contacting another service.', success: false }
    default:
      return { status: 'Unknown', message: 'Looks like something went wrong.', success: false }
  }
}
