<template>
  <div class="app-container">

    <el-dialog
      custom-class="wrong-calendar-dialog"
      :modal="true"
      :visible.sync="isCurrentCallerWrongCalendar"
      title="Wait!"
    >
      <p>You are about to add an appointment to a different calendar than selected on the intake form! The selected calendar was <span style="color: red;">{{ intakeSelectedCalendar }}</span>.</p>
      <p>Please review your selection before proceeding.</p>
      <el-button type="Primary" @click="isCurrentCallerWrongCalendar = false"> Close </el-button>
    </el-dialog>
    <!-- this is where the dialog popup when you click on the calendar lives -->

    <el-dialog
      v-el-drag-dialog
      custom-class="appointmentDialog"
      :show-close="true"
      :close-on-click-modal="false"
      :modal="false"
      :visible.sync="appointmentDialog.isVisible"
      @closed="onAppointmentDialogClosed('appointmentForm')"
      @dragDialog="handleDrag"
    >
      <div slot="title">
        <span v-if="showCurrentCallerButton" slot="label" style="width: 100% !important;">
          <el-tooltip style="padding-left:10px;" class="item" effect="dark" content="Use current caller's most recently created matter and contact information." placement="top">
            <el-button
              :disabled="applyMatterButtonDisabled"
              type="success"
              size="mini"
              @click="applyCallersMatter()"
            >Apply Current Caller's Matter</el-button>
          </el-tooltip>
        </span>
        <!-- <span v-else class="el-dialog__title">Read Only</span> -->
      </div>
      <el-form ref="appointmentForm" size="mini" :model="appointmentForm" :rules="appointmentDialog.rules" label-position="top" label-width="120px" :disabled="appointmentDialog.isReadOnly">
        <el-form-item label="Summary" prop="summary">
          <el-input v-model="appointmentForm.summary" :autosize="{ minRows: 1, maxRows: 8}" type="textarea" />
        </el-form-item>
        <el-form-item label="Description" prop="description">
          <el-input v-model="appointmentForm.description" :autosize="{ minRows: 4, maxRows: 8}" type="textarea" />
        </el-form-item>
        <el-row type="flex" justify="left" :gutter="20">
          <el-col :span="12">
            <el-form-item label="Matter" prop="matter">
              <el-select
                v-model="appointmentForm.matter"
                value-key="description"
                filterable
                remote
                reserve-keyword
                placeholder="Type in matter name"
                :remote-method="matterSelection"
                :loading="appointmentDialog.matterSelectionLoading"
                style="width: 100%"
                :disabled="appointmentDialog.isReadOnly"
                @change="onMatterOptionSelected"
              >
                <el-option
                  v-for="matter in appointmentDialog.matterSelectionOptions"
                  :key="matter.id"
                  :value="matter"
                  :label="matter.description"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="left" :gutter="20">
          <el-col :span="24">
            <el-form-item label="Date" prop="dateRange">
              <el-date-picker
                v-model="appointmentForm.dateRange"
                class="filter-item"
                type="datetimerange"
                format="MM/d/yyyy hh:mm:ss A"
                value-format="MM/d/yyyy hh:mm:ss A"
                align="right"
                :readonly="true"
                :unlink-panels="true"
                range-separator="To"
                start-placeholder="Start date"
                end-placeholder="End date"
                :clearable="false"
              />

            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="cancelAppointmentCreation('appointmentForm')">Close</el-button>

        <el-button v-show="!appointmentDialog.isReadOnly && !appointmentDialog.isEditing" :loading="loadingWhileCreatingClioObjects" size="mini" type="primary" :disabled="appointmentDialog.isReadOnly" @click="submitAppointmentCreation('appointmentForm')">Confirm</el-button>

        <el-popover
          v-model="appointmentPopoverVisible"
          placement="top"
          width="260"
        >

          <!-- <p>Are you sure to delete this?</p> -->
          <div style="text-align: center; margin: 0">
            <el-button-group>

              <el-button type="plain" size="mini" @click="setScheduleEditable('appointmentForm'); appointmentPopoverVisible = false">Edit</el-button>
              <el-button type="plain" size="mini" @click="appointmentPopoverVisible = false; appointmentRescheduleDialogVisible = true">Resched.</el-button>
              <el-button type="danger" size="mini" @click="appointmentPopoverVisible = false; appointmentCancelDialogVisible = true">Cancel</el-button>
            </el-button-group>
          </div>
          <el-button v-show="appointmentDialog.isReadOnly && !appointmentDialog.isEditing && (this.$store.getters.newAppointmentInformation.appointment_state !== 'create')" slot="reference" size="mini">Modify</el-button>
        </el-popover>

        <el-button v-show="!appointmentDialog.isReadOnly && appointmentDialog.isEditing" :loading="loadingWhileCreatingClioObjects" size="mini" type="success" :disabled="appointmentDialog.isReadOnly" @click="submitAppointmentPatch('appointmentForm');">Save Changes</el-button>

      </span>
    </el-dialog>

    <el-row>
      <el-col :span="24">
        <div class="date-control-container">
          <span>

            <el-button size="medium" icon="el-icon-arrow-left" circle @click="onClickNavi('prev')" />
            <el-button type="medium" icon="el-icon-arrow-right" circle @click="onClickNavi('next')" />
            <span class="render-range" style="margin-left: 10px; font-weight: bold">{{ dateRange }}</span>

            <div style="float: right; padding-left: 10px; width: 130px">
              <el-select v-model="selectedView">
                <el-option
                  v-for="(options, index) in viewModeOptions"
                  :key="index"
                  :value="options.value"
                  :label="options.title"
                >
                  {{ options.title }}
                </el-option>
              </el-select>
            </div>

            <div style="float: right; padding-left: 10px; width: 130px">
              <!-- <calendars
                id="calendar-select"
                custom-style="width: 100%"
                :selected-calendar-id="selectedCalendar.calendar_id_clio"
                @update-selected-calendar="onUpdateSelectedCalendar"
              /> -->
              <el-select
                v-model="clioCalendar"
                placeholder="Select"
                :value-key="calendarKey"
                @change="onUpdateSelectedCalendar"
              >
                <el-option
                  v-for="calendar, index in clioCalendars"
                  :key="index"
                  :label="calendar.calendar_name"
                  :value="calendar"
                />
              </el-select>
            </div>

            <div style="float: right; padding-left: 5px">
              <el-button v-show="selectedCalendar.calendar_id_clio > -1 && !appointmentDialog.isVisible" type="success" icon="el-icon-refresh" @click="getList(), calendarLoading = true">Refresh</el-button>
            </div>

            <div style="float: right; padding-left: 5px">
              <el-button v-show="selectedCalendar.calendar_id_clio > -1 && !appointmentDialog.isVisible" type="danger" icon="el-icon-phone-outline" @click="hotTransfer()">Hot Transfer</el-button>
            </div>

            <div v-if="this.$store.getters.newAppointmentInformation.appointment_state === 'create'" style="float: right; padding-left: 10px">
              <el-tooltip content="The caller that just had a matter created on the New Client form. " placement="bottom" effect="dark">
                <el-tag size="x-large" style="height: 36px; line-height: 34px" effect="plain" type="success"><b>Caller:</b> {{ currentCallerName }} <b>Location:</b> {{ currentCallerCalendarName }}</el-tag>
              </el-tooltip>
              <!-- <el-button type="primary" @click="test">test</el-button> -->
            </div>
          </span>
        </div>
        <calendar
          ref="tuiCal"
          v-loading="selectedCalendar.calendar_id_clio == -1 || calendarLoading"
          :element-loading-text="listLoadingText"
          element-loading-spinner="none"
          element-loading-background="rgba(0, 0, 0, 0.2)"
          element-loading-custom-class="calendar-loading-custom"
          style="height: 800px"
          :use-detail-popup="useDetailPopup"
          :use-creation-popup="useCreationPopup"
          :view="selectedView"
          :calendars="calendarList"
          :schedules="scheduleList"
          :theme="theme"
          :template="template"
          :task-view="false"
          :schedule-view="true"
          :month="month"
          :week="week"
          :disable-dbl-click="disableDblClick"
          :is-read-only="isReadOnly"
          @clickSchedule="onClickSchedule"
          @clickDayname="onClickDayname"
          @beforeCreateSchedule="onBeforeCreateSchedule"
          @beforeDeleteSchedule="onBeforeDeleteSchedule"
          @afterRenderSchedule="onAfterRenderSchedule"
          @beforeUpdateSchedule="onBeforeUpdateSchedule"
          @clickTimezonesCollapseBtn="onClickTimezonesCollapseBtn"
        />

        <!-- Appointment Reschedule Confirmation Dialog -->
        <el-dialog
          title="Reschedule Appointment"
          :visible.sync="appointmentRescheduleDialogVisible"
          width="30%"
          center
        >
          <span>Are you sure you want to Reschedule this appointment?</span>
          <span slot="footer" class="dialog-footer">
            <el-button type="plain" @click="appointmentRescheduleDialogVisible = false">No</el-button>
            <el-button type="primary" @click="appointmentRescheduleDialogVisible = false, appointmentDialog.isVisible = false, appointmentReschedule()">Yes</el-button>
          </span>
        </el-dialog>

        <!-- Appointment Cancel Confirmation Dialog -->
        <el-dialog
          title="Cancel Appointment"
          :visible.sync="appointmentCancelDialogVisible"
          width="30%"
          center
        >
          <span>Are you sure you want to Cancel this appointment?</span>
          <span slot="footer" class="dialog-footer">
            <el-button type="plain" @click="appointmentCancelDialogVisible = false">No</el-button>
            <el-button type="primary" @click="appointmentCancelDialogVisible = false, appointmentDialog.isVisible = false, appointmentCancel()">Yes</el-button>
          </span>
        </el-dialog>

        <el-dialog
          title="Appointment Date Passed"
          :visible.sync="pastApptDialog"
          width="30%"
          center
        >
          <span>The appointment time and date you've chosen has already passed</span>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="pastApptDialog = false">Close</el-button>
          </span>
        </el-dialog>

        <!-- Popup dialog to remind user to select a calendar -->
        <el-dialog
          title="Send Text?"
          :visible.sync="textConfirmDialog.isVisible"

          width="30%"
          center
        >
          <span>Send confirmation text to client?</span>
          <span slot="footer" class="dialog-footer">
            <el-button @click="textConfirmDialog.isVisible = false, cancelSendConfirmationText()">Close</el-button>
            <el-button :loading="textConfirmDialogLoading" type="primary" @click="sendConfirmationText()">Send</el-button>
          </span>
        </el-dialog>
      </el-col>
    </el-row>
  </div>

</template>
<script>
import { fetchList } from '@/api/event'
import 'tui-calendar/dist/tui-calendar.css'
import Calendar from '@toast-ui/vue-calendar/src/Calendar.vue'
import moment from 'moment-timezone'
import { mapGetters } from 'vuex'
import { getClioItems, createClioItems, patchClioItems } from '@/api/clio'
import './app.css'
import elDragDialog from '@/directive/el-drag-dialog' // base on element-ui
import { sendSms } from '@/api/twilio'
import { addappointment, getMatterClientInfo, updateAppointmentResult, getClientTextingInfo } from '@/api/lmdbManagement'
import { confirmationEmail } from '@/api/send-grid'
import * as apiCalendars from '@/api/calendar-management'
import { responseValidator } from '@/utils/response-validator'

import myTheme from './myTheme'

export default {
  name: 'App',
  directives: { elDragDialog },
  components: {
    'calendar': Calendar
  },
  data() {
    return {
      clioCalendar: {},
      clioCalendars: [],
      calendarKey: 'id_location_attributes_list',
      listLoadingText: 'Please select a calendar.',
      calendarLoading: true,
      viewModeOptions: [
        {
          title: 'Monthly',
          value: 'month'
        },
        {
          title: 'Weekly',
          value: 'week'
        },
        {
          title: 'Daily',
          value: 'day'
        }
      ],
      dateRange: '',
      selectedView: 'week',
      selectedAppointmentType: undefined,
      calendarList: [
        {
          id: '0',
          name: 'Firm',
          bgColor: '#cfb0ff',
          borderColor: '#9e5fff'
        },
        {
          id: '1',
          name: '1st',
          bgColor: '#bdf8ff',
          borderColor: '#19e7ff'
        },
        {
          id: '2',
          name: '2nd',
          bgColor: '#99b1ff',
          borderColor: '#3363ff'
        },
        {
          id: '3',
          name: '3rd',
          bgColor: '#ff96aa',
          borderColor: '#ff0031'
        },
        {
          id: '4',
          name: '1-2',
          bgColor: '#a8ffb5',
          borderColor: '#00ff26'
        },
        {
          id: '5',
          name: 'Discuss',
          bgColor: '#bababa',
          borderColor: '#575757'
        }
      ],
      scheduleList: [
      ],
      timezones: [{
        timezoneOffset: 540,
        displayLabel: 'GMT+09:00',
        tooltip: 'Seoul'
      },
      {
        timezoneOffset: -420,
        displayLabel: 'GMT-08:00',
        tooltip: 'Los Angeles'
      }],
      theme: myTheme,
      template: {
        milestone(schedule) {
          return `<span style="color:#fff;background-color: ${schedule.bgColor};">${schedule.title}</span>`
        },
        milestoneTitle() {
          return 'Milestone'
        },
        allday(schedule) {
          return `${schedule.title}<i class="fa fa-refresh"></i>`
        },
        alldayTitle() {
          return 'All Day'
        }
      },
      month: {
        startDayOfWeek: 0
      },
      week: {
        taskView: true,
        scheduleView: true,
        showTimezoneCollapseButton: false,
        timezonesCollapsed: false
      },
      taskView: true,
      scheduleView: true,
      useDetailPopup: false,
      useCreationPopup: false,
      disableDblClick: true,
      isReadOnly: false,
      appointmentPopoverVisible: false,
      appointmentRescheduleDialogVisible: false,
      appointmentCancelDialogVisible: false,
      appointmentDialog: {
        rules: {
          summary: [
            { required: true, message: 'Field cannot be left blank.', trigger: 'blur' }
          ],
          description: [
            { required: true, message: 'Field cannot be left blank.', trigger: 'blur' }
          ],
          type: [
            { required: true, message: 'Please select an appointment type.', trigger: 'change' }
          ],
          matter: [
            { required: true, message: 'Please select a matter', trigger: 'change' }
          ],
          dateRange: [
            { required: false }
            // { required: true, validator: validateDate, trigger: 'blur' } //2021-07-10 Testing to see if appt will create even if it doesn't show here
          ]
        },
        isVisible: false,
        matterSelectionLoading: false,
        matterSelectionOptions: [],
        isReadOnly: false,
        isEditing: false
      },
      appointmentForm: {
        summary: undefined,
        description: undefined,
        type: undefined,
        matter: {
          description: undefined,
          display_number: undefined,
          id: undefined,
          client: {
            id: undefined,
            canText: '',
            clientNumber: '',
            clientEmail: undefined
          },
          dateRange: undefined,
          appointmentTime: '',
          appointmentId: undefined
        }
      },
      textConfirmDialogLoading: false,
      textConfirmDialog: {
        isVisible: false
      },
      currentCallerName: '',
      currentCallerCalendarName: '',
      selectedLocation: undefined,
      selectedCalendar: {
        calendar_id_clio: '-1'
      },
      intakeSelectedCalendar: '',
      isCurrentCallerWrongCalendar: false,
      loadingWhileCreatingClioObjects: false,
      remindCalendarDialog: false,
      calendarButtonVisible: false,
      pastApptDialog: false,
      lmdbAppointmentForm: {
        appointment_id_clio: undefined,
        matter_id_clio: undefined,
        contact_id_clio: undefined,
        appointmentType: undefined,
        appointmentresulttypes_id_result_type: undefined,
        start_time: undefined,
        end_time: undefined,
        insertedBy: undefined,
        locations_id_calendar: undefined,
        can_text: undefined,
        phone_number: undefined,
        email_address: undefined
      },
      guideRef: undefined
    }
  },
  computed: {
    ...mapGetters([
      'name',
      'avatar',
      'roles'
    ]),
    showCurrentCallerButton() {
      const newAppointmentInformation = this.$store.getters.newAppointmentInformation
      var showCurrentCallerButton = false
      if (newAppointmentInformation.appointment_state === 'create' && !this.appointmentDialog.isReadOnly) {
        showCurrentCallerButton = true
      } else if (!this.appointmentDialog.isReadOnly) {
        showCurrentCallerButton = true
      }
      return showCurrentCallerButton
    },
    currentCallerWrongCalendar() {
      const newAppointmentInformation = this.$store.getters.newAppointmentInformation
      if (this.selectedCalendar.calendar_id_clio !== newAppointmentInformation.calendar_clio_id) {
        return true
      } else {
        return false
      }
    },
    applyMatterButtonDisabled() {
      const newAppointmentInformation = this.$store.getters.newAppointmentInformation
      if (newAppointmentInformation.calendar_clio_id) {
        return false
      } else {
        return true
      }
    }
  },
  watch: {
    selectedView(newValue) {
      this.$refs.tuiCal.invoke('changeView', newValue, true)
      this.setRenderRangeText()
    },
    selectedCalendar(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getList()
      }
    },
    currentCallerWrongCalendar() {
      try {
        this.intakeSelectedCalendar = this.$store.getters.newAppointmentInformation.office_location.calendar_name
      } catch (e) {
        this.intakeSelectedCalendar = ''
      }
    }
  },
  async mounted() {
    var newAppointmentInformation = {}
    if (this.$store.getters.newAppointmentInformation.matter !== null) {
      newAppointmentInformation = this.$store.getters.newAppointmentInformation
    }
    await this.setRenderRangeText()
    if (newAppointmentInformation.summary) {
      this.currentCallerName = newAppointmentInformation.summary
    }
    if (newAppointmentInformation.calendar_name) {
      this.currentCallerCalendarName = newAppointmentInformation.calendar_name
    }
    try {
      const resp = await apiCalendars.getClioLinkedCalendars()
      const validator = responseValidator(resp)
      if (!validator.success) throw resp
      if (this.$store.getters.newAppointmentInformation.office_id) {
        this.clioCalendars = resp.calendar_attributes_lists.filter(cal => cal.id_connection_entity === this.$store.getters.newAppointmentInformation.office_id)
      } else {
        this.clioCalendars = resp.calendar_attributes_lists
      }
    } catch (e) {
      this.$notify.error({ title: 'Oops!', message: 'Unable to retrieve calendar list.' })
    }
  },
  methods: {
    async fetchCalendars() {
      console.log('fetching calendars...')
      console.log(this.$store.getters.newAppointmentInformation)
      try {
        const resp = await apiCalendars.getClioLinkedCalendars()
        const validator = responseValidator(resp)
        if (!validator.success) throw resp
        if (this.$store.getters.newAppointmentInformation.office_id) {
          this.clioCalendars = resp.calendar_attributes_lists.filter(cal => cal.id_connection_entity === this.$store.getters.newAppointmentInformation.office_id)
        } else {
          this.clioCalendars = resp.calendar_attributes_lists
        }
      } catch (e) {
        this.$notify.error({ title: 'Oops!', message: 'Unable to retrieve calendar list.' })
      }
    },
    checkCurrentCallerWrongCalendar() {
      const newAppointmentInformation = this.$store.getters.newAppointmentInformation
      if (this.selectedCalendar.calendar_id_clio !== newAppointmentInformation.calendar_clio_id) {
        return true
      } else {
        return false
      }
    },
    async test() {
      console.log('appt info', this.$store.getters.newAppointmentInformation)
    },
    cancelSendConfirmationText() {
      this.customResetForms()
      this.$store.dispatch('scheduling/clearNewAppointmentInformation')
    },
    async onMatterOptionSelected(selectedMatter) {
      // set the client information to the form, and get the stuff needed to email/text
      this.appointmentForm.matter.client.clientNumber = selectedMatter.client.primary_phone_number
      this.appointmentForm.matter.client.clientEmail = selectedMatter.client.primary_email_address
      var postBodyTextInfo = {
        contact_id_clio: selectedMatter.client.id
      }
      // get texting requirements
      var clientTextingInfo = await getClientTextingInfo(postBodyTextInfo)
      this.appointmentForm.matter.client.canText = clientTextingInfo[0].can_text
      // appointmentInformation.appointmentForm.matter.client.canText = clientTextingInfo[0].can_text
      // setup description
      this.appointmentForm.description =
        'PHONE: ' + this.appointmentForm.matter.client.clientNumber + '\n\n' +
        'EMAIL: ' + this.appointmentForm.matter.client.clientEmail + '\n\n' +
        'CREATED BY: ' + this.name + '\n\n'
    },
    notifyError(e) {
      this.$notify({
        title: 'Error Type',
        message: e,
        type: 'error',
        duration: 4000
      })
    },
    notifySuccess(msg) {
      this.$notify({
        title: 'Success',
        message: msg,
        type: 'success',
        duration: 4000
      })
    },
    async appointmentCancel() {
      // set flags that indicate canceling is happening
      this.loadingWhileCreatingClioObjects = true
      // client data from clio is loaded in automatically
      // going to just use the appointment form
      var postBody = {
        data: {
          id: this.appointmentForm.appointmentId,
          summary: this.addAppointmentPrefix('CANCELED', this.appointmentForm.summary),
          description: this.appointmentForm.description,
          start: this.appointmentForm.dateRange[0],
          end: this.appointmentForm.dateRange[1],
          etag: this.appointmentForm.etag,
          calendar_owner: {
            id: this.selectedCalendar.calendar_id_clio
          }
        }
      }
      // need to send the updated appointment summary & status to clio
      var appointment = await this.rescheduleExistingAppointment(postBody).catch((e) => { this.notifyError(e) })
      // wtf
      if (appointment === 'Error') {
        this.notifyError('Error rescheduling appointment')
        this.loadingWhileCreatingClioObjects = false
        return 0
      }
      // need to send the updated appointment status to lmdb
      var bodyData = {
        appointment_id_clio: appointment.data.id,
        appointmentresulttypes_id_result_type: 1,
        inserted_by: this.name
      }
      await updateAppointmentResult(bodyData).catch((e) => { this.notifyError(e) })
      // refresh the calendar
      this.fetchCalendars()
      this.getList()
      // allow
      this.loadingWhileCreatingClioObjects = false
    },
    async appointmentReschedule() {
      // client data from clio is loaded in automatically
      // populate the form data variables to the 'apply current client data' button
      var newAppointmentInformation = JSON.parse(JSON.stringify(this.appointmentForm))
      newAppointmentInformation.matter.display_number = this.appointmentForm.matter.id
      newAppointmentInformation.calendar_clio_id = this.selectedCalendar.calendar_id_clio
      newAppointmentInformation.calendar_name = this.selectedCalendar.name
      newAppointmentInformation.id_connection_entity = this.selectedCalendar.id_connection_entity
      // set flags that indicate rescheduling is happening
      this.calendarLoading = true
      try {
        const resp = await apiCalendars.getAccountCalendarsFromLinkedCalendar(newAppointmentInformation.calendar_clio_id)
        const validator = responseValidator(resp)
        if (!validator.success) throw validator
        this.clioCalendars = resp.clio_linked_calendars
      } catch (e) {
        this.$notify.error({ title: 'Oops!', message: 'Unable to retrieve calendar list.' })
      }
      // get client texting info from database
      var postBodyTextInfo = {
        contact_id_clio: newAppointmentInformation.matter.client.id
      }

      var canTextRes = null
      try {
        canTextRes = await getClientTextingInfo(postBodyTextInfo)
        // eslint-disable-next-line require-atomic-updates
        newAppointmentInformation.matter.client.canText = canTextRes[0].can_text
        // eslint-disable-next-line require-atomic-updates
        newAppointmentInformation.matter.client.clientNumber = canTextRes[0].phone_number
      } catch (e) {
        this.notifyError('Error acquiring client texting information.')
      }

      // get client information from clio
      var query = `matters?clientoffice=${this.selectedCalendar.id_connection_entity}&order=display_number&matter_id=${newAppointmentInformation.matter.display_number}&client_id=${newAppointmentInformation.matter.client.id}&limit=1&status=open,pending&fields=id,etag,client%7Bid%2Cprimary_email_address%7D`

      await getClioItems(query).then(response => {
        if (response?.data) {
          newAppointmentInformation.matter.client.clientEmail = response.data[0].client.primary_email_address
        }
      }).catch((e) => { this.notifyError(e) })
      // going to just use the appointment form
      var postBody = {
        data: {
          id: newAppointmentInformation.appointmentId,
          summary: this.addAppointmentPrefix('RESCHEDULED', newAppointmentInformation.summary),
          description: newAppointmentInformation.description,
          start: newAppointmentInformation.dateRange[0],
          end: newAppointmentInformation.dateRange[1],
          etag: newAppointmentInformation.etag,
          calendar_owner: {
            id: this.selectedCalendar.calendar_id_clio
          }
        }
      }
      // need to send the updated appointment summary & status to clio
      try {
        var appointment = await this.rescheduleExistingAppointment(postBody).catch((e) => { this.notifyError(e) })
      } catch (e) {
        this.$notify.error({ title: 'Oops!', message: 'Unable to update appointment' })
        this.calendarLoading = false
        return 0
      }
      // need to send the updated appointment status to lmdb
      var bodyData = {
        appointment_id_clio: appointment.data.id,
        appointmentresulttypes_id_result_type: 2,
        inserted_by: this.name
      }
      try {
        await updateAppointmentResult(bodyData).catch((e) => { this.notifyError(e) })
      } catch (e) {
        this.$notify.error({ title: 'Oops!', message: 'Unable to update appointment' })
      }
      // refresh the calendar
      this.getList()
      // eslint-disable-next-line require-atomic-updates
      newAppointmentInformation.appointment_state = 'create'
      // commit the new appointment information to the store for later use
      this.$store.dispatch('scheduling/setNewAppointmentInformation', newAppointmentInformation)
      if (newAppointmentInformation.matter) {
        this.currentCallerName = newAppointmentInformation.summary
      }
      if (newAppointmentInformation.calendar_name) {
        this.currentCallerCalendarName = newAppointmentInformation.calendar_name
      }
      this.calendarLoading = false
    },
    addAppointmentPrefix(type, str) {
      var newstr
      if (type === 'RESCHEDULED') {
        if (str.match(/\[(.+?)\]/)) {
          newstr = str.replace(/\[(.+?)\]/g, '[' + 'RESCHEDULED' + ']')
        } else {
          newstr = '[RESCHEDULED] ' + str
        }
      } else if (type === 'CANCELED' || str.match(/\[(.+?)\]/)) {
        if (str.match(/\[(.+?)\]/)) {
          newstr = str.replace(/\[(.+?)\]/g, '[' + 'CANCELED' + ']')
        } else {
          newstr = '[CANCELED] ' + str
        }
      }
      return newstr
    },
    hotTransfer() {
      this.appointmentDialog.isVisible = true
      const startTime = moment().toDate()
      const endTime = moment().add(30, 'm').toDate()
      this.appointmentForm.dateRange = [startTime, endTime]
      this.appointmentDialog.matterSelectionOptions = []
      this.appointmentForm.matter = {}
      this.appointmentForm.summary = ''
      this.appointmentForm.description = ''
      this.appointmentDialog.isReadOnly = false
      this.appointmentDialog.isEditing = false
    },
    customResetForm(inputForm) {
      if (inputForm) {
        try {
          Object.keys(inputForm).forEach(key => {
            inputForm[key] = undefined // reset all the values for each key.
          })
        } catch {
          // console.log('Error resetting in customResetForm', +inputForm)
        }
      }
    },
    customResetForms() {
      this.customResetForm(this.appointmentForm)
    },
    async sendConfirmationText() {
      const newAppointmentInformation = this.$store.getters.newAppointmentInformation
      this.textConfirmDialogLoading = true
      this.calendarLoading = true

      var toNumber = '+1' + newAppointmentInformation.matter.client.clientNumber
      var datetime = moment(newAppointmentInformation.matter.appointmentTime)
      var myData = {
        to: toNumber,
        from: '',
        appointment_time: datetime.tz('America/Chicago').format('ddd MMMM DD, YYYY hh:mm a'),
        id_location: this.selectedCalendar.id_location
      }
      await sendSms(myData).then((res) => {
        if (res.success) {
          this.notifySuccess('Message has been sent!')
        } else {
          this.notifyError('Message was not sent: ', res.failure)
        }
      }).catch((e) => {
        this.notifyError('Error sending text: ', e)
        return false
      })
      this.textConfirmDialogLoading = false
      this.textConfirmDialog.isVisible = false
      this.calendarLoading = false
      this.$store.dispatch('scheduling/clearNewAppointmentInformation')
    },
    sendConfirmationEmail(lmdbAppointmentForm) {
      var datetime = moment(lmdbAppointmentForm.start_time)
      var myData = {
        to: lmdbAppointmentForm.email_address,
        from: 'admin@legalmerge.com',
        subject: 'Bond & Botes Appointment Confirmation',
        appointment_time: datetime.tz('America/Chicago').format('ddd MMMM DD, YYYY hh:mm a'),
        clio_id_calendar: this.selectedCalendar.calendar_id_clio,
        id_office: this.selectedCalendar.id_connection_entity,
        id_location: this.selectedCalendar.id_location
      }
      confirmationEmail(myData)
    },
    onCalendarLoaded(val) {
      // if (val && this.$route.query.id_location) {
      //   this.selectedLocation = val
      // }
    },
    async applyCallersMatter() {
      if (this.checkCurrentCallerWrongCalendar()) {
        this.isCurrentCallerWrongCalendar = true
      }
      const newAppointmentInformation = this.$store.getters.newAppointmentInformation
      if (newAppointmentInformation.appointment_state === 'create') {
        this.appointmentForm.summary = newAppointmentInformation.summary
        this.appointmentForm.type = this.calendarList[newAppointmentInformation.appointment_type]
        this.appointmentForm.description = newAppointmentInformation.description
        this.appointmentForm.matter = newAppointmentInformation.matter
        this.appointmentDialog.isVisible = true
        this.appointmentDialog.matterSelectionOptions.push(this.appointmentForm.matter)
      } else {
        this.appointmentForm = newAppointmentInformation
      }
    },
    handleDrag() {
      // this.$refs.select.blur()
    },
    onAppointmentDialogClosed(formName) {
      // this.resetForm(formName)
      this.customResetForm(this.appointmentForm)
      this.appointmentDialog.isReadOnly = false
      this.appointmentDialog.isEditing = false
    },
    handleSelectedAppointmentType() {
      // TODO: wire up when implement appointment/calendar filter
    },
    resetForm(formName) {
      this.appointmentDialog.matterSelectionOptions = []
      this.appointmentForm.matter = undefined
      if (this.$refs['appointmentForm']) {
        this.$refs['appointmentForm'].resetFields()
      }
    },
    matterSelection(searchTerm) {
      if (searchTerm !== '') {
        this.appointmentDialog.matterSelectionLoading = true
        var query = `matters?clientoffice=${this.selectedCalendar.id_connection_entity}&order=display_number&client_id=&limit=15&query=${searchTerm}&status=open,pending&fields=id,etag,display_number,description,status,client%7Bid%2Cname%2Cfirst_name%2Cmiddle_name%2Clast_name%2Ctype%2Cinitials%2Cprimary_phone_number%2Cprimary_email_address%7D`
        getClioItems(query).then(response => {
          if (response?.data) {
            var options = response.data.map(m => ({ id: m.id, description: m.description, client: m.client, display_number: m.display_number, email_address: m.primary_email_address, phone_number: m.primary_phone_number }))
            this.appointmentDialog.matterSelectionOptions = options
          } else {
            this.appointmentDialog.matterSelectionOptions = []
          }
          this.appointmentDialog.matterSelectionLoading = false
        }).catch((e) => { this.notifyError(e) })
      } else {
        this.appointmentDialog.matterSelectionOptions = []
        this.appointmentDialog.matterSelectionLoading = false
      }
    },
    async createAppointment() {
      // const newAppointmentInformation = this.$store.getters.newAppointmentInformation
      const newAppointmentInformation = undefined
      let matterId
      var cont = false
      if (newAppointmentInformation?.matter?.['id'] !== null && newAppointmentInformation?.matter?.['id'] !== undefined && newAppointmentInformation?.matter?.id > 0) {
        matterId = newAppointmentInformation.matter.display_number
        cont = true
      } else if (this.appointmentForm?.matter?.['id'] !== null && this.appointmentForm?.matter?.['id'] !== undefined && this.appointmentForm?.matter?.id > 0) {
        matterId = this.appointmentForm.matter.id
        cont = true
      } else {
        this.notifyError('Appointment reschedule error, Matter not found.')
        cont = false
      }
      if (cont) {
        var postBody = {
          data: {
            summary: this.appointmentForm.summary,
            description: this.appointmentForm.description,
            start_at: this.appointmentForm.dateRange[0], // need some validation
            end_at: this.appointmentForm.dateRange[1], // need some validation
            calendar_owner: {
              id: this.selectedCalendar.calendar_id_clio // is this the correct way to do this?
            },
            matter: {
              id: matterId // is this the correct way to do this?
            }
          }
        }
        console.log(this.selectedCalendar.id_connection_entity)
        var appointment = null
        try {
          console.log('this.selectedCalendar.id_connection_entity', this.selectedCalendar.id_connection_entity)
          appointment = await createClioItems('calendar_entries.json?clientoffice=' + this.selectedCalendar.id_connection_entity, postBody).catch((e) => { this.notifyError(e) })
        } catch (e) {
          this.$notify.error({ title: 'Oops!', message: 'Unable to add appointment...' })
        }
        if (appointment.error) {
          this.notifyError('Appointment creation failed.')
        } else {
          this.notifySuccess('Appointment successfully created!')
        }
        return appointment
      } else {
        return 'failed'
      }
    },
    // test
    async submitAppointmentCreation(formName) {
      this.$refs[formName].validate(async(valid) => {
        var newAppointmentInformation = this.$store.getters.newAppointmentInformation
        if (valid) {
          this.loadingWhileCreatingClioObjects = true
          const appointment = await this.createAppointment().catch((e) => { this.notifyError('Clio appointment creation error', e) })
          if (appointment !== 'failed') {
            this.waitForElement(appointment, 5)
            let matterId
            if (newAppointmentInformation.matter) {
              matterId = newAppointmentInformation.matter.display_number
            } else {
              matterId = this.appointmentForm.matter.id
            }
            const lmdbAppointmentForm = {
              appointment_id_clio: appointment.data.id,
              matter_id_clio: matterId,
              contact_id_clio: this.appointmentForm.matter.client.id,
              appointmentType: 1,
              appointmentresulttypes_id_result_type: 1,
              start_time: this.appointmentForm.dateRange[0],
              end_time: this.appointmentForm.dateRange[1],
              insertedBy: this.name,
              locations_id_calendar: this.selectedCalendar.calendar_id_clio,
              phone_number: this.appointmentForm.matter.client.clientNumber,
              can_text: this.appointmentForm.matter.client.canText,
              email_address: this.appointmentForm.matter.client.clientEmail
            }
            var isSubmissionSuccessful = await addappointment(lmdbAppointmentForm)
              .then((res) => {
                return true
              })
              .catch((e) => {
                this.notifyError('LMDB submission error: ', e)
                return false
              })
            if (appointment?.data?.id) {
              if (lmdbAppointmentForm.email_address) { this.sendConfirmationEmail(lmdbAppointmentForm) }
              try {
                this.getList()
              } catch (e) {
                // console.log('Failed to update calendar: ', e)
              }
            } else {
              // console.log('Could not create appointment.')
              this.notifyError('Could not create appointment.')
            }
            this.appointmentDialog.isVisible = false
            this.loadingWhileCreatingClioObjects = false
            if (newAppointmentInformation.matter.client.canText instanceof String) {
              newAppointmentInformation.matter.client.canText = newAppointmentInformation.matter.client.canText.toLowerCase()
            }
            if (lmdbAppointmentForm.can_text === 1 || newAppointmentInformation.matter.client.canText === 1 || newAppointmentInformation.matter.client.canText === true || newAppointmentInformation.matter.client.canText === 'yes' || newAppointmentInformation.matter.client.canText === 'Yes') {
              this.textConfirmDialog.isVisible = true
              newAppointmentInformation.matter.appointmentTime = lmdbAppointmentForm.start_time
              newAppointmentInformation.matter.client.clientNumber = lmdbAppointmentForm.phone_number
              this.loadingWhileCreatingClioObjects = false
            // this.$store.dispatch('scheduling/setNewAppointmentInformation', newAppointmentInformation)
            } else {
              this.customResetForms()
              if (isSubmissionSuccessful) {
                console.log('clearing 1')
                await this.$store.dispatch('scheduling/clearNewAppointmentInformation')
                await this.fetchCalendars()
              }
            }
          } else {
            this.customResetForms()
            if (isSubmissionSuccessful) {
              console.log('clearing 2')
              await this.$store.dispatch('scheduling/clearNewAppointmentInformation')
              await this.fetchCalendars()
            }
          }
        } else {
          console.log('made it here 1011')
          this.loadingWhileCreatingClioObjects = false
          this.reviewDialogVisible = false
        }
      })
    },
    cancelAppointmentCreation(formName) {
      this.resetForm(formName)
      this.appointmentDialog.isReadOnly = false
      this.appointmentDialog.isVisible = false
    },
    clioDateToTuiDate(clioDate) {
      try {
        var tempDate = moment(clioDate).toISOString()
        return tempDate
      } catch (e) {
        return undefined
      }
    },
    onUpdateSelectedCalendar(val) {
      this.selectedCalendar = val
      this.scheduleList = []
      this.getList()
    },
    getList() {
      if (this.selectedCalendar?.calendar_id_clio !== '-1' && this.selectedCalendar.id_connection_entity) {
        this.listLoading = true
        this.listLoadingText = 'Loading calendar.'
        const from = this.$refs.tuiCal.invoke('getDateRangeStart')._date
        const to = this.$refs.tuiCal.invoke('getDateRangeEnd')._date
        const toplus1 = moment(to).add(1, 'day')._d
        const toISO = toplus1.toISOString()
        const fromISO = from.toISOString()

        var query = `?expanded=true&from=${fromISO}&to=${toISO}&calendar_id=${this.selectedCalendar.calendar_id_clio}&fields=id,etag,start_at,end_at,all_day,summary,description,location,parent_calendar_entry_id,calendar_owner_id,calendars,external_properties,matter%7Bid,display_number,description,client%7D&clientoffice=${this.selectedCalendar.id_connection_entity}`
        fetchList(query)
          .then(response => {
            if (response.data.length > 0) {
              this.scheduleList = response.data.map(clioCalendarObject => {
                var toastFormattedObject = {
                  id: clioCalendarObject.id ? clioCalendarObject.id : null,
                  calendarId: clioCalendarObject.calendarId ? clioCalendarObject.calendarId : '0',
                  title: clioCalendarObject.summary ? clioCalendarObject.summary : '',
                  body: clioCalendarObject.description ? clioCalendarObject.description : '',
                  dueDateClass: '',
                  start: clioCalendarObject.start_at ? this.clioDateToTuiDate(clioCalendarObject.start_at) : null,
                  end: clioCalendarObject.end_at ? this.clioDateToTuiDate(clioCalendarObject.end_at) : null,
                  isReadOnly: false,
                  category: 'time',
                  raw: { matter: clioCalendarObject.matter, etag: clioCalendarObject.etag }
                }
                this.listLoading = false
                this.calendarLoading = false
                return toastFormattedObject
              })
            } else {
              this.listLoading = false
              this.calendarLoading = false
            }
          }).catch((e) => { this.notifyError('Fetchlist query error: ', e) })
      }
    },
    setRenderRangeText() {
      const { invoke } = this.$refs.tuiCal
      const view = invoke('getViewName')
      const calDate = invoke('getDate')
      const rangeStart = invoke('getDateRangeStart')

      let year = calDate.getFullYear()
      let month = calDate.getMonth()
      let date = calDate.getDate()
      let dateRangeText = ''

      switch (view) {
        case 'month':
          var y = moment(rangeStart.toDate()).add(15, 'days').year()
          var monthDate = moment()

          monthDate.set('month', month)
          monthDate.set('date', date)
          monthDate.set('year', y)
          dateRangeText = monthDate.format('MMMM YYYY')
          break
        case 'week':
          year = rangeStart.getFullYear()
          month = rangeStart.getMonth()
          date = rangeStart.getDate()
          var momentStartDate = moment()
          momentStartDate.set('year', year)
          momentStartDate.set('month', month)
          momentStartDate.set('date', date)
          var momentEndDate = moment()
          momentEndDate.set('year', year)
          momentEndDate.set('month', month)
          momentEndDate.set('date', date)
          momentEndDate.subtract(1, 'days')
          momentEndDate.add(1, 'week')
          dateRangeText = momentStartDate.format('MMMM D, YYYY') + ' - ' + momentEndDate.format('MMMM D, YYYY')
          break
        default:
          var weekDate = moment()
          weekDate.set('year', year)
          weekDate.set('month', month)
          weekDate.set('date', date)
          dateRangeText = weekDate.format('MMMM D, YYYY')
      }
      this.dateRange = dateRangeText
    },
    setCalendarEventToAppoitnmentForm(res) {
      this.appointmentDialog.matterSelectionOptions = []
      this.appointmentForm.matter = {}
      if (res.schedule) {
        this.appointmentDialog.isReadOnly = true
        this.appointmentForm.dateRange = [res.schedule.start.toDate(), res.schedule.end.toDate()]
        this.appointmentForm.summary = res.schedule.title
        this.appointmentForm.description = res.schedule.body
        this.appointmentForm.type = this.calendarList[res.schedule.calendarId]
        this.appointmentForm.etag = res.schedule.raw.etag
        if (res.schedule.raw.matter) {
          this.appointmentDialog.matterSelectionOptions = [res.schedule.raw.matter]
          this.appointmentForm.matter = res.schedule.raw.matter
        }
        this.appointmentForm.appointmentId = res.schedule.id
      } else {
        this.appointmentForm.summary = ''
        this.appointmentForm.description = ''
        this.appointmentForm.dateRange = [res.start.toDate(), res.end.toDate()]
        this.appointmentDialog.isReadOnly = false
        this.appointmentDialog.isEditing = false
      }
    },
    onClickNavi(action) {
      this.listLoading = true
      this.$refs.tuiCal.invoke(action)
      this.setRenderRangeText()
      this.getList()
    },
    onClickSchedule(res) {
      this.setCalendarEventToAppoitnmentForm(res)
      this.appointmentDialog.isVisible = true
    },
    onClickDayname(res) {
      this.setCalendarEventToAppoitnmentForm(res)
      this.appointmentDialog.isVisible = true
    },
    onBeforeCreateSchedule(res) {
      // if (this.selectedCalendar.calendar_id_clio == '-1') { // If no calendar is selected, call the reminder dialog
      //   this.remindCalendarDialog = true
      const currDateTime = new Date()
      if (res.start.toDate() < currDateTime) { // If a past datetime is selected then popup dialog instead of appt creation.
        this.pastApptDialog = true
      } else {
        this.setCalendarEventToAppoitnmentForm(res)
        this.appointmentForm.dateRange = [res.start.toDate(), res.end.toDate()]
        this.appointmentDialog.isVisible = true
      }

      // var guide = res.guide
      this.guideRef = res.guide
      this.guideRef.clearGuideElement()
    },
    onBeforeUpdateSchedule(e) {

    },

    setScheduleEditable(formName) {
      this.appointmentDialog.isReadOnly = false
      this.appointmentDialog.isEditing = true
    },
    async submitAppointmentPatch(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          var appointment = await this.updateExistingAppointment()

          if (appointment && appointment !== 'Error') {
            try {
              this.getList()
              this.guideRef.clearGuideElement()
              window.location = window.location.href.split('?')[0]
            } catch (exception) {
              this.loadingWhileCreatingClioObjects = false
            }
            this.loadingWhileCreatingClioObjects = true
          } else {
            this.loadingWhileCreatingClioObjects = true
          }

          this.resetForm(formName)
          this.appointmentDialog.isVisible = false
          this.loadingWhileCreatingClioObjects = false
        } else {
          this.loadingWhileCreatingClioObjects = false
          this.reviewDialogVisible = false
          return false
        }
      })
    },
    async updateExistingAppointment() {
      var postBody = {
        data: {
          id: this.appointmentForm.appointmentId,
          summary: this.appointmentForm.summary,
          description: this.appointmentForm.description,
          start: this.appointmentForm.dateRange[0],
          end: this.appointmentForm.dateRange[1],
          etag: this.appointmentForm.etag,
          calendar_owner: {
            id: this.selectedCalendar.calendar_id_clio
          }
        }
      }
      try {
        var appointment = await patchClioItems('calendar_entries/' + postBody.data.id + '.json?clientoffice=' + this.selectedCalendar.id_connection_entity, postBody)
        // var appointment = await patchClioItems('calendar_entries/' + this.appointmentForm.appointmentId + '.json?clientoffice=' + this.selectedCalendar.id_connection_entity, postBody)
        return appointment
      } catch (error) {
        console.error(error)
        this.notifyError(error)
        return []
      }
    },
    async rescheduleExistingAppointment(postBody) {
      var appointment = await patchClioItems('calendar_entries/' + postBody.data.id + '.json?clientoffice=' + this.selectedCalendar.id_connection_entity, postBody).catch((e) => { this.notifyError(e) })
      return appointment
    },
    onBeforeDeleteSchedule(res) {
      const idx = this.scheduleList.findIndex(item => item.id === res.schedule.id)
      this.scheduleList.splice(idx, 1)
    },
    onAfterRenderSchedule(res) {
    },
    onClickTimezonesCollapseBtn(timezonesCollapsed) {
      if (timezonesCollapsed) {
        this.theme['week.timegridLeft.width'] = '100px'
        this.theme['week.daygridLeft.width'] = '100px'
      } else {
        this.theme['week.timegridLeft.width'] = '50px'
        this.theme['week.daygridLeft.width'] = '50px'
      }
    },
    waitForElement(element, loops) {
      if (element !== undefined) {
        return true
      } else if (loops <= 0) {
        return false
      } else {
        loops = loops - 1
        return setTimeout(this.waitForElement(element, loops), 250)
      }
    }
  }
}
</script>

<style lang="scss">

.el-input.is-disabled .el-input__inner{
  background-color:white;
  border-color:#dfe4ed;
  color: black;
  cursor:not-allowed
}

.el-textarea.is-disabled .el-textarea__inner{
  background-color:white;
  border-color:#dfe4ed;
  color: black;
  cursor:not-allowed
}

.el-range-editor.is-disabled {
    background-color: white;
    border-color: #dfe4ed;
    color: black;
    cursor: not-allowed;
}

.el-range-editor.is-disabled input {
    background-color: white;
    color: black;
    cursor: not-allowed;
}

.calendar-loading-custom{
  .el-loading-spinner {
    .el-loading-text {
      font-size: 24px;
      color: black;
    }
  }
}
</style>

<style lang="scss" scoped>
.date-control-container {
  padding-top: 10px;
  padding-bottom: 10px;
}
.filter-item {
  width: 100%
}

.wrong-calendar-dialog {
  text-align: center;
  --el-dialog-padding-primary: 0px !important;
  .el-dialog__body {
    padding-top: 0px !important;
  }
}

.wrong-calendar-dialog .el-dialog__body {
  padding-top: 0px !important;
}

.matter-label {
  background-color: blue;
  width: 100%;
  .label {
    span {
  width: 100%
}
  }}
</style>
