<template>
  <div class="sidebar-logo-container" :class="{'collapse':collapse}">
    <transition name="sidebarLogoFade">
      <router-link v-if="collapse" key="collapse" class="sidebar-logo-link" to="/" />
      <router-link v-else key="expand" class="sidebar-logo-link" to="/">
        <h1 class="sidebar-title-legal">{{ titleLegal }} </h1>
        <h1 class="sidebar-title-merge">{{ titleMerge }} </h1>
      </router-link>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      titleLegal: 'Legal',
      titleMerge: 'Merge'
    }
  }
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Cormorant+SC:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Yellowtail&display=swap');
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: #2b2f3a;
  text-align: center;
  overflow: hidden;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;

    & .sidebar-logo {
      width: 32px;
      height: 32px;
      vertical-align: middle;
      margin-right: 12px;
    }

    & .sidebar-title-legal {
      display: inline-block;
      margin: 0;
      color: #fff;
      font-weight: 600;
      line-height: 50px;
      font-size: 30px;
      font-family: 'Cormorant SC', serif;
      vertical-align: middle;
      letter-spacing: 2px;
      padding-right: 2px;
    }

    & .sidebar-title-merge {
      display: inline-block;
      margin: 0;
      color: #fff;
      font-weight: 1;
      line-height: 50px;
      font-size: 30px;
      font-family: 'Yellowtail', cursive;
      vertical-align: middle;
      letter-spacing: 2px;
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0px;
    }
  }
}
</style>
