<template>
  <div>
    <el-dialog title="Loading" :visible.sync="loadingDialogVisible">
      <el-progress :text-inside="true" :stroke-width="26" :percentage="loadingProgressPercentage" />
      <p>
        <span>
          {{ loadingProgressText }}
        </span>
      </p>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="loadingDialogVisible = false">Hide</el-button>
      </span>
    </el-dialog>

    <!-- <LeadSourceTableDialog v-bind.sync="LeadSourceTableDialogalues" @close="closeLeadSourceTableDialog" /> -->

    <el-row :gutter="20" style="margin-top: 15px">
      <el-col
        :span="24"
      ><div>
        <!-- /* TABLE
        ***
        ***
        ****
        ****
        ** STARTS here
        */ -->
        <el-table
          id="analyticsTable"
          ref="analyticsTable"
          v-loading="listLoading"
          :data="rows"
          border
          :fit="false"
          stripe
          :show-summary="true"
          highlight-current-row
          style="width: 100%"
        >
          <el-table-column
            label="Lead Source"
            width="180"
            column-key="lead_source"
            prop="lead_source"
            header-align="center"
          />

          <el-table-column
            label="Lead Count"
            width="180"
            column-key="lead_count"
            prop="lead_count"
            header-align="center"
          />
          <el-table-column
            label="Disputed"
            width="180"
            column-key="disputed_count"
            prop="disputed_count"
            header-align="center"
          />
          <el-table-column
            label="Unusable"
            width="180"
            column-key="unusable_count"
            prop="unusable_count"
            header-align="center"
          />
          <el-table-column
            label="Set Opportunities"
            width="180"
            column-key="set_opportunities"
            prop="set_opportunities"
            header-align="center"
          />
          <el-table-column
            label="Reschedules"
            width="180"
            column-key="reschedule_count"
            prop="reschedule_count"
            header-align="center"
          />
          <el-table-column
            label="Total Sets"
            width="180"
            column-key="total_sets"
            prop="total_sets"
            header-align="center"
          />
          <el-table-column
            label="Set Rate"
            width="180"
            column-key="set_percentage"
            prop="set_percentage"
            header-align="center"
          />
          <el-table-column
            label="Adjusted Set Rate"
            width="180"
            column-key="adjusted_set_rate"
            prop="adjusted_set_rate"
            header-align="center"
          />
          <el-table-column
            label="On Calendar"
            width="180"
            column-key="appointments_on_calendar"
            prop="appointments_on_calendar"
            header-align="center"
          />
          <el-table-column
            label="Shows"
            width="180"
            column-key="total_shows"
            prop="total_shows"
            header-align="center"
          />
          <el-table-column
            label="Show Rate"
            width="180"
            column-key="show_rate"
            prop="show_rate"
            header-align="center"
          />
          <el-table-column
            label="Retained"
            width="180"
            column-key="total_retains"
            prop="total_retains"
            header-align="center"
          />
          <el-table-column
            label="Retain Rate"
            width="180"
            column-key="retain_rate"
            prop="retain_rate"
            header-align="center"
          />
          <el-table-column
            label="Cost Per Lead"
            width="180"
            column-key="cost_per_lead"
            prop="cost_per_lead"
            :formatter="currencyFormatter"
            header-align="center"
          />
          <el-table-column
            label="Admin Cost"
            width="180"
            column-key="admin_cost"
            prop="admin_cost"
            :formatter="currencyFormatter"
            header-align="center"
          />
          <el-table-column
            label="Lead Spend"
            width="180"
            column-key="lead_spend"
            prop="lead_spend"
            :formatter="currencyFormatter"
            header-align="center"
          />
          <el-table-column
            label="Total Spent"
            width="180"
            column-key="total_spent"
            prop="total_spent"
            :formatter="currencyFormatter"
            header-align="center"
          />
          <el-table-column
            label="Cost Per Retain"
            width="180"
            column-key="cost_per_retain"
            prop="cost_per_retain"
            :formatter="currencyFormatter"
            header-align="center"
          />
          <!-- <el-table-column
            label="View Info"
            width="180"
            header-align="center"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="viewSelectedInfo(scope.$index, scope.row)"
              >View Records</el-button>
            </template>
          </el-table-column> -->
          <!-- <el-table-column
            label="Disputed"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="handleEdit(scope.$index, scope.row)"
              >Edit</el-button>
              <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.$index, scope.row)"
              >Delete</el-button>
            </template>
          </el-table-column> -->

          <!-- <el-table-column
            v-for="col in columns"
            :key="col"
            :label="col"
            :prop="col"
            sortable
            :resizable="true"
            show-overflow-tooltip
            align="left"
            header-align="center"
            :column-key="col"
          /> -->
        </el-table></div></el-col></el-row>
  </div>
</template>

<script>
import {
  getListData,
  lookupMatterData
} from '@/api/appointmentresult'
import { getLeadInfo } from '@/api/lead-info'
import XLSX from 'xlsx'
import alasql from 'alasql'
import _ from 'lodash'

const today = new Date()

export default {
  name: 'AnalyticsAdwords',
  components: {},
  props: {
    dateFilter: {
      type: Array,
      default: null
    },
    selectedLocation: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      appointmentList: [],
      formattedRecordData: [],
      list: null,
      listLoading: false, // this should be set to true when the data is loaded
      loadingDialogVisible: false,
      loadingProgressPercentage: 0,
      loadingProgressText: 'loading',
      reportSelected: 'adwords_roi_report',
      rows: [],
      selectListLoading: false
      // selectedLocation: null
    }
  },
  watch: {
    dateFilter: function(newVal, oldVal) { // watch it
      //   console.log('dateFilter changed: ', newVal, ' | was: ', oldVal)
      this.fetchRecordsRange()
    },
    selectedLocation: function(newVal, oldVal) { // watch it
      //   console.log('selectedLocation changed: ', newVal, ' | was: ', oldVal)
      this.filterByLocation()
    }
  },
  clientId: undefined,
  caller: '',
  mounted: async function() {
    // console.log('analytics adwords mounted')
    // console.log('this.selectedLocation', this.selectedLocation)
    // console.log('datefilter', this.dateFilter)
    await this.fetchRecordsRange()
  },
  methods: {
    viewSelectedInfo(index, row) {
      // console.log('viewSelected', index, row)
    },
    percentageFormatter(x) {
      const pctFormatter = Intl.NumberFormat('en-US', {
        style: 'percent'
      })

      return pctFormatter.format(x)
    },
    currencyFormatter(row, column, cellValue, index) {
      // console.log('row is:', row)
      // console.log('column is:', column)
      // console.log('cellValue is:', cellValue)
      // console.log('index is:', index)
      let returnValue = null
      if (cellValue) {
        const currencyFormatter = Intl.NumberFormat('en-US', {
          style: 'currency', currency: 'USD'
        })
        returnValue = currencyFormatter.format(cellValue)
      }
      return returnValue
      // return currencyFormatter.format(cellValue)
    },
    async fetchRecordsRange() {
    //  console.log('called fetchRecordsRange')
      if (this.dateFilter && this.reportSelected) {
        this.listLoading = true
        this.loadingDialogVisible = true
      }

      //  console.log('this.dateFilter is:', this.dateFilter)
      //  console.log('this.selectedLocation is:', this.selectedLocation)

      this.loadingProgressPercentage = 0
      this.loadingProgressText = 'Fetching lead data'
      this.formattedRecordData = []

      // var tableDataCount = await getListData([this.dateFilter, 'adwords_roi_count', this.selectedLocation, today])
      // console.log('tableDataCount is:', tableDataCount)
      var tableData = await getListData([this.dateFilter, this.reportSelected, this.selectedLocation, today])
      // console.log('tableData is:', tableData)

      // const uncrushed = JSONCrush.uncrush(tableData)
      // console.log('uncrushed: ', uncrushed)

      this.loadingProgressPercentage = 75
      this.loadingProgressText = 'Fetching appointment data'

      // deep clone tableData
      var tableClone = _.cloneDeep(tableData.data)

      // if there is tabledata, get data for all appointments on LM calendar for selected daterange
      // and get a list of appointment IDs for rescheduled appointments
      if (tableData.data.length > 0) {
        // OLD appointmentsOnCalendar query
        // var appointmentsOnCalendar = await lookupMatterData([this.dateFilter, 'appointments_on_calendar'])

        var appointmentsOnCalendar = await lookupMatterData([this.dateFilter, 'appointments_range_for_matters_range'])

        // console.log('appointmentsOnCalendar', appointmentsOnCalendar)

        var appointmentsRescheduled = await lookupMatterData([this.dateFilter, 'appointments_rescheduled'])

        // map to get the list of rescheduled appointments from the array of objects
        var appointmentsRescheduledList = _.map(appointmentsRescheduled.data, 'id_appointment')

        // join and split to convert the numbers to strings for intersection below
        appointmentsRescheduledList = appointmentsRescheduledList.join().split(',')
      }
      // get all appointment IDs connected to every matter in dateFilter range
      if (tableData.data.length > 0) {
        var appointmentsByMatter = await lookupMatterData([this.dateFilter, 'matter_appointments_range'])
        //   console.log('appointmentsByMatter is: ', appointmentsByMatter.data)
      }

      const res_lead_source_aliases = await getLeadInfo('lead_source_aliases')

      // console.log('res_lead_source_aliases:', res_lead_source_aliases)

      // ******** possibly move duplicateCounter out here and try doing the join inside the map

      this.loadingProgressPercentage = 85
      this.loadingProgressText = 'Formatting data and marking duplicates'
      /** **
       * ***
       * *** MAP HERE ******
       * ***
       */

      var tableFormatted = tableClone.map(function(x) {
      // var tableFormatted = tableData.data.map(function(x) {
        // set duplicate value if from googleAdsLeads
        var [minGoogleAdsInsert] = alasql(`SELECT
                                        MIN(combined_inserted_on) as min_inserted_on
                                        FROM ?
                                        WHERE lead_phone_number = ?
                                        GROUP BY lead_phone_number`, [tableClone, x.lead_phone_number])
        // eslint-disable-next-line
          if (minGoogleAdsInsert.min_inserted_on == x.combined_inserted_on) {
          x.duplicate_value = 'First'
        } else {
          x.duplicate_value = 'duplicate'
        }

        // setting final lead quality
        if (x.lead_quality === 'good' || x.lead_quality === 'C') {
          if (x.duplicate_value === 'First') {
            x.final_lead_quality = 'G'
          } else {
            x.final_lead_quality = 'U'
          }
        } else if (x.lead_quality === 'D') {
          x.final_lead_quality = 'D'
        } else {
          x.final_lead_quality = 'U'
        }

        /* can probably delete the switch directly below */
        // combining lead sources with multiple alternate names for the same source
        switch (x.combined_lead_source) {
          case 'Google':
            if (x.lead_channel === 'Paid Search') {
              x.final_lead_source = 'Adwords'
            } else {
              x.final_lead_source = 'Organic Google'
            }
            break
          case '720 Facebook Lead':
          case '720':
          case '720CreditScore':
            x.final_lead_source = '720'
            break
          case 'Ascend':
          case 'Ascend Finance':
            x.final_lead_source = 'Ascend'
            break
          case 'LSA-Opelika':
          case 'LSA-Montgomery':
          case 'LSA-Huntsville':
          case 'LSA-Mobile':
          case 'LSA-Birminham':
          case 'LSA-Anniston':
            x.final_lead_source = 'LSA'
            break
          case 'Lawsuit_DM_Montgomery':
          case 'Lawsuit_DM_Mobile':
          case 'Lawsuit_DM_Huntsville':
          case 'Lawsuit_DM_Florence':
            x.final_lead_source = 'Lawsuit_DM'
            break
          case 'FC_DM_Montgomery':
          case 'FC_DM_Mobile':
          case 'FC_DM_Huntsville':
          case 'FC_DM_Birmingham':
            x.final_lead_source = 'FC_DM'
            break
          case 'expertise.com':
            x.final_lead_source = 'Expertise.com'
            break
          default:
            x.final_lead_source = x.combined_lead_source
            break
        }

        // distinguish Adwords from Organic
        // if (x.combined_lead_source === 'Google') {
        //   if (x.lead_channel === 'Paid Search') {
        //     x.combined_lead_source = 'Adwords'
        //   } else {
        //     x.combined_lead_source = 'Organic Google'
        //   }
        // }

        // query from lead source aliases and set lead_source value

        var [alias_lead_source] = alasql(`SELECT a.lead_source_name FROM ? a WHERE LOWER(a.lead_alias_name) = LOWER(?)`, [res_lead_source_aliases.data, x.final_lead_source])

        if (!alias_lead_source?.lead_source_name) {
          console.log('need to add: ', x.final_lead_source)
        }

        x.final_lead_source = alias_lead_source?.lead_source_name

        // connecting appointment data to leads
        var [appt_concat_query] = alasql(`SELECT
                                        concat_appts
                                        FROM ?
                                        WHERE id_clio = ?`, [appointmentsByMatter.data, x.clio_matter_id])
        if (appt_concat_query?.concat_appts) {
          x.appts_concat = appt_concat_query.concat_appts
        }
        if (x?.appts_concat && x.duplicate_value === 'First' && x.final_lead_quality === 'G') {
          var apptsArray = _.split(x.appts_concat, ',')
          x.appts_count = apptsArray.length
        }

        // on calendar data

        // connecting appointment data to leads
        var [on_calendar_appt_concat_query] = alasql(`SELECT
                                        concat_appts
                                        FROM ?
                                        WHERE id_clio = ?`, [appointmentsOnCalendar.data, x.clio_matter_id])

        if (on_calendar_appt_concat_query?.concat_appts) {
          x.appts_on_cal_concat = on_calendar_appt_concat_query.concat_appts
        }

        if (x?.appts_on_cal_concat && x.duplicate_value === 'First' && x.final_lead_quality === 'G') {
          var apptsOnCalArray = _.split(x.appts_on_cal_concat, ',')
          x.appt_on_calendar = apptsOnCalArray.length
        } else {
          x.appt_on_calendar = 0
        }

        // if (_.some(appointmentsOnCalendar.data, ['matters_id_matter', x.id_matter]) && x.duplicate_value === 'First') {
        //   x.appt_on_calendar = 1
        // } else {
        //   x.appt_on_calendar = 0
        // }

        var rescheduledApptsList = _.intersection(appointmentsRescheduledList, apptsArray)

        if (x.duplicate_value === 'First' && x.final_lead_quality === 'G') {
          x.resched_count = rescheduledApptsList.length
        }

        if ((x.appts_count - x.resched_count) > 1) {
          x.resched_count = apptsArray.length - 1
        }

        if (x?.combined_lead_location) {
          if (isNaN(x.combined_lead_location)) {
            switch (x.combined_lead_location.toLowerCase()) {
              case 'montgomery/opelika' :
              case 'montgomery / opelika':
              case 'montgomery':
              case 'opelika':
                x.final_location = 1
                break
              case 'gadsden/anniston':
              case 'anniston / gadsden':
              case 'gadsden':
              case 'anniston':
                x.final_location = 2
                break
              case 'florence/haleyville':
              case 'florence / haleyville':
              case 'florence':
              case 'haleyville':
                x.final_location = 3
                break
              case 'huntsville/decatur/cullman':
              case 'huntsville / cullman / decatur':
              case 'huntsville':
              case 'decatur':
              case 'cullman':
                x.final_location = 4
                break
              case 'jackson/vicksburg/hattiesburg':
              case 'jackson':
              case 'vicksburg':
              case 'hattiesburg':
              case 'mississippi':
                x.final_location = 5
                break
              case 'mobile':
                x.final_location = 6
                break
              case 'birmingham':
                x.final_location = 8
                break
              default:
                x.final_location = 'unknown'
                break
            }
          } else {
            switch (x.combined_lead_location) {
              case 1:
                x.final_location = 1
                break
              case 2:
                x.final_location = 2
                break
              case 3:
                x.final_location = 3
                break
              case 4:
                x.final_location = 4
                break
              case 5:
                x.final_location = 5
                break
              case 6:
                x.final_location = 6
                break
              case 8:
                x.final_location = 8
                break
              default:
                x.final_location = 'unknown'
                break
            }
          }
        } else {
          x.final_location = 'unknown'
        }

        if (x?.locations_id_location && x.final_location !== x.locations_id_location) {
          x.final_location = x.locations_id_location
        }

        return x
      })

      this.loadingProgressPercentage = 90
      this.loadingProgressText = 'Performing Calculations'

      var queryStartPeriod = this.getPeriodFromDate(this.dateFilter[0])
      var queryEndPeriod = this.getPeriodFromDate(this.dateFilter[1])
      // get lead costs
      var leadCostQuery = `lead_costs?from=${queryStartPeriod}&to=${queryEndPeriod}`
      // console.log('leadCostQuery is:', leadCostQuery)

      const res_lead_costs = await getLeadInfo(leadCostQuery)

      // console.table(res_lead_costs.data)
      // console.log('res_lead_costs.data: ', res_lead_costs.data)

      const res_lead_costs_summary = alasql(`SELECT
        lc.id_lead_source,
        lc.id_office,
        lc.id_lead_cost_type,
        SUM(lc.lead_cost_amount) AS lead_cost_amount,
       -- ROUND( AVG(lc.vendor_fee_percentage), 2) AS vendor_fee_percentage,
       MAX(lc.vendor_fee_percentage),
        lc.lead_source_name
        FROM ? lc
        GROUP BY lc.id_lead_source, lc.id_office, lc.id_lead_cost_type, lc.lead_source_name`, [res_lead_costs.data])

      // const res_lead_costs_summary = alasql(`SELECT * FROM ? lc`, [res_lead_costs.data])

      // console.log('res_lead_costs_summary', res_lead_costs_summary)
      // console.table(res_lead_costs_summary)

      // console.log('res_lead_costs:', res_lead_costs)
      // console.table(res_lead_costs.data)

      var groupedData = alasql(`WITH a AS (SELECT final_lead_source as lead_source,
      final_location AS office_location,
      COUNT(*) AS lead_count,
      SUM(if(final_lead_quality = 'D', 1, 0)) AS disputed_count,
      SUM(if(final_lead_quality = 'U', 1, 0)) AS unusable_count,
      SUM(if(duplicate_value = 'First', appts_count, 0)) AS total_sets,
      SUM(if(lead_result IN('Filed', 'Not Retained', 'Retained', 'Scheduled Be Back', 'Unscheduled Be Back') AND duplicate_value = 'First', 1, 0)) AS total_shows,
      SUM(resched_count) AS reschedule_count,
      SUM(if(duplicate_value = 'First', appt_on_calendar, 0)) AS appointments_on_calendar,
      SUM(if(lead_result IN('Filed', 'Retained') AND duplicate_value = 'First', 1, 0)) AS total_retains
      FROM ?
      GROUP BY final_location, final_lead_source)
      SELECT a.*,
      a.lead_count - a.disputed_count - a.unusable_count AS set_opportunities,
      CONCAT(ROUND(((a.total_sets - a.reschedule_count) / (a.lead_count - a.disputed_count - a.unusable_count) * 100), 2), '%') AS set_percentage,
      CONCAT(ROUND(((a.total_sets) / (a.lead_count - a.disputed_count - a.unusable_count) * 100), 2), '%') AS adjusted_set_rate,
      IF(a.total_shows <> 0 AND a.appointments_on_calendar <> 0, CONCAT(ROUND((a.total_shows / a.appointments_on_calendar * 100), 2), '%'), '0%') as show_rate,
      IF(a.total_retains <> 0 AND a.total_shows <> 0, CONCAT(ROUND((a.total_retains / a.total_shows * 100), 2), '%'), '0%') as retain_rate,
      CASE  WHEN a.office_location = 1 THEN 'Montgomery/Opelika' 
            WHEN a.office_location = 2 THEN 'Gadsden/Anniston' 
            WHEN a.office_location = 3 THEN 'Florence/Haleyville'
            WHEN a.office_location = 4 THEN 'Huntsville' 
            WHEN a.office_location = 5 THEN 'MS' 
            WHEN a.office_location = 6 THEN 'Mobile' 
            WHEN a.office_location = 8 THEN 'Birmingham' 
        ELSE 'Unknown' END AS office_location_name,
      -- COALESCE(b.lead_cost_amount, 0) AS lead_cost_amount,
      IFNULL(b.lead_cost_amount, 0) AS lead_cost_amount,
       b.id_lead_cost_type,
       IF(b.vendor_fee_percentage IS NULL, 0, b.vendor_fee_percentage) AS vendor_fee_percentage,
      -- CASE WHEN b.id_lead_cost_type = 1 THEN (a.set_opportunities * b.lead_cost_amount)
       CASE WHEN b.id_lead_cost_type = 1 THEN (a.lead_count - a.disputed_count - a.unusable_count) * b.lead_cost_amount
        WHEN b.id_lead_cost_type = 2 THEN b.lead_cost_amount
        WHEN b.id_lead_cost_type = 3 THEN b.lead_cost_amount
        ELSE 0 END AS lead_spend
       FROM a \
       LEFT JOIN ? b ON b.lead_source_name = a.lead_source AND b.id_office = a.office_location`, [tableFormatted, res_lead_costs_summary])

      // LEFT JOIN ? b ON b.lead_source_name = a.lead_source AND b.id_office = a.office_location`, [tableFormatted, res_lead_costs.data])
      // Perform cost calculations
      var groupedCostData = alasql(`SELECT a.*, 
       IF(a.vendor_fee_percentage > 0, ROUND(a.lead_spend * (a.vendor_fee_percentage* .01), 2), 0) AS admin_cost,
       --IF(a.vendor_fee_percentage IS NOT NULL, ROUND(a.lead_spend * (1+(a.vendor_fee_percentage* .01)), 2), a.lead_spend) AS total_spent,
       IF(IFNULL(a.vendor_fee_percentage, 0) > 0, ROUND(a.lead_spend * (1+(a.vendor_fee_percentage* .01)), 2), a.lead_spend) AS total_spent,
       IF(a.total_retains > 0, ROUND((a.lead_spend * (1+(a.vendor_fee_percentage * .01))/a.total_retains), 2), NULL) AS cost_per_retain,
      -- ROUND((a.lead_spend * (1+(a.vendor_fee_percentage * .01))/a.total_retains), 2) AS cost_per_retain,
       ROUND((a.lead_spend * (1+(a.vendor_fee_percentage * .01))/a.lead_count), 2) AS cost_per_lead
       FROM ? a`, [groupedData])

      // console.table(groupedData)
      // console.table(groupedCostData)

      // ************************* Set sort order for the object for excel export ***************************************

      const sortOrder = { 'lead_source': 1, 'lead_count': 2, 'disputed_count': 3, 'unusable_count': 4, 'set_opportunities': 5, 'reschedule_count': 6, 'total_sets': 7, 'set_percentage': 8, 'adjusted_set_rate': 9, 'appointments_on_calendar': 10, 'total_shows': 11, 'show_rate': 12, 'total_retains': 13, 'retain_rate': 14, 'office_location': 15, 'office_location_name': 16, 'lead_spend': 17, 'id_lead_cost_type': 18, 'vendor_fee_percentage': 19, 'total_spent': 20, 'cost_per_retain': 21, 'admin_cost': 22, 'cost_per_lead': 23 }

      const groupedDataSorted = groupedCostData.map(o => Object.assign({}, ...Object.keys(o).sort((a, b) => sortOrder[a] - sortOrder[b]).map(x => { return { [x]: o[x] } })))

      this.loadingProgressPercentage = 100
      this.loadingProgressText = 'Finishing up'
      this.formattedRecordData = groupedDataSorted
      // console.table(groupedDataSorted)

      // this.formattedRecordData = groupedData

      // console.log('tableData is ', tableData)
      // console.log('tableFormatted is:', tableFormatted)
      // console.log('groupedData is:', groupedData)
      // console.log('groupedDataSorted is:', groupedDataSorted)
      this.listLoading = false
      this.loadingDialogVisible = false
      if (groupedDataSorted !== 'none') {
        this.rows = groupedDataSorted
        // this.rows = groupedData
        // go ahead and run the location filter
        this.filterByLocation()
      } else {
        this.rows = []
      }
    },
    filterByLocation() {
      var q_1 = 'SELECT * FROM ? WHERE office_location = 1 ORDER BY lead_source ASC'
      var q_2 = 'SELECT * FROM ? WHERE office_location = 2 ORDER BY lead_source ASC'
      var q_3 = 'SELECT * FROM ? WHERE office_location = 3 ORDER BY lead_source ASC'
      var q_4 = 'SELECT * FROM ? WHERE office_location = 4 ORDER BY lead_source ASC'
      var q_5 = 'SELECT * FROM ? WHERE office_location = 5 ORDER BY lead_source ASC'
      var q_6 = 'SELECT * FROM ? WHERE office_location = 6 ORDER BY lead_source ASC'
      var q_8 = 'SELECT * FROM ? WHERE office_location = 8 ORDER BY lead_source ASC'
      var q_all = `WITH A AS (SELECT lead_source,
      SUM(lead_count) AS lead_count,
      SUM(disputed_count) AS disputed_count,
      SUM(unusable_count) AS unusable_count,
      SUM(total_sets) AS total_sets,
      SUM(total_shows) AS total_shows,
      SUM(reschedule_count) AS reschedule_count,
      SUM(appointments_on_calendar) AS appointments_on_calendar,
      SUM(total_retains) AS total_retains,
      SUM(lead_cost_amount) AS lead_cost_amount,
      SUM(admin_cost) AS admin_cost,
      SUM(total_spent) AS total_spent,
      SUM(lead_spend) AS lead_spend,
      SUM(cost_per_lead) AS cost_per_lead
      FROM ?
      GROUP BY lead_source)
      SELECT A.lead_source,
      A.lead_count,
      A.disputed_count,
      A.unusable_count,
      A.total_sets,
      A.total_shows,
      A.reschedule_count,
      A.appointments_on_calendar,
      A.total_retains,
      A.lead_cost_amount,
      ROUND(A.admin_cost, 2) as admin_cost,
      ROUND(A.total_spent, 2) as total_spent,
      ROUND(A.lead_spend, 2) as lead_spend,
      ROUND((A.total_spent / A.total_retains), 2) as cost_per_retain,
      ROUND((A.total_spent / A.lead_count), 2) AS cost_per_lead,
      A.lead_count - A.disputed_count - A.unusable_count AS set_opportunities,
      CONCAT(ROUND(((A.total_sets - A.reschedule_count) / (A.lead_count - A.disputed_count - A.unusable_count) * 100), 2), '%') AS set_percentage,
      CONCAT(ROUND(((A.total_sets) / (A.lead_count - A.disputed_count - A.unusable_count) * 100), 2), '%') AS adjusted_set_rate,
      IF(A.total_shows <> 0 AND A.appointments_on_calendar <> 0, CONCAT(ROUND((A.total_shows / A.appointments_on_calendar * 100), 2), '%'), '0%') as show_rate,
      IF(A.total_retains <> 0 AND A.total_shows <> 0, CONCAT(ROUND((A.total_retains / A.total_shows * 100), 2), '%'), '0%') as retain_rate
       FROM A
       ORDER BY lead_source ASC`
      var filterLocationQuery
      // *****
      // **** Doing query this way because this.selectedLocation wasn't working as a param
      // **** possibly look at converting the value to a number to see if that works
      switch (this.selectedLocation) {
        case 1:
          filterLocationQuery = q_1
          break
        case 2:
          filterLocationQuery = q_2
          break
        case 3:
          filterLocationQuery = q_3
          break
        case 4:
          filterLocationQuery = q_4
          break
        case 5:
          filterLocationQuery = q_5
          break
        case 6:
          filterLocationQuery = q_6
          break
        case 8:
          filterLocationQuery = q_8
          break
        default:
          filterLocationQuery = q_all
          break
      }
      this.rows = alasql(filterLocationQuery, [this.formattedRecordData])
    },
    getPeriodFromDate(inputDate) {
      // update later by checking if inputDate is a date and then formatting the string accordingly
      inputDate = inputDate.replace(/-/g, '')
      const period = inputDate.substring(0, 6)
      // const dt = DateTime.local(inputDate)
      // const period = dt.toFormat('yyyyMM')

      return period
    },
    handleExport() {
      var excelExportData = XLSX.utils.json_to_sheet(this.rows)
      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new() // make Workbook of Excel
      XLSX.utils.book_append_sheet(wb, excelExportData, 'Lead Data') // sheetName is name of Worksheet
      XLSX.writeFile(wb, `${this.reportSelected}.xlsx`) // name of the file is 'Export.xlsx'
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination-container {
  margin-top: 0px;
  padding: 15px 16px;
}

.analytics-filter-container {
  .filter-item {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }
  .export-button {
    vertical-align: middle;
    position: absolute;
    right: 0;
  }
}

.date-control-container {
  padding-top: 10px;
  padding-bottom: 10px;
}

.filter-item {
  width: 100%;
}

.chart-container {
  position: relative;
  width: 100%;
  height: calc(100vh - 84px);
}

.el-table--scrollable-x .el-table__fixed {
bottom: 8px; // table-scroll-bar-height
height: auto !important;
}
</style>
